import {PaginationAction, PaginationActionKind, PaginationState} from "@/components/PayoutHistory/PayoutHistoryModel";
import BalanceLog, {BalanceLogType} from "@/models/BalanceLog";
import moment from "moment";

const eventsOptions = [
    {
        value: 4,
        label: "4"
    },
    {
        value: 5,
        label: "5"
    },
    {
        value: 6,
        label: "6"
    },
    {
        value: 7,
        label: "7"
    }
]

const paginationReducer = (state: PaginationState, action: PaginationAction) => {
    const { type, payload } = action;
    let newFirstRow: number;
    let newLastRow: number;
    let newUserTransaction: BalanceLog[];
    let newRowsMax: number;
    let newFilterTransactionType: BalanceLogType | undefined;
    let newFilterDate: moment.Moment | undefined;
    switch (type) {
        case PaginationActionKind.SET_INITIAL_DATA:
            const initialData = action.payload?.initialData
            if (initialData === undefined) {
                return state
            }
            const paginationInitialState: PaginationState = {
                immutableUserTransaction: initialData,
                userTransactions: initialData,
                rowsPerPage: 5 <= initialData.length ? 5 : initialData.length,
                rowsMax: initialData.length,
                firstRow: 0,
                lastRow: 5 <= initialData.length ? 5 : initialData.length,
                filterTransactionType: undefined,
                filterDate: undefined,
                eventsOptions: eventsOptions,
                backwardDisabled: true,
                forwardDisabled: initialData.length < 10,
                backwardLongDisabled: true,
                forwardLongDisabled: initialData.length < 30,
            }
            return paginationInitialState
        case PaginationActionKind.CHANGE_ROW_PER_PAGE:
            const difference =  Number(payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage) - state.rowsPerPage;
            if (state.rowsMax < (payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage)) {
                newFirstRow = 0;
                newLastRow = state.rowsMax;
            } else {
                if (difference > 0 && state.lastRow + difference > state.rowsMax) {
                    newFirstRow = state.rowsMax - Number(payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage);
                    newLastRow = state.rowsMax;
                }  else {
                    newFirstRow = state.firstRow;
                    newLastRow = state.firstRow + Number(payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage)
                }
            }
            return {
                ...state,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                forwardDisabled: newLastRow >= state.rowsMax,
                backwardDisabled: newFirstRow <= 0,
                forwardLongDisabled: state.rowsMax < newLastRow + (Number(payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage) * 5),
                backwardLongDisabled: newFirstRow - (Number(payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage) * 5) < 0,
                rowsPerPage: payload?.rowsPerPage ? payload.rowsPerPage : state.rowsPerPage,
            }
        case PaginationActionKind.FORWARD:
            if (state.rowsMax - state.lastRow < state.rowsPerPage) {
                newFirstRow = state.rowsMax - state.rowsPerPage;
                newLastRow = state.rowsMax;
            } else {
                newFirstRow = state.firstRow + state.rowsPerPage;
                newLastRow = state.lastRow + state.rowsPerPage;
            }
            return {
                ...state,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                forwardLongDisabled: state.rowsMax < newLastRow + (state.rowsPerPage * 5),
                forwardDisabled: newLastRow >= state.rowsMax,
                backwardDisabled: newFirstRow <= 0,
                backwardLongDisabled: newFirstRow - (state.rowsPerPage * 5) < 0,
            }
        case PaginationActionKind.FORWARD_LONG:
            newFirstRow = state.firstRow + state.rowsPerPage * 5;
            newLastRow = state.lastRow + state.rowsPerPage * 5;
            return {
                ...state,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                forwardLongDisabled: state.rowsMax < newLastRow + (state.rowsPerPage * 5),
                forwardDisabled: newLastRow >= state.rowsMax,
                backwardDisabled: newFirstRow <= 0,
                backwardLongDisabled: newFirstRow - (state.rowsPerPage * 5) < 0,
            }
        case PaginationActionKind.BACKWARD:
            if (state.firstRow -  state.rowsPerPage < 0) {
                newFirstRow = 0;
                newLastRow = state.rowsPerPage;
            } else {
                newFirstRow = state.firstRow - state.rowsPerPage;
                newLastRow = state.lastRow - state.rowsPerPage;
            }
            return {
                ...state,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                forwardLongDisabled: state.rowsMax < newLastRow + (state.rowsPerPage * 5),
                forwardDisabled: newLastRow >= state.rowsMax,
                backwardDisabled: newFirstRow <= 0,
                backwardLongDisabled: newFirstRow - (state.rowsPerPage * 5) < 0,
            }
        case PaginationActionKind.BACKWARD_LONG:
            newFirstRow = state.firstRow - state.rowsPerPage * 5;
            newLastRow = state.lastRow - state.rowsPerPage * 5;
            return {
                ...state,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                forwardLongDisabled: state.rowsMax < newLastRow + (state.rowsPerPage * 5),
                forwardDisabled: newLastRow >= state.rowsMax,
                backwardDisabled: newFirstRow <= 0,
                backwardLongDisabled: newFirstRow - (state.rowsPerPage * 5) < 0,
            }
        case PaginationActionKind.FILTER_TRANSACTION_TYPE:
            if (payload?.filterTransactionType) {
                newUserTransaction = state.immutableUserTransaction.filter(transaction => transaction.type === payload?.filterTransactionType)
                newFilterTransactionType = payload?.filterTransactionType;
            } else {
                newUserTransaction = state.immutableUserTransaction;
                newFilterTransactionType = undefined;
            }

            if (state.filterDate !== undefined) {
                newUserTransaction = newUserTransaction.filter(transaction => moment(transaction.date) > moment(state.filterDate))
            }

            newRowsMax = newUserTransaction.length;

            if (newRowsMax < state.rowsPerPage) {
                newFirstRow = 0;
                newLastRow = newRowsMax;
            } else if (newRowsMax < state.lastRow) {
                newLastRow = newRowsMax;
                newFirstRow = newLastRow - state.rowsPerPage;
            } else {
                newFirstRow = state.firstRow;
                newLastRow = state.firstRow + state.rowsPerPage;
            }

            return {
                ...state,
                userTransactions: newUserTransaction,
                rowsMax: newRowsMax,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                filterTransactionType: newFilterTransactionType,
                forwardLongDisabled: newRowsMax < newLastRow + (state.rowsPerPage * 5),
                forwardDisabled: newLastRow >= newRowsMax,
                backwardDisabled: newFirstRow <= 0,
                backwardLongDisabled: newFirstRow - (state.rowsPerPage * 5) < 0,
            }
        case PaginationActionKind.FILTER_DATE:
            if (typeof payload?.filterDate != "undefined") {
                newUserTransaction = state.immutableUserTransaction.filter(transaction => moment(transaction.date) > moment(payload?.filterDate))
                newFilterDate = payload?.filterDate;
            } else {
                newUserTransaction = state.immutableUserTransaction;
                newFilterDate = undefined;
            }

            if (state.filterTransactionType !== undefined) {
                newUserTransaction = newUserTransaction.filter(transaction => transaction.type === state.filterTransactionType);
            }

            newRowsMax = newUserTransaction.length;

            if (newRowsMax < state.rowsPerPage) {
                newFirstRow = 0;
                newLastRow = newRowsMax;
            } else if (newRowsMax < state.lastRow) {
                newLastRow = newRowsMax;
                newFirstRow = newLastRow - state.rowsPerPage;
            } else {
                newFirstRow = state.firstRow;
                newLastRow = state.firstRow + state.rowsPerPage;
            }

            return {
                ...state,
                userTransactions: newUserTransaction,
                rowsMax: newRowsMax,
                firstRow: newFirstRow,
                lastRow: newLastRow,
                filterDate: newFilterDate,
                forwardLongDisabled: newRowsMax < newLastRow + (state.rowsPerPage * 5),
                forwardDisabled: newLastRow >= newRowsMax,
                backwardDisabled: newFirstRow <= 0,
                backwardLongDisabled: newFirstRow - (state.rowsPerPage * 5) < 0,
            }

        default:
            return state;
    }
}

export default paginationReducer;
