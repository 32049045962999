import {ReactElement, useState} from "react";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import ConfirmDeleteMembershipCardModal from "@/components/ConfirmDeleteMembershipcardModal";


interface TicketCardProps {
    classes: string;
    uuid: string;
    trashable: boolean;
    fullName: string;
    startColor: string;
    endColor: string;
    number: string;
    reductionCode: string;
    valid: boolean;
    pinType: string;
    code: string;
    onUpdate: () => void;

}

const TicketCard = (props: TicketCardProps): ReactElement => {
    const [deleteMembershipCardOpen, setDeleteMembershipCardOpen] = useState<boolean>(false);
    const api = useApi()
    return (
        <div className={props.classes + " ticket-card"}>
            <div className="ticket-card__top"
                 style={{background: `linear-gradient(105.83deg, ${props.startColor} 0%, ${props.endColor} 100%)`}}>
                <div className="ticket-card__top__header">
                    <h3 className="ticket-card__top__header__label">{props.fullName}</h3>
                </div>
            </div>
            <div className="ticket-card__bottom">
                <div className="ticket-card__bottom__details">
                    <label className="ticket-card__bottom__details__title">Dettagli</label>
                    <p className="ticket-card__bottom__details__paragraph">
                        <strong>{props.pinType === "date" ? "Nato il: " : "PIN: "}</strong>{props.code}
                    </p>
                    <p className="ticket-card__bottom__details__paragraph">
                        <strong>{"Tessera N°: "}</strong>{props.number}
                    </p>
                    <div className="ticket-card__bottom__details__wrapper">
                        <p className="ticket-card__bottom__details__paragraph">
                            <strong>{"Tipo: "}</strong>{props.reductionCode}
                        </p>
                        <p className="ticket-card__bottom__details__paragraph">
                            <strong>{"Stato: "}</strong>{props.valid ? "Valido" : "Non valido"}
                        </p>
                    </div>
                </div>
                {
                    (props.trashable) ?
                        <div className="ticket-card__bottom__trash-can">
                            <i className="icon icon--trash-can cursor-pointer"
                               onClick={() => { setDeleteMembershipCardOpen(true) }}
                            />
                        </div>
                        :
                        null
                }
            </div>
            {
                deleteMembershipCardOpen ?
                    <Modal show={deleteMembershipCardOpen}
                           onHide={() => {
                               setDeleteMembershipCardOpen(false)
                           }}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <ConfirmDeleteMembershipCardModal
                            ticketData={props}
                            onClose={() => {
                                setDeleteMembershipCardOpen(false)
                            }}
                            onComplete={() => {
                                setDeleteMembershipCardOpen(false)
                                api.DeleteMembershipCard(props.uuid).then(() => {
                                    toast.success("Card Successfully Deleted")
                                    props.onUpdate()
                                }).catch((e) => {
                                    if (e.response.status === 409) {
                                        if (e.response.data.text === "marketplace") {
                                            toast.error("Errore, un biglietto associato alla tessera è attualmente in vendita")
                                        } else {
                                            toast.error("Errore, un biglietto associato alla tessera è attualmente immutabile")
                                        }
                                    } else {
                                        toast.error("L'operazione non è attualmente disponibile, riprovare più tardi")
                                    }
                                })
                            }}
                        />
                    </Modal>
                    :
                    null
            }
        </div>
    )
}


export default TicketCard
