import {ReactElement} from "react";
import {Flipped} from "react-flip-toolkit";
import TicketsBundle from "@/models/TicketsBundle";
import ReductionCode from "@/models/ReductionCode";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";
import {useStore} from "@/hoc/Store/context";
import {ErrorCodes} from "@/models/ErrorCodes";
import {useLocation, useNavigate} from "react-router-dom";

interface BundleProps {
    bundle: TicketsBundle;
    reductionCodes: ReductionCode[];
    onSelect: () => void;
}

const Bundle = (props: BundleProps): ReactElement => {
    const api = useApi()
    const store = useStore()
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <Flipped key={`bundle-${props.bundle.uuid}`} flipId={`bundle-${props.bundle.uuid}`}>
            <div className={"col-12 col-md-4 px-md-1 px-xl-3 user-select-none"}>
                <div className={"bundle" /*+ featureClass*/}>
                    <div className="bundle__top" title={"x" + props.bundle.totalTickets}>
                        <p className="ticket__gradientLabel--price font-weight-medium">
                            {props.reductionCodes.find((reductionCode: ReductionCode) => reductionCode.uuid === props.bundle.reductionCodeUuid)?.name}
                        </p>
                        <h2 className="bundle__title font-weight-bold">{props.bundle.section}</h2>
                        <div className="bundle__info__position">
                            <p className="label">
                            <span>{"Settore:"} <span
                                className="text--primary-color d-inline">n° {props.bundle.subSection}</span></span>
                                <span>{"Fila:"} <span
                                    className="text--primary-color d-inline">{props.bundle.row}</span></span>
                                <span>{"Posti:"} <span
                                    className="text--primary-color d-inline">{props.bundle.seats.join(", ")}
                            </span>
                            </span>
                            </p>
                        </div>
                    </div>
                    <div className="bundle__center">
                        <div className="bundle__center__half bundle__center__half-first"/>
                        <div className="bundle__center__half bundle__center__half-last"/>
                        <svg className="bundle__dashed-line" viewBox="10 0 110 2" xmlns="http://www.w3.org/2000/svg">
                            <line x1=".2%" y1="1" x2="120%" y2="1" stroke="#1D0F51" strokeDasharray="4 3"
                                  strokeLinecap="round" strokeWidth="1"/>
                        </svg>
                    </div>
                    <div className="bundle__bottom">
                        <div className="bundle__shop__price-section px-lg-1">
                            <p className="bundle__shop__price-section__single-price label-2">{`€ ${(props.bundle.singleTicketPrice / 100).toLocaleString('it-IT', {minimumFractionDigits: 2})} x${props.bundle.totalTickets}`}</p>
                            <p className="bundle__gradientLabel--price">{`€ ${(props.bundle.totalPrice / 100).toLocaleString('it-IT', {minimumFractionDigits: 2})}`} {}{/***/}</p>
                        </div>
                        <button className="btn btn--outlined btn--outlined_no-p ticket__shop__button"
                                onClick={() => {
                                    api.IsLogged().then(isLogged=>{
                                        if(isLogged.isLogged) {
                                            api.AddTicketClusterToPreorder(props.bundle.uuid).then(p => {
                                                props.onSelect()
                                                api.GetPreorder().then(p => {
                                                    store.setPreorder(p)
                                                })
                                            }).catch(() => {
                                                toast("Spiacenti, si è verificato un errore", {type: "error"})
                                            })
                                        } else{
                                            navigate(`/Auth?from=${location.pathname}&errorCode=${ErrorCodes.LOGIN_NEEDED}`)
                                        }
                                })}}
                        >
                            <span className="btn__text-gradient">{"Aggiungi al carrello"}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Flipped>
    )
}

export default Bundle
