import {FormEvent, ReactElement, useState} from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements, useStripe
} from "@stripe/react-stripe-js";
import Loader from "@/components/Loader";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useApi} from "@/hoc/Api/context";
import {tr} from "date-fns/locale";


interface CheckoutFormProps {
    orderUuid: string;
}

const CheckoutForm = (props: CheckoutFormProps): ReactElement => {
    const stripe = useStripe()
    const elements = useElements()
    const api = useApi()
    const [cardNumberError, setCardNumberError] = useState<string | undefined | null>(undefined)
    const [cardExpiryError, setCardExpiryError] = useState<string | undefined | null>(undefined)
    const [cardCVCError, setCardCVCError] = useState<string | undefined | null>(undefined)
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState<boolean>(false);
    const handleSubmit = (ev: FormEvent<HTMLFormElement>): void => {
        ev.preventDefault()
        if (stripe === null || elements === null) {
            return
        }
        const cardNumber = elements.getElement(CardNumberElement)
        if (cardNumber === null) {
            return;
        }

        if (hasErrors()) {
            return;
        }

        setSubmitted(true)

        api.FinalizePreorder().then(preorder => {
            stripe.confirmCardPayment(preorder.clientData?.clientSecret || "", {
                payment_method: {
                    card: cardNumber
                },
                return_url: `${window.location.href}/${props.orderUuid}`,
            }, {handleActions: false}).then(r => {
                if (r.error) {
                    switch (r.error.type) {
                        case "card_error":
                            navigate(`/Checkout/${props.orderUuid}`)
                            return;
                        default:
                            setSubmitted(false)
                            toast("Errore elaborando la richiesta di pagamento", {type: "error"})
                    }
                } else {
                    if (r.paymentIntent.status === "requires_capture") {
                        navigate(`/Checkout/${props.orderUuid}`)
                        return
                    } else if (r.paymentIntent.status === "requires_action") {
                        if (r.paymentIntent.next_action) {
                            if (r.paymentIntent.next_action.redirect_to_url?.url) {
                                window.location.href = r.paymentIntent.next_action.redirect_to_url?.url as string
                            }
                        }
                    }
                }
            }).catch(e => {
                setSubmitted(false)
                switch (e.type) {
                    case "card_error":
                        toast("Hai inserito dei dati invalidi", {type: "error"})
                        break
                    default:
                        toast("Errore elaborando la richiesta di pagamento", {type: "error"})
                }
            })
        }).catch((e) => {
            setSubmitted(false)
            if (e.response.status === 400) {
                switch (e.response.data.code) {
                    case "unassociatedMembershipCard":
                        toast.error("Errore, ad uno o più biglietti non è stata associata una carta valida")
                        break;
                    case "malformedRequest":
                        toast.error("Errore, non è stato selezionato alcun biglietto da acquistare")
                        break
                    default:
                        toast.error("Spiacenti, si è verificato un errore.")
                }
            } else {
                toast.error("Spiacenti, si è verificato un errore.")
            }
        })

    }
    const hasErrors = (): boolean => {
        return cardCVCError !== null || cardExpiryError !== null || cardNumberError !== null
    }
    if (elements === null) {
        return <Loader/>
    }
    return (
        <form className="row checkout__form needs-validation" onSubmit={handleSubmit}>
            {
                submitted?<Loader/>:null
            }
            <div className={"form-group checkout__form__input col-12 col-md-6"}>
                <label className="checkout__form__label">{"Carta"}</label>
                <CardNumberElement
                    onChange={(ev) => {
                        if (ev.error) {
                            setCardNumberError(ev.error.message)
                        } else {
                            if (ev.complete) {
                                setCardNumberError(null)
                                return;
                            }
                            setCardNumberError(undefined)
                        }
                    }}
                />
                {
                    cardNumberError ?
                        <div style={{display: "block"}}
                             className="invalid-feedback">{cardNumberError}</div> : null
                }
            </div>

            <div className={"form-group checkout__form__input col-12 col-md-6"}>
                <label className="checkout__form__label">{"Scadenza"}</label>
                <CardExpiryElement
                    onChange={(ev) => {
                        if (ev.error) {
                            setCardExpiryError(ev.error.message)
                        } else {
                            if (ev.complete) {
                                setCardExpiryError(null)
                                return;
                            }
                            setCardExpiryError(undefined)
                        }
                    }}
                />
                {
                    cardExpiryError ?
                        <div style={{display: "block"}}
                             className="invalid-feedback">{cardExpiryError}</div> : null
                }
            </div>

            <div className={"form-group checkout__form__input col-12 col-md-6"}>
                <label className="checkout__form__label">{"CVC"}</label>
                <CardCvcElement
                    onChange={(ev) => {
                        if (ev.error) {
                            setCardCVCError(ev.error.message)
                        } else {
                            if (ev.complete) {
                                setCardCVCError(null)
                                return;
                            }
                            setCardCVCError(undefined)
                        }
                    }}
                />
                {
                    cardCVCError ?
                        <div style={{display: "block"}}
                             className="invalid-feedback">{cardCVCError}</div> : null
                }
            </div>
            <div className="w-100"/>
            <button disabled={hasErrors()} type={"submit"} className={"btn btn--gradient checkout__form__button"}>
                {"ACQUISTA"}
            </button>
        </form>
    )
}

export default CheckoutForm
