import {ReactElement, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";
import {isSafari} from "react-device-detect";
interface TicketCardProps {
    classes: string;
    uuid: string;
    trashable: boolean;
    fullName: string;
    startColor: string;
    endColor: string;
    number: string;
    reductionCode: string;
    valid: boolean;
    pinType: string;
    code: string;
    onUpdate: () => void;

}
interface ConfirmDeleteMembershipCardModalProps {
    onClose: () => void;
    onComplete: () => void;
    ticketData: TicketCardProps;
}

const ConfirmDeleteMembershipCardModal = (props: ConfirmDeleteMembershipCardModalProps): ReactElement => {

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    return (
        <>
            <div className="modal-header">
                <h5 className="mx-auto modal-title">Conferma eliminazione Tessera</h5>
                <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mt-2 mx-sm-0">
                <div className="container">
                    <div className="row justify-content-center d-flex gy-3">
                        <div className="col-12 col-sm-10">
                            <div className="ticket-card">
                                <div className="ticket-card__top"
                                     style={{background: `linear-gradient(105.83deg, ${props.ticketData.startColor} 0%, ${props.ticketData.endColor} 100%)`}}>
                                    <div className="ticket-card__top__header">
                                        <h3 className="ticket-card__top__header__label">{props.ticketData.fullName}</h3>
                                    </div>
                                </div>
                                <div className="ticket-card__bottom">
                                    <div className="ticket-card__bottom__details">
                                        <label className="ticket-card__bottom__details__title">Dettagli</label>
                                        <p className="ticket-card__bottom__details__paragraph">
                                            <strong>{props.ticketData.pinType === "date" ? "Nato il: " : "PIN: "}</strong>{props.ticketData.code}
                                        </p>
                                        <p className="ticket-card__bottom__details__paragraph">
                                            <strong>{"Tessera N°: "}</strong>{props.ticketData.number}
                                        </p>
                                        <div className="ticket-card__bottom__details__wrapper">
                                            <p className="ticket-card__bottom__details__paragraph">
                                                <strong>{"Tipo: "}</strong>{props.ticketData.reductionCode}
                                            </p>
                                            <p className="ticket-card__bottom__details__paragraph">
                                                <strong>{"Stato: "}</strong>{props.ticketData.valid ? "Valido" : "Non valido"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 justify-content-center d-flex">
                            <label>Confermi di voler eliminare la Tessera?</label>
                        </div>
                        <div className="col-12 justify-content-center d-flex">
                            <button className="btn btn--gradient" onClick={() => {props.onComplete()}}>
                                {"CONFERMA"}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ConfirmDeleteMembershipCardModal
