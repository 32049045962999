import { ReactElement, useEffect, useState } from "react";
import UserTicket from "@/models/UserTicket";
import Select, { StylesConfig } from "react-select";
import SellTicket, { SellTicketType } from "@/components/SellTicket";
import { useMedia } from "react-media";
import * as _ from "lodash";
import variables from "@/models/ColorsVariables";

interface SellTableProps {
    noFilter: boolean;
    noHeader: boolean;
    title: string;
    emptySubtitle: string;
    id: string;
    match: string;
    card: string;
    type: SellTicketType;
    tickets: UserTicket[];
    availableSections?: string;
}

interface SelectOptions {
    value: String;
    label: String;
}

const SellTable = (props: SellTableProps): ReactElement => {

    const [showMore, setShowMore] = useState<boolean>(false);

    const [filteredItems, setFilteredItems] = useState<UserTicket[]>(props.tickets);

    const [memberFilter, setMemberFilter] = useState<string | null>(null);
    const [issuerFilter, setIssuerFilter] = useState<string | null>(null);

    const GLOBAL_MEDIA_QUERIES = {
        xs: "(max-width: 500px)",
    };

    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    const ticketList = filteredItems.map((t, index) => {
        return <SellTicket key={index} noFilter={props.noFilter} noHeader={props.noHeader} type={props.type} ticket={t} />
    });

    const selectStyles: StylesConfig = {
        control: (styles) => (
            { ...styles, backgroundColor: variables.background, border: "0", boxShadow: "none", color: variables.mainText, fontSize: 14, cursor: 'pointer', }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
        dropdownIndicator: (styles, { isFocused }) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover': {
                    color: variables.mainText
                }
            }
        },
        clearIndicator: (styles, { isFocused }) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover': {
                    color: variables.mainText
                }
            }
        },
        valueContainer: (styles) => ({ ...styles, padding: 0, fontSize: 14, color: variables.mainText }),
        menu: (styles) => ({ ...styles, fontSize: 14, color: `${variables.mainText} !important` }),
        placeholder: (styles) => ({ ...styles, color: variables.mainText }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                color: variables.mainText,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: variables.mainText, fontSize: 14 }),
    }

    const membershipCardOptions: SelectOptions[] = _.uniqBy(filteredItems.map((ticket): SelectOptions => ({
        value: ticket.membershipCard.uuid,
        label: ticket.membershipCard.fullName
    })), 'value')

    const issuerOptions: SelectOptions[] = _.uniqBy(
        [
            ...filteredItems.map((ticket): SelectOptions => ({
                value: ticket.mainIssuerUuid,
                label: ticket.mainIssuer,
            })),
            ...filteredItems.map((ticket): SelectOptions => ({
                value: ticket.secondaryIssuerUuid,
                label: ticket.secondaryIssuer,
            }))
        ], 'value')

    const TicketsFilter = (
        <div className="d-block">
            <div className="row offset-xl-1 sell__table__filters">
                <div className={"pe-0 px-sm-3 p-sm-0 pe-sm-2 col-6 col-sm-6 col-md-5 col-lg-4 col-xl-3"}>
                    <label className={matches.xs ? "d-none" : "label"}>{"Partita"}</label>
                    <Select options={issuerOptions}
                        placeholder={matches.xs ? "Partita" : "Mostra tutte"}
                        isClearable
                        styles={selectStyles}
                        onChange={(ev: any) => { setIssuerFilter(ev != null ? ev.value : null) }}
                    />
                </div>
                <div className={"ps-0 px-sm-3 p-sm-0 pe-sm-2 col-6 col-sm-6 col-md-5 col-lg-4 col-xl-3"}>
                    <label className={matches.xs ? "d-none" : "label"}>{"Tessera"}</label>
                    <Select options={membershipCardOptions}
                        placeholder={matches.xs ? "Tessera" : "Mostra tutte"}
                        isClearable
                        styles={selectStyles}
                        onChange={(ev: any) => { setMemberFilter(ev != null ? ev.value : null) }}
                    />
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        setFilteredItems(props.tickets)
    }, [props.tickets])

    useEffect(() => {
        setFilteredItems(
            props.tickets
                .filter(t => t.membershipCard.uuid === memberFilter || memberFilter === null)
                .filter(t => t.mainIssuerUuid === issuerFilter || t.secondaryIssuerUuid === issuerFilter || issuerFilter === null)
        )
    }, [memberFilter, issuerFilter])

    return (
        <div className={`${props.noFilter && props.noHeader ? "sell__table--sell-page" : null} sell__table row`}>
            <div className={`col-12 col-lg-11 offset-xl-1`}>
                <h3 className="sell__table__title">
                    {props.title}
                </h3>
                {
                    props.availableSections ?
                        <p className="sell__table__sections">Il servizio di rivendita è abilitato per i seguenti settori: <b>{props.availableSections}.</b></p>
                        : null
                }
            </div>
            {
                (props.tickets.length > 0)
                    ?
                    <>
                        {TicketsFilter}
                        {
                            !props.noHeader
                                ?
                                <div className="sell__table__header col-12 col-lg-10 offset-lg-1 d-none d-md-flex">
                                    <p className={`label-2 sell__table__header__label sell__table__header__label--match ${props.id === "Acquired" ? "sell__table__header__label--match--acquired" : null}`}>{props.match}</p>
                                    <p className={`label-2 sell__table__header__label sell__table__header__label--details ${props.id === "Acquired" ? "sell__table__header__label--details--acquired" : null}`}>{props.card}</p>
                                </div>
                                :
                                props.type === SellTicketType.BOUGHT ?
                                    <div className={"d-none d-md-block"}>
                                        <div className={"row offset-xl-1"}>
                                            <div className={"col-md-6 ms-lg-2 col-lg-5 col-xl-5"}>
                                                <p className="text--primary-color">PARTITA</p>
                                            </div>
                                            <div className={"col-md-4 ms-lg-5 col-lg-3 col-xl-3"}>
                                                <p className="text--primary-color">TESSERA</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={"d-none d-md-block"}>
                                        <div className={"row offset-xl-1"}>
                                            <div className={"col-md-5 col-lg-5 col-xl-4"}>
                                                <p className="text--primary-color">PARTITA</p>
                                            </div>
                                            <div className={"col-md-4 col-lg-3 col-xl-4"}>
                                                <p className="text--primary-color">TESSERA</p>
                                            </div>
                                            <div className={"col-md-3 col-lg-4 col-xl-4"}>
                                                <p className="text--primary-color">RIMBORSO</p>
                                            </div>
                                        </div>
                                    </div>
                        }
                        {ticketList}
                        {
                            (filteredItems.length > 4 && !showMore)
                                ?
                                <div className="col-12 transactions__total-price__show-more">
                                    <button type="button" onClick={
                                        () => {
                                            setShowMore(true)
                                        }
                                    } className="btn btn--outlined">
                                        <span className="btn__text-gradient">
                                            {"Mostra altri"}
                                        </span>
                                    </button>
                                </div>
                                :
                                null
                        }
                    </>
                    :
                    <div className="col-12 col-lg-10 offset-xl-1">
                        <p>{props.emptySubtitle}</p>
                    </div>
            }
        </div>
    )
}

export default SellTable
