import {ReactElement, useEffect, useState} from "react";
import BalanceLog, {BalanceLogType} from "@/models/BalanceLog";
import Moment from "react-moment";
import Payout, {PayoutStatus} from "@/models/Payout";
import {Modal} from "react-bootstrap";
import HistoryModal from "@/components/HistoryModal";
import UserTicket from "@/models/UserTicket";


const PayoutHistoryTicket = (props: BalanceLog): ReactElement => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const [ticketOrder, setTicketOrder] = useState<UserTicket | undefined>();
    const [payout, setPayout] = useState<Payout | undefined>();

    useEffect(() => {
        props.type === BalanceLogType.SALE ?
            setTicketOrder(props.data as unknown as UserTicket)
            :
            setPayout(props.data as unknown as Payout);
    }, [])

    return (
        <div className="row d-flex align-items-center py-3 payout-history-ticket"
             onClick={() => {
                 setShowModal(!showModal);
             }}
        >
            {/* ICON */}
            <div className="col-2 col-sm-2 d-flex justify-content-center">
                <i className={`icon icon--${props.type === BalanceLogType.SALE ? "payout-tickets" : "available-balance"}`}/>
            </div>

            {/* DESCRIZIONE */}
            <div className="col-7 col-sm-5 col-lg-4 d-flex">
                <div className="row">
                    <div className="col-12 d-flex d-lg-none">
                        <label className="date"><Moment locale={"it"} format="DD/MM/yyyy" className="text--heading-color">{props.date}</Moment></label>
                    </div>
                    <div className="col-12 ticket-type text--primary-color">
                        {props.type === BalanceLogType.SALE ? "Vendita Biglietto" : "Richiesta Payout"}
                    </div>
                    <div className="col-12 ticket-content text--heading-color">
                        {props.type === BalanceLogType.SALE ?
                            `${ticketOrder?.mainIssuer} vs ${ticketOrder?.secondaryIssuer} - ${ticketOrder?.membershipCard.fullName}`
                            :
                            payout?.status === PayoutStatus.COMPLETED ? `Inviato a: ${payout?.name}` : "In attesa di invio"
                        }
                    </div>
                </div>
            </div>

            {/* DATA */}
            <div className="col-2 col-sm-2 d-none d-lg-flex date">
                <label className="date"><Moment locale={"it"} format="DD/MM/yyyy" className="text--heading-color">{props.date}</Moment></label>
            </div>

            {/* IMPORTO */}
            <div className="col-3 col-sm-3 col-lg-2 d-flex px-1 p-sm-auto">
                <label className={`price price-${props.type === BalanceLogType.PAYOUT ? "text--main-accent": "text--heading-color"}`}>
                    {(props.amount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                </label>
            </div>

            {/* LENTE */}
            <div className="col-2 d-none d-sm-flex justify-content-center"
                 onClick={() => {
                     setShowModal(true)
                 }}
            >
                <i className="icon icon--magnifying-glass lens"/>
            </div>
            {
                showModal ?
                    <>
                        <Modal show={showModal}
                               onHide={() => {
                                   setShowModal(false)
                               }}
                               contentClassName={"ticket-cards__modal__content"}
                               dialogClassName={"ticket-cards__modal"}
                        >
                            <HistoryModal
                                ticket={ticketOrder}
                                payout={payout}
                                accountingDate={props.accountingDate}
                                onClose={() => {
                                    setShowModal(false)
                                }}
                            />
                        </Modal>
                    </>
                    :
                    null
            }
        </div>
    )
}

export default PayoutHistoryTicket;
