import {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {Link, useMatch} from "react-router-dom";

// @ts-ignore
import profileImgPlaceholder from "@/img/profile_placeholder.png";
import {Modal} from "react-bootstrap";
import LogoutModal from "@/components/LogoutModal";
import UserInfo from "@/models/UserInfo";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";

interface ProfileHeaderProps {
    user: UserInfo
    onUserUpdated: (user: UserInfo) => void
}

const ProfileHeader = (props: ProfileHeaderProps): ReactElement => {

    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);

    const api = useApi()

    const [profileImage, setProfileImage] = useState<string>(profileImgPlaceholder)

    useEffect(() => {
        if (props.user.profileImage !== "") {
            api.GetBase64Media(props.user.profileImage).then(r => setProfileImage(r))
        }
    }, [])

    const uploadImage = (ev: ChangeEvent<HTMLInputElement>): void => {
        if (ev.target === null || ev.target.files === null) {
            return
        }
        let formData = new FormData()
        formData.append("profileImage", ev.target.files[0])
        api.UploadProfileImage(formData).then(u => {
            props.onUserUpdated(u)
            api.GetBase64Media(u.profileImage).then(r => setProfileImage(r))
        }).catch(e => {
            if (e.response.status === 413) {
                toast.error("L'immagine è troppo grande")
            } else {
                toast.error("Spiacenti, si è verificato un errore")
            }
        })
    }

    return (
        <>
            <div className="profileHeader container">
                <div className="row">
                    <div className="col-12 position-relative">
                        <div className="profileHeader__top">
                            <div className="profileHeader__top__info">
                                <div className="profileHeader__top__info__image-wrapper" onClick={
                                    () => {
                                        document.getElementById("imgupload")?.click()
                                    }
                                }>
                                    <input onChange={uploadImage}
                                           accept="image/jpeg, image/png" type="file" id="imgupload"
                                           style={{display: 'none'}}/>
                                    <img id="user-image" alt="profile image" className="profileHeader__top__info__user-image"
                                         src={profileImage}
                                    />
                                </div>
                                <div className="profileHeader__top__info__data">
                                    <h3 className="profileHeader__top__info__data__name">{props.user.name}</h3>
                                </div>
                            </div>
                            <div className="profileHeader__top__saldo">
                                <h4
                                    className="profileHeader__top__logout__label"
                                    onClick={() => setShowLogoutModal(true)}
                                >
                                    <i className="icon icon--white icon--logout profileHeader__top__logout__icon logout"/>
                                    Logout
                                </h4>
                                <h4 className="profileHeader__top__saldo__label">{"Saldo"}</h4>
                                <h2 className="profileHeader__top__saldo__price">{"€ " + (props.user.totalBalance / 100).toLocaleString('it-IT', {minimumFractionDigits: 2})}</h2>
                            </div>
                        </div>
                        <div className="profileHeader__bottom">
                            <ul className="profileHeader__bottom__links">
                                <li key={0}>
                                    <Link to={"/Profilo"}>
                                        <label
                                            className={"profileHeader__bottom__link" + (useMatch("/Profilo") !== null ? " active" : "")}>
                                            {"Account"}
                                        </label>
                                    </Link>
                                </li>
                                <li key={1}>
                                    <Link to={"/Profilo/Tessere"}>
                                        <label
                                            className={"profileHeader__bottom__link" + (useMatch("/Profilo/Tessere") !== null ? " active" : "")}>
                                            {"Tessere"}
                                        </label>
                                    </Link>
                                </li>
                                <li key={2}>
                                    <Link to={"/Profilo/Vendi"}>
                                        <label
                                            className={"profileHeader__bottom__link" + (useMatch("/Profilo/Vendi") !== null ? " active" : "")}>
                                            {"Biglietti"}
                                        </label>
                                    </Link>
                                </li>
                                <li key={3}>
                                    <Link to={"/Profilo/Saldo"}>
                                        <label
                                            className={"profileHeader__bottom__link" + (useMatch("/Profilo/Saldo") !== null ? " active" : "")}>
                                            {"Portafoglio"}
                                        </label>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                showLogoutModal ?
                    <Modal show={showLogoutModal}
                           onHide={() => {
                               setShowLogoutModal(false)
                           }}
                           contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                           dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                    >
                        <LogoutModal onClose={() => {
                            setShowLogoutModal(false)
                        }}/>
                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default ProfileHeader
