import { ReactElement, useEffect, useState, } from 'react'
import Layout from "@/components/Layout";
import Select from "react-select";
import { useAuthUser } from '@/hoc/Login/context';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '@/models/AuthUser';
import { useApi } from '@/hoc/Api/context';
import { Modal, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import MembershipCardValidation from '@/models/MembershipCardValidation';
import EventIssuer from '@/models/EventIssuer';
import { toast } from 'react-toastify';
import CreateValidationModal, { SelectOption } from '@/components/CreateValidationModal';



const Admin = ({ ...props }): ReactElement => {

    const [validations, setValidations] = useState<MembershipCardValidation[]>([])
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false)
    const [refreshList, setRefreshList] = useState<boolean>(false)
    const [eventsIssuerSelect, setEventsIssuerSelect] = useState<SelectOption[]>([])
    const authUserContext = useAuthUser()
    const api = useApi()
    const navigate = useNavigate()

    useEffect(() => {
        if (!authUserContext.authUser.email || authUserContext.authUser.role !== UserRole.ADMIN) {
            navigate('/', { replace: true })
        } else {
            getValidations()
        }
    }, [])

    useEffect(() => {
        if (refreshList) {
            getValidations()
            setRefreshList(false)
        }
    }, [refreshList])

    const getValidations = () => {
        api.GetActiveEventIssuers().then((r: EventIssuer[]) => {
            setEventsIssuerSelect([
                ...r.map(eventIssuer => {
                    return {
                        label: eventIssuer.name,
                        value: eventIssuer.uuid
                    }
                })])
        })
        api.GetValidations().then((r: MembershipCardValidation[]) => {
            setValidations(
                r.sort((validationA, validationB) => {
                    return new Date(validationB.createdAt).getTime() - new Date(validationA.createdAt).getTime()
                })
            )
        })
    }

    const handleRowClick = (id: string) => { navigate(`/Validazioni/${id}`) }

    const handleOnCloseDialog = (refreshList: boolean) => {
        setShowCreateDialog(false)
        setRefreshList(refreshList)
    }

    return (
        <Layout>
            <div className='admin container'>
                {
                    showCreateDialog ?
                        <Modal
                            show={showCreateDialog}
                            onHide={() => { setShowCreateDialog(false) }}
                            contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                            dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                        >
                            <CreateValidationModal
                                eventsIssuerSelect={eventsIssuerSelect}
                                onClose={handleOnCloseDialog}
                            />
                        </Modal>
                        :
                        null
                }
                <h2 className='info-title'>Pannello amministrazione</h2>
                <div className='d-flex justify-content-end'>
                    <button onClick={() => setShowCreateDialog(true)} className="btn btn--gradient p-2" >Nuova validazione</button>
                </div>
                <div className='mt-3 mb-5'>
                    <h3>Validazioni</h3>
                    <Table striped bordered hover>
                        <thead className="w-full">
                            <tr className=''>
                                <th>UUID</th>
                                <th>Totale</th>
                                <th>Team</th>
                                <th>Iniziata il</th>
                                <th>Finita il</th>
                                <th>Creata il</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                validations.map((validation: MembershipCardValidation, i: number) => {
                                    return <tr className='admin_table_cell' onClick={() => handleRowClick(validation.uuid)} key={validation.uuid}>
                                        <td>{validation.uuid}</td>
                                        <td>{validation.count}</td>
                                        <td>{eventsIssuerSelect.find(f => f.value === validation.eventIssuerUuid)?.label}</td>
                                        <td>{format(new Date(validation.startedAt), 'dd-MM-yyyy hh:mm')}</td>
                                        <td>{validation.endedAt ? format(new Date(validation.endedAt), 'dd-MM-yyyy hh:mm') : '-'}</td>
                                        <td>{format(new Date(validation.createdAt), 'dd-MM-yyyy hh:mm')}</td>
                                    </tr>
                                }
                                )
                            }

                        </tbody>
                    </Table>
                </div>
            </div>
        </Layout>

    )
}

export default Admin
