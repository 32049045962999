import {ReactElement, useEffect} from "react";
import UserInfo from "@/models/UserInfo";
import {PayoutProfileStatus} from "@/models/PayoutProfile";
import {useNavigate} from "react-router-dom";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";


interface PayoutModalProps {
    onClose: () => void;
    onComplete: () => void;
    userInfo: UserInfo;
}


const PayoutModal = (props: PayoutModalProps): ReactElement => {
    const navigate = useNavigate();
    const api = useApi();

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    const requirePayout = (): void => {
        api.RequirePayout().then(() => {
            toast.success("Richiesta di prelievo accettata")
            props.onComplete()
        }).catch(() => {
            toast.error("L'operazione non è andata a buon fine")
            props.onClose()
        })
    }

    return <>
        <div className="modal-header">
            <h6 className="mx-auto modal-title">Payout</h6>
            <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                    onClick={() => {
                        props.onClose()
                    }}
            >
                <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
            </button>
        </div>
        <div className="ticket-cards__modal__custom-body modal-body container balance-payout-modal row gy-2 gy-md-1">
            <div className="col-12 d-flex justify-content-between">
                <div className="col-3 d-flex px-0 mt-0">
                    <div className="d-flex d-md-none">
                        <i className="icon icon--available-balance icon-balance"/>
                    </div>
                </div>
                <div className="col-9 row d-flex px-0">
                    <div className="col-12 d-flex justify-content-end">
                        <label className="header-info">
                            {"Saldo "}
                            <span className="blue">
                        {((props?.userInfo.availableBalance) / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                    </span>
                        </label>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <label className="header-info">
                            {"Commissioni "}
                            <span className="red">
                        {
                            props?.userInfo.availableBalance > 2000 ?
                                " - 0,00 €" : " - 2,00 €"
                        }
                    </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <i className="icon icon--available-balance icon-balance d-none d-md-inline-flex"/>
                    <label className="available-balance-text">{"Saldo Disponibile "}</label>
                </div>
                <span className="available-balance-gradient">
                    {
                        props?.userInfo.availableBalance > 2000 ?
                            ((props?.userInfo.availableBalance) / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"
                            :
                            ((props?.userInfo.availableBalance - 200) / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"
                    }
                </span>
            </div>
            <div className="col-12 d-flex align-items-center py-2">
                <label className="tax-info">
                    Se il saldo è maggiore di 20€ non paghi le commissioni!
                </label>
            </div>
            <div className="col-12 d-flex divider my-4"></div>
            <div className="col-12 d-flex">
                <label className="transfer-info">
                    Riceverai il saldo per mezzo bonifico entro 7 giorni lavorativi sull’IBAN da te indicato.
                </label>
            </div>
            <div className="col-12 d-flex py-4">
                {
                    props?.userInfo.payoutProfile?.status === PayoutProfileStatus.Complete ?
                        <>
                            <div className="row d-flex card-profile-account card-profile-account__complete">
                                <div className="d-flex card-profile-account__name">
                                    <span>{props?.userInfo.payoutProfile.firstName} {props?.userInfo.payoutProfile.lastName}</span>
                                </div>
                                <div className="d-flex card-profile-account__body">
                                    <span>
                                        {`IBAN: **** **** **** ${props?.userInfo.payoutProfile?.IBAN.substring(props?.userInfo.payoutProfile.IBAN.length - 4)}`}
                                    </span>
                                </div>
                                <div className={"d-flex card-profile-account__body pb-3 align-items-center"}>
                                    <i className="icon icon--document-card"/>
                                    <span className="font-size-medium">Documento verificato</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="col-12 d-flex justify-content-start align-items-center">
                                <div className="col-auto d-flex">
                                    <i className="icon icon--warning icon-warning me-md-3"/>
                                </div>
                                <div className="col-11 col-md-auto d-flex">
                                    <label
                                        className="payout-data-shortcut">{"Prima di poter ricevere il saldo è necessario "}
                                        <span className="payout-data-shortcut" onClick={() => navigate("/Profilo")}>
                                        configurare i dati di payout
                                    </span>
                                    </label>
                                </div>
                            </div>
                        </>
                }
            </div>
            <div className="col-12 d-flex">
                {
                    props?.userInfo.payoutProfile?.status === PayoutProfileStatus.Complete ?
                        <label className="transfer-info">Desideri inviare il saldo?</label>
                        :
                        null
                }
            </div>
            <div className="col-auto mx-auto d-flex align-items-center pt-md-4 pb-md-3">
                <button className="btn btn--outlined py-1 px-5"
                        disabled={props?.userInfo.payoutProfile?.status !== PayoutProfileStatus.Complete}
                        onClick={requirePayout}
                >
                    <span className="btn__text-gradient">INVIA</span>
                </button>
            </div>

        </div>
    </>
}

export default PayoutModal
