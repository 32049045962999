import {ReactElement, ReactNode, useEffect} from "react";

interface ChangePasswordModalProps {
    onClose: () => void;
    resetFilters: () => void;
    children: ReactNode;
}

const FiltersModal = (props: ChangePasswordModalProps): ReactElement => {

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    return (
        <>
            <div className="modal-header">
                <div className={"col-4"}>
                    <button type="button" className="col-4 btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                props.onClose()
                            }}
                    >
                        <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                    </button>
                </div>
                <div className={"col-auto d-flex justify-content-center"}>
                    <h5 className="col-auto ml-auto mr-auto modal-title">{"Filtri"}</h5>
                </div>
                <div className={"col-4 d-flex justify-content-end"}>
                    <button type="button" className="btn close p-0 me-5 d-md-none" data-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                props.resetFilters()
                            }}
                    >
                        <label className="btn__text-gradient">resetta</label>
                    </button>
                </div>
            </div>
            <div className="modal-body mt-5 mt-sm-1 pt-5 pt-sm-1 pb-sm-5 px-5 px-sm-5 overflow-hidden justify-content-between">
                <div className="row mx-sm-2">
                    { props.children}
                </div>
                <div className={"mx-auto mb-5 mb-sm-0 mt-3 mt-sm-4"}>
                    <button className="btn btn--gradient" onClick={() => {props.onClose()}}>
                        {"MOSTRA RISULTATI"}
                    </button>
                </div>
            </div>

        </>
    )
}

export default FiltersModal
