import {createContext, useContext} from "react";
import Preorder from "@/models/Preorder";
import TicketOrder from "@/models/Ticketorder";
import MembershipCard from "@/models/MembershipCard";
import UserTickets from "@/models/UserTickets";

interface StoreContextIFace {
    ticketsOrder: () => TicketOrder[];
    setTicketOrder: (to: TicketOrder) => void;
    membershipCards: () => MembershipCard[];
    setMembershipCard: (to: MembershipCard[]) => void;
    setUserTickets: (to: UserTickets) => void;
    userTickets: () => UserTickets | undefined;
    getAvailableMembershipCards: (eventUuid: string, mainIssuerUuid: string) => MembershipCard[];
    preorder: () => Preorder | undefined;
    setPreorder: (p: Preorder) => void;
    deletePreorder: ()=>void;

}

// @ts-ignore
const StoreContext = createContext<StoreContextIFace>();


const useStore = (): StoreContextIFace => {
    return useContext(StoreContext)
}

export default StoreContext;
export {useStore}
