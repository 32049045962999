import React, {ReactElement, useEffect} from "react";
import UserTicket from "@/models/UserTicket";
import Moment from "react-moment";


interface SellModalProps {
    ticket: UserTicket;
    onClose: () => void;
    onConfirm: () => void;
}

const SellModal = (props: SellModalProps): ReactElement => {

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    return (
            <>
                <div className="modal-header">
                    <h5 className="mx-auto modal-title pt-3">{"Metti in Vendita"}</h5>
                    <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                props.onClose()
                            }}
                    >
                        <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                    </button>
                </div>
                <div className="modal-body container px-4 mb-3 mb-md-0 px-sm-5">
                    <div className="row">
                        <div className="sell-modal__modal__confirmation col-12">
                            <div className="sell-modal__modal__confirmation__ticket-info">
                                <label className="sell-modal__modal__content__header__type">
                                    {props.ticket.ticketDetails.reductionCode}
                                </label>

                                <h3 className="sell-modal__modal__confirmation__ticket-info__title sell-modal__modal__content__header__title">
                                    {props.ticket.mainIssuer}
                                    <span className="sell-modal__modal__content__header__title__divider"> vs </span>
                                    {props.ticket.secondaryIssuer}
                                </h3>

                                <label className="sell-modal__modal__confirmation__ticket-info__stadium">
                                    <label>
                                        {`${props.ticket.location} - `}
                                        <Moment format="DD/MM/YY">
                                            {props.ticket.date}
                                        </Moment>
                                        {" - "}
                                        <Moment format="HH:mm">
                                            {props.ticket.date}
                                        </Moment>
                                    </label>
                                </label>
                                <label
                                    className="sell-modal__modal__confirmation__ticket-info__sector">
                                    {`Settore: ${props.ticket.ticketDetails.section} n° ${props.ticket.ticketDetails.subSection}, fila: ${props.ticket.ticketDetails.row}, posto: ${props.ticket.ticketDetails.seat}`}
                                </label>
                                <label className="sell-modal__modal__confirmation__ticket-info__username font-weight-semibold">{`Associato: ${props.ticket.membershipCard.fullName}`}</label>
                                <label className="sell-modal__modal__confirmation__ticket-info__member-card">{`Tessera: ${props.ticket.membershipCard.number}`}</label>
                                <label className="sell-modal__modal__confirmation__price">{`€ ${(props.ticket.price / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}`}</label>

                            </div>
                            <label className="sell-modal__modal__confirmation__conditions">
                                {"Cliccando su conferma metterai in vendita questo biglietto.\n" +
                                "Ricorda che se vuoi vendere il biglietto attraverso altri canali, devi prima toglierlo dalla vendita su ticketag."}
                            </label>
                        </div>
                    </div>
                    <div className="row mt-auto">
                        <div className="sell-modal__modal__content__buttons col-12">
                            <button id="signup-button"
                                    className="btn btn--outlined "
                                    onClick={() => {props.onClose()}}
                            >
                                <span className="btn__text-gradient">Annulla</span>
                            </button>
                            <button
                                type="button"
                                className="btn btn--gradient"
                                onClick={props.onConfirm}
                            >
                                {"Conferma"}
                            </button>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default SellModal
