import {createContext, ReactElement, ReactNode, useContext} from "react";

interface TeamContextIFace {
    GetEventIssuer(): string | undefined;
}

// @ts-ignore
const TeamContext = createContext<TeamContextIFace>();


const useTeam = (): TeamContextIFace => {
    return useContext(TeamContext)
}

interface TeamProviderProps {
    eventIssuer?: string;
    children: ReactNode;
}

const TeamProvider = (props: TeamProviderProps): ReactElement => {
    return <TeamContext.Provider
        value={{
            GetEventIssuer(): string | undefined {
                return props.eventIssuer;
            }
        }}>
        {props.children}
    </TeamContext.Provider>
}

export {useTeam,TeamProvider}
