import {ReactElement} from "react";
import Moment from "react-moment";
import Ticketorder from "@/models/Ticketorder";
import {useStore} from "@/hoc/Store/context";
import MembershipCard from "@/models/MembershipCard";

interface CheckoutTicketDetailsProps {
    ticketOrder: Ticketorder;
}

const TicketDetailsCheckout = (props: CheckoutTicketDetailsProps): ReactElement => {

    const store = useStore()

    const membershipCard: MembershipCard | undefined = store.membershipCards().find(m => m.uuid === props.ticketOrder.targetMembershipCardUuid)

    return (
        <>
            <div className="ticket-details">
                <div className="row justify-content-between">
                    <div className="col-auto p-0">
                        <span className="ticket-details__header__title__text px-2 text--heading-color">1x</span>
                        <span className="font-weight-semibold text--main-accent">{props.ticketOrder.ticketDetails.reductionCode}</span>
                    </div>
                    <div className={"col-auto"}>
                        <p className="ticket-details__header__price text--primary-color">€ {(props.ticketOrder.price/100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}</p>
                    </div>
                </div>
                <div className="row ps-3">
                    <div className={"col-12 pt-1"}>
                        <label className="label-2 ticket-details__body__label ticket-details__body__label--first-line">
                            <span className="ticket-details__header__title">{props.ticketOrder.mainIssuer}</span> vs <span className="ticket-details__header__title">{props.ticketOrder.secondaryIssuer}</span><br/>
                        </label>
                    </div>
                    <div className={"col-12"}>
                        <label className="label-2 ticket-details__body__label ticket-details__body__label--first-line">
                            <span className="ticket-details__header__title"><Moment locale={"it"} format={"dddd D MMMM"}>{props.ticketOrder.date}</Moment></span>
                        </label>
                    </div>
                    <div className={"col-12"}>
                        <label className="label-2 ticket-details__body__label ticket-details__body__label--first-line">
                            <span className="ticket-details__header__title">Stadio {props.ticketOrder.location}</span>
                        </label>
                    </div>
                    <div className={"col-12"}>
                        <label className="label-2 ticket-details__body__label ticket-details__body__label--first-line">
                            <span className="ticket-details__body__label--sector">{`Settore: ${props.ticketOrder.ticketDetails.section} n° ${props.ticketOrder.ticketDetails.subSection}`}</span>
                        </label>
                    </div>
                    <div className={"col-12"}>
                        <label className="label-2 ticket-details__body__label ticket-details__body__label--first-line">
                            <span className="ticket-details__body__label--seat"> {`Fila: ${props.ticketOrder.ticketDetails.row}, Posto: ${props.ticketOrder.ticketDetails.seat}`}</span>
                        </label>
                    </div>
                </div>
                <div className={"row ps-3 pt-2"}>
                    <div className={"col-12"}>
                        <label className="label-1 ticket-details__footer__title text--primary-color">
                            TESSERA ASSOCIATA
                        </label>
                    </div>
                    <div className={"col-12"}>
                        <label className="label-2 ticket-details__footer__content">
                            <span className="ticket-details__footer__label--user text--primary-color">{ membershipCard ? membershipCard.fullName : ""}</span>
                            <span className="ticket-details__footer__label--birthdate text--heading-color">{membershipCard ? membershipCard.number : ""}</span>
                        </label>
                    </div>
                </div>
                <div className={"ticket-details__footer"}></div>
            </div>
        </>
    )
}

export default TicketDetailsCheckout;
