import {ReactElement, useEffect, useState} from "react";
import Checkbox from "@/components/Checkbox";
import ReductionCode from "@/models/ReductionCode";


interface ReductionCodesFilterProps {
    title?: string;
    filterReductionCodes: string[];
    reductionCodes: ReductionCode[];
    updateFilter: (val: string[]) => void;
}


const ReductionCodesFilter = (props: ReductionCodesFilterProps): ReactElement => {
    const [activeReductionCodes, setActiveReductionCodes] = useState<string[]>(props.filterReductionCodes);

    useEffect(() => {
        props.updateFilter(activeReductionCodes)
    }, [activeReductionCodes])

    const handleChange = (toggled: string, checked: boolean): void => {
        const idx = activeReductionCodes.indexOf(toggled);
        if (!checked) {
            setActiveReductionCodes(activeReductionCodes.filter((rCode: string) => rCode !== toggled))
        } else {
            if (idx !== -1) {
                setActiveReductionCodes(activeReductionCodes.filter((rCode: string) => rCode !== toggled))
            } else {
                setActiveReductionCodes([...activeReductionCodes, toggled])
            }
        }
    }


    return (
        <div className="col-12 filter__body">
            {
                props.title
                    ?
                    <div className="filter__body__info">
                        <label className="label filter__body__info__label text--primary-color">{props.title}</label>
                    </div>
                    :
                    null
            }
            <div className="filter__body__element filter__body__element--typology mb-3">
                {
                    props.reductionCodes.map((rCode, i) => {
                        return (
                            <Checkbox
                                key={i}
                                id={rCode.uuid}
                                title={rCode.name}
                                value={rCode.uuid}
                                onToggle={(checked) => {
                                    handleChange(rCode.uuid, checked)
                                }}
                                parentClass={""}
                                checked={(activeReductionCodes.indexOf(rCode.uuid) > -1)}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ReductionCodesFilter
