import React, {ReactElement, ReactNode, useState} from "react";
import SeoContext from "@/hoc/Seo/context";
import {Helmet} from "react-helmet";
// @ts-ignore
import seoData from "@/static/json/seo.json";

interface SeoProviderProps {
    children: ReactNode;
}

export enum SeoPage {
    homepage = "homePage",
    partite = "partite",
    partita = "partita",
    faq = "faq",
    terminiCondizioni = "terminiCondizioni",
    privacyPolicy = "privacyPolicy",
    auth = "auth",
    profiloAccount = "profiloAccount",
    profiloTessere = "profiloTessere",
    profiloBiglietti = "profiloBiglietti",
    profiloPortafoglio = "profiloPortafoglio",
    carrello = "carrello",
    checkout = "checkout",
    notFound = "notFound",
}

interface Seo {
    title: string;
    description: string;
    keywords?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    ogUrl?: string;
    ogType?: string;
    ogLocale?: string;
    noindex?: boolean;
}


const SeoProvider = (props: SeoProviderProps): ReactElement => {
    const [seoPage, setSeoPage] = useState<SeoPage>(SeoPage.homepage);
    // @ts-ignore
    const seo: Seo = seoData[seoPage] || seoData[SeoPage.homepage]

    return <SeoContext.Provider value={{
        setSeo: setSeoPage
    }}>
        <>
            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description}/>
                {
                    seo.keywords ?
                        <meta name="keywords" content={seo.keywords}/>
                        :
                        null
                }
                {
                    seo.ogTitle ?
                        <meta property="og:title" content={seo.ogTitle}/>
                        :
                        null
                }
                {
                    seo.ogDescription ?
                        <meta property="og:description" content={seo.ogDescription}/>
                        :
                        null
                }
                {
                    seo.ogImage ?
                        <meta property="og:image" content={seo.ogImage}/>
                        :
                        null
                }
                {
                    seo.ogUrl ?
                        <meta property="og:url" content={seo.ogUrl}/>
                        :
                        null
                }
                {
                    seo.ogType ?
                        <meta property="og:type" content={seo.ogType ?? "website"}/>
                        :
                        null
                }
                {
                    seo.ogLocale ?
                        <meta property="og:locale" content={seo.ogLocale ?? "it_IT"}/>
                        :
                        null
                }
                {
                    seo.noindex ?
                        <meta name="robots" content="noindex"/>
                        :
                        null
                }
            </Helmet>
            {props.children}
        </>
    </SeoContext.Provider>
}

export default SeoProvider
