import {ReactElement, useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useApi} from "@/hoc/Api/context";
import {isSafari} from "react-device-detect";
import {useNavigate} from "react-router-dom";

interface SignupForm {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    readTerms: boolean;
    agreeTermsAndCondition: boolean;
    agreePrivacy: boolean
    agreeMarketing: boolean
}

interface SignupProps {
    onSubmit: () => void;
}

const Signup = (props: SignupProps): ReactElement => {
    const api = useApi()
    const navigate = useNavigate()
    const {register, formState, handleSubmit, setValue, getValues, trigger} = useForm<SignupForm>({mode: "onChange"})
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
    const [requirementsPassword, setRequirementsPassword] = useState<boolean>(false)

    useEffect(() => {
        register("password", {required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([a-zA-Z\d$&+,:;=?_@#|'<>.^*()%!-]+){8,}$/})
    }, [])

    const handleSignIn = (data: SignupForm): void => {
        api.Register(data.email, data.password, data.name, data.agreePrivacy, data.agreeTermsAndCondition, data.agreeMarketing).then(r => {
            toast(`Abbiamo inviato una mail di conferma all'indirizzo ${r.email}`, {type: "success"})
            props.onSubmit()
        }).catch(e => {
            switch (e.response.status) {
                case 401:
                    toast.error("Spiacenti, questo indirizzo email è già registrato")
                    break
                default:
                    toast.error("Spiacenti, si è verificato un errore")
                    break
            }
        })
    }

    return (
        <div id="signup" className="signup-in__content">
            <hr className="separator signup-in__separator col-12"/>
            <form id="signupForm"
                  onSubmit={handleSubmit(handleSignIn)}
                  className="needs-validation"
                  noValidate
            >
                {/* NOME */}
                <div className="form-group">
                    <label htmlFor="name">Nome</label>
                    <input id="name"
                           autoComplete="on"
                           type="text"
                           className={`form-control col-lg-8 col-12 ${formState.errors.name ? 'is-invalid' : ''}`}
                           {...register("name", {
                               required: true,
                               minLength: 1
                           })}
                           name="name"
                    />
                    <div className="invalid-feedback">
                        Questo campo non può rimanere vuoto
                    </div>
                </div>

                {/* EMAIL */}
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input id="email"
                           autoComplete="on"
                           type="email"
                           className={`form-control col-lg-8 col-12 ${formState.errors.email ? 'is-invalid' : ''}`}
                           {...register("email", {
                               required: true,
                               pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                           })}
                           name="email"
                    />
                    <div className="invalid-feedback">
                        Errore, inserire una mail valida
                    </div>
                </div>

                {/* PASSWORD */}
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-group">
                        <input id="password"
                               autoComplete="on"
                               type={showPassword ? "text" : "password"}
                               className={`form-control col-lg-8 col-12 ${formState.errors.password ? 'is-invalid' : ''}`}
                               onChange={(event) => {
                                   setValue("password", event.target.value, {shouldValidate: true})
                                   trigger("confirmPassword")
                               }}
                               name="password"
                        />
                        <span className={`${isSafari ? "showPasswordButton__safari": "showPasswordButton "} icon icon--eye icon--default-no-opacity
                                                ${showPassword ? "icon--eye--closed" : null}
                                                ${formState.errors.password ? "showPasswordButton__translate" : null}`}
                              onClick={() => {
                                  setShowPassword(!showPassword)
                              }}
                        ></span>
                        <div className="invalid-feedback">
                            Errore, la password non rispetta i requisiti
                            <br/>
                            <span className="text-decoration-underline"
                                  onClick={() => {
                                      setRequirementsPassword(!requirementsPassword)
                                  }}
                            >
                                Requisiti:
                            </span>
                            {
                                requirementsPassword?
                                    (
                                        <ul>
                                            <li>Almeno una Lettera Maiuscola</li>
                                            <li>Almeno una Lettera minuscola</li>
                                            <li>Almeno un Numero</li>
                                            <li>Lunghezza minima 8 caratteri</li>
                                            <li>Non deve contenere spazi</li>
                                            <li>Caratteri speciali ammessi:</li>
                                            <ul>
                                                <li>{" & + , : ; = ? _ @ # | ' < > . ^ * ( ) % !"}</li>
                                            </ul>
                                        </ul>
                                    )
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>

                {/* CONFIRM PASSWORD */}
                <div className="form-group">
                    <label htmlFor="confirmPassword">Ripeti Password</label>
                    <div className="input-group">
                        <input id="confirmPassword"
                               autoComplete="on"
                               type={showConfirmPassword ? "text" : "password"}
                               className={`form-control col-lg-8 col-12 ${formState.errors.confirmPassword ? 'is-invalid' : ''}`}
                               {...register("confirmPassword", {
                                   required: true,
                                   validate: val => {return val === getValues("password")}
                               })}
                               name="confirmPassword"
                        />
                        <span className={`${isSafari ? "showPasswordButton__safari": "showPasswordButton "} icon icon--eye icon--default-no-opacity
                                            ${showConfirmPassword ? "icon--eye--closed" : null}
                                            ${formState.errors.confirmPassword ? "showPasswordButton__translate" : null}`}
                              onClick={() => {
                                  setShowConfirmPassword(!showConfirmPassword)
                              }}
                        ></span>
                        <div className="invalid-feedback">
                            Le due password non coincidono
                        </div>
                    </div>
                </div>

                {/* READ TERMS, CONDITION AND PRIVACY */}
                <div className={"row py-2"}>
                    <div className={"col-auto"}>
                        <div className={`form-group form-check`}>
                            <input type="checkbox"
                                   className={`form-check-input ${formState.errors.readTerms ? 'form-check-input-invalid' : ''}`}
                                   id={"readTerms"}
                                   {...register("readTerms",
                                       {
                                           required: true,
                                           validate: val => {
                                               return val
                                           }
                                       })}
                                   name={"readTerms"}
                            />
                        </div>
                    </div>
                    <div className={"col p-0"}>
                        <label className="form-check-label">
                            Dichiaro di aver letto, compreso e accettato i
                            <span className="text--clickable-links cursor-pointer" onClick={() => navigate("/Termini-e-Condizioni")}>
                                {" Termini e Condizioni "}
                            </span>
                            e le
                            <span className="text--clickable-links cursor-pointer" onClick={() => navigate("/Privacy-Policy")}>
                                {" Privacy Policy "}
                            </span>
                        </label>
                    </div>
                </div>

                {/* AGREE TERMS AND CONDITIONS */}
                <div className={"row py-2"}>
                    <div className={"col-auto"}>
                        <div className={`form-group form-check`}>
                            <input type="checkbox"
                                   className={`form-check-input ${formState.errors.agreeTermsAndCondition ? 'form-check-input-invalid' : ''}`}
                                   id={"agreeTermsAndCondition"}
                                   {...register("agreeTermsAndCondition",
                                       {
                                           required: true,
                                           validate: val => {
                                               return val
                                           }
                                       })}
                                   name={"agreeTermsAndCondition"}
                            />
                        </div>
                    </div>
                    <div className={"col p-0"}>
                        <label className="form-check-label">
                            Dichiaro espressamente di aver letto, compreso e accettato i seguenti articoli dei
                            <span className="text--clickable-links cursor-pointer" onClick={() => navigate("/Termini-e-Condizioni")}>
                                {" Termini e Condizioni"}
                            </span>
                        </label>
                    </div>
                </div>

                {/* DETAILS TERMS AND CONDITIONS */}
                <div>
                    <label className="form-check-label">
                        <ul className={"mb-0"}>
                            <li>Presentazione (applicabilità dei
                                <span className="text--clickable-links cursor-pointer" onClick={() => navigate("/Termini-e-Condizioni")}>
                                    {" Termini e Condizioni "}
                                </span>
                                )
                            </li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>Requisiti dell’utente</li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>Obblighi, garanzie e responsabilità dell’utente</li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>Acquisto dei Biglietti (rinuncia al diritto di recesso)</li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>vendita dei Biglietti (rinuncia al diritto di recesso)</li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>Durata, sospensione o cessazione dei servizi e recesso dell’utente</li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>Legge applicabile e foro competente</li>
                        </ul>
                        <ul className={"mb-0"}>
                            <li>Varie (cessione, manleva e indennizzo)</li>
                        </ul>
                    </label>
                </div>

                {/* READ PRIVACY */}
                <div className={"row py-2"}>
                    <div className={"col-auto"}>
                        <div className={`form-group form-check`}>
                            <input type="checkbox"
                                   className={`form-check-input ${formState.errors.agreePrivacy ? 'form-check-input-invalid' : ''}`}
                                   id={"agreePrivacy"}
                                   {...register("agreePrivacy",
                                       {
                                           required: true,
                                           validate: val => {
                                               return val
                                           }
                                       })}
                                   name={"agreePrivacy"}
                            />
                        </div>
                    </div>
                    <div className={"col p-0"}>
                        <label className="form-check-label">
                            Dichiaro espressamente di aver letto l’Informativa sulle
                            <span className="text--clickable-links cursor-pointer" onClick={() => navigate("/Privacy-Policy")}>
                                {" Privacy Policy "}
                            </span>
                        </label>
                    </div>
                </div>

                <div className={"row py-2"}>
                    <div className={"col-auto"}>
                        <div className={`form-group form-check`}>
                            <input type="checkbox"
                                   className={`form-check-input ${formState.errors.agreeMarketing ? 'form-check-input-invalid' : ''}`}
                                   id={"agreeMarketing"}
                                   {...register("agreeMarketing",
                                       {
                                           required: false
                                           })}
                                   name={"agreeMarketing"}
                            />
                        </div>
                    </div>
                    <div className={"col p-0"}>
                        <label className="form-check-label">
                            Acconsento al trattamento dei miei dati personali per finalità di marketing, sia tramite
                            email che tramite telefono, su prodotti di Ticketag e su prodotti/servizi di terze parti.
                            Sono consapevole che posso revocare in qualsiasi momento il consenso o limitarne
                            l’estensione a specifici mezzi di comunicazione inviando una richiesta a
                            <a className="text--clickable-links" href="mailto:ticketagsrl@pec.it" style={{fontSize: 12}}>
                                {" ticketagsrl@pec.it "}
                            </a>
                        </label>
                    </div>
                </div>

                {/* DETAIL PRIVACY */}
                <div className={"mb-4 mb-sm-3"}>
                    <label className="form-check-label px-2">

                    </label>
                </div>

                {/* SIGN IN BUTTON */}
                <div className="mb-1 mb-sm-5">
                    <button type="submit"
                            className="btn btn--gradient signup-in__btn--gradient"
                            disabled={!formState.isValid}>
                        CREA IL TUO ACCOUNT
                    </button>
                </div>

            </form>
        </div>
    )
}

export default Signup
