import {ReactElement, useState} from "react";
import UserTicket from "@/models/UserTicket";
import Media from "react-media";
import Moment from "react-moment";
import {useApi} from "@/hoc/Api/context";
import {Modal} from "react-bootstrap";
import SellModal from "@/components/SellModal";
import {useStore} from "@/hoc/Store/context";
import {toast} from "react-toastify";

export enum SellTicketType {
    SELLING,
    SELLABLE,
    BOUGHT,
    UNSOLD
}

interface SellTicketProps {
    noFilter: boolean;
    noHeader: boolean;
    type: SellTicketType;
    ticket: UserTicket;
}

const SellTicket = (props: SellTicketProps): ReactElement => {
    const api = useApi()
    const store = useStore()
    const [showSellModal, setShowSellModal] = useState<boolean>(false)

    const sellTicket = (t: UserTicket): void => {
        setShowSellModal(true)
    }

    const unsellTicket = (t: UserTicket): void => {
        api.UnsellTicket(props.ticket.uuid).then(r => {
            api.GetUserTickets().then(r => {
                store.setUserTickets(r)
            })
        }).catch(() => {
            toast.error("Il biglietto non può essere tolto dalla vendita in questo momento, riprova più tardi")
        })
    }
    const downloadReceipt = () => {
        api.GetRawMedia(props.ticket.receiptUuid)
    }
    return (
        <>
            <div className={`sell-ticket`}>
                <div className="sell-ticket__contents offset-xl-1">

                    <div
                        className={`sell-ticket__contents__wrapper justify-content-between d-md-flex ${props.type === SellTicketType.UNSOLD ? "opacity-50" : ""}`}>
                        {/* LEFT SIDE (info) */}
                        <div
                            className={`col-md-auto col-lg-${props.type === SellTicketType.BOUGHT ? "5" : "4"} sell-ticket__contents__info`}>
                            <div className="sell-ticket__contents__header">

                                <label className="label-2">{props.ticket.championship}</label>

                                {/* SQUADRA VS SQUADRA + REDUCTION*/}
                                <h3 className="sell-ticket__contents__header__title">
                                    <span>{props.ticket.mainIssuer}</span>
                                    <span className="sell-ticket__contents__header__title__divider"> vs </span>
                                    <span className={"pe-2"}>{props.ticket.secondaryIssuer}</span>
                                    <label className="sell-ticket__contents__header__type text--main-accent">
                                        {props.ticket.ticketDetails.reductionCode}
                                    </label>
                                </h3>

                                {/* INFO TICKET: location - date - section - seat*/}
                                <div className="sell-ticket__contents__info__match">
                                    <label>
                                        {`${props.ticket.location} - `}
                                        <Moment format="DD/MM/YY" locale={"it"}>
                                            {props.ticket.date}
                                        </Moment>
                                        {" - "}
                                        <Moment format="HH:mm" locale={"it"}>
                                            {props.ticket.date}
                                        </Moment>
                                    </label>
                                    <label>
                                        {"Settore: "}{props.ticket.ticketDetails.section}
                                        {" Area: "}{props.ticket.ticketDetails.subSection}
                                    </label>
                                    <label>
                                        {`Fila: ${props.ticket.ticketDetails.row}, Posto: ${props.ticket.ticketDetails.seat}`}
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* CENTER (TESSERA) */}
                        {
                            <div
                                className="col-12 col-md-3 sell-ticket__contents__user-info align-items-center justify-content-center">
                                <div className="sell-ticket__contents__info__ticket pt-0 pt-md-4">
                                    <label className="sell-ticket__contents__info__ticket__username">
                                        {props.ticket.membershipCard.fullName}
                                    </label>
                                    <label>
                                        {`Tessera N: ${props.ticket.membershipCard.number}`}
                                    </label>
                                </div>
                            </div>
                        }

                        {/* RIGHT SIDE (buttons - actions) */}
                        <div
                            className={`col-12 col-md-${props.type === SellTicketType.BOUGHT ? "3" : "4"} justify-content-center align-self-center`}>
                            <div
                                className={`sell-ticket__contents__sell ${props.type === SellTicketType.BOUGHT ? "justify-content-start" : "justify-content-center"}`}>
                                <Media query="(min-width: 768px)">
                                    {
                                        matches => matches ?
                                            (
                                                (props.type === SellTicketType.BOUGHT)
                                                    ?
                                                    <div className="row justify-content-center">
                                                        <div className={"col-auto d-flex justify-content-center"}
                                                             style={{minWidth: "200px"}}>
                                                            <button className={"btn btn--outlined m-0"}
                                                                    onClick={() => {
                                                                        downloadReceipt()
                                                                    }}
                                                            >
                                                            <span className="btn__text-gradient">
                                                                SCARICA PDF
                                                            </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="px-2 row justify-content-center">
                                                        <div className={"col-auto d-flex justify-content-center"}
                                                             style={{minWidth: "100px"}}>
                                                            <label
                                                                className="sell-ticket__contents__sell__price d-flex align-items-center">
                                                                {"€"} {(props.ticket.price / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                                                            </label>
                                                        </div>
                                                        <div className={"col-auto d-flex justify-content-center"}
                                                             style={{minWidth: "200px"}}>
                                                            {
                                                                props.type === SellTicketType.UNSOLD ?
                                                                    <span className={"btn__text-gradient-red py-3"}>
                                                                        {"Non venduto"}
                                                                    </span>
                                                                    :
                                                                    <>
                                                                        <button
                                                                            className={(props.type === SellTicketType.SELLING) ? "btn btn--outlined btn--outlined--red" : "btn btn--outlined"}
                                                                            onClick={() => {
                                                                                if (props.type === SellTicketType.SELLABLE) {
                                                                                    sellTicket(props.ticket)
                                                                                } else if (props.type === SellTicketType.SELLING) {
                                                                                    unsellTicket(props.ticket)
                                                                                }
                                                                            }}
                                                                        >
                                                                            <span
                                                                                className={(props.type === SellTicketType.SELLABLE) ? "btn__text-gradient" : "btn__text-gradient-red"}>
                                                                                {(props.type === SellTicketType.SELLABLE) ? "Vendi" : "Rimuovi"}
                                                                            </span>
                                                                        </button>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                            )
                                            :
                                            (
                                                (props.type === SellTicketType.BOUGHT)
                                                    ?
                                                    <div className="row justify-content-start align-self-start">
                                                        <div className={"col-auto d-flex justify-content-start"}
                                                             style={{minWidth: "200px"}}>
                                                            <button className={"btn btn--outlined m-0"}
                                                                    onClick={() => {
                                                                        downloadReceipt()
                                                                    }}
                                                            >
                                                            <span className="btn__text-gradient">
                                                                SCARICA PDF
                                                            </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="px-2 row justify-content-between w-100">
                                                        <div className={"col-auto d-flex p-0 justify-content-center"}
                                                             style={{minWidth: "80px"}}>
                                                            <label
                                                                className="sell-ticket__contents__sell__price d-flex align-items-center">
                                                                {"€"} {(props.ticket.price / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                                                            </label>
                                                        </div>
                                                        <div className={"col-auto d-flex p-0 justify-content-end"}
                                                             style={{minWidth: "160px"}}>
                                                            {
                                                                props.type === SellTicketType.UNSOLD ?
                                                                    <span className={"btn__text-gradient-red py-3"}>
                                                                        {"Non venduto"}
                                                                    </span>
                                                                    :
                                                                    <>
                                                                        <button
                                                                            className={(props.type === SellTicketType.SELLING) ? "btn btn--outlined btn--outlined--red" : "btn btn--outlined"}
                                                                            onClick={() => {
                                                                                if (props.type === SellTicketType.SELLABLE) {
                                                                                    sellTicket(props.ticket)
                                                                                } else if (props.type === SellTicketType.SELLING) {
                                                                                    unsellTicket(props.ticket)
                                                                                }
                                                                            }}
                                                                        >
                                                                        <span
                                                                            className={(props.type === SellTicketType.SELLABLE) ? "btn__text-gradient" : "btn__text-gradient-red"}>
                                                                            {(props.type === SellTicketType.SELLABLE) ? "Vendi" : "Rimuovi"}
                                                                        </span>
                                                                        </button>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                            )
                                    }
                                </Media>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Modal show={showSellModal}
                   onHide={() => setShowSellModal(false)}
                   dialogClassName={"sell-modal__modal"}
                   contentClassName={"sell-modal__modal__content"}
            >
                <SellModal ticket={props.ticket} onClose={() => setShowSellModal(false)}
                           onConfirm={() => {
                               api.SellTicket(props.ticket.uuid).then(r => {
                                   api.GetUserTickets().then(r => {
                                       store.setUserTickets(r)
                                   })
                                   setShowSellModal(false)
                               }).catch(() => {
                                   toast.error("Spiacenti, si è verificato un errore")
                               })
                           }}
                />
            </Modal>
        </>

    )
}

export default SellTicket
