import {ReactElement, ReactNode, useEffect, useState} from "react";
import ReactResizeDetector from 'react-resize-detector';

interface SteperProps {
    numSteps: number;
    className?: string;
    onChange?: (step: number) => void
    onBeforeChange?: (oldStep: number, step: number) => void
    maxWidth?: number;
    initialStep?: number;
    currentStep: number;
}

const Stepper = (props: SteperProps): ReactElement => {
    const [step, setStep] = useState<number>(props.currentStep);
    const [sepStyle, setSepStyle] = useState<any>({});
    useEffect(() => {
        setStep(props.currentStep)
    }, [props.currentStep])
    const handleSetStep = (stepNumber: number): void => {
        if ((props.onBeforeChange ? props.onBeforeChange(step, stepNumber) : true) === null) {
            return
        }
        setStep(stepNumber)

        if (props.onChange) {
            props.onChange(stepNumber)
        }
    }

    const onContainerResize = (width: number | undefined): void => {
        if (width === undefined) {
            return
        }
        let maxWidth = 600
        let minWidth = 150
        let targetWith = (width > minWidth ? width : minWidth)
        targetWith = (targetWith < maxWidth ? targetWith : maxWidth)
        let sepWidth = (targetWith - ((25 * props.numSteps) + (20 * (props.numSteps - 1)) + 40)) / (props.numSteps - 1)
        setSepStyle({
            width: sepWidth
        })
    }

    const renderStep = (stepNumber: number): ReactNode => {
        return <div onClick={() => stepNumber < step ? handleSetStep(stepNumber) : null}
                    key={stepNumber}
                    className={`upload-modal__progress__step  ${step === stepNumber ? "active" : ''} ${stepNumber > step ? 'disabled' : ''} `}>
            {stepNumber + 1}
            <span className="sep" style={sepStyle}/>
        </div>
    }

    return (
        <ReactResizeDetector handleWidth onResize={(arg) => {
            onContainerResize(arg)
        }}>
            <div className={`upload-modal__progress  ${props.className ? props.className : ''}`} style={{
                maxWidth: props.maxWidth || 600,
                minWidth: 150
            }}>
                {Array.from(Array(props.numSteps || 2).keys()).map(i => renderStep(i))}
            </div>
        </ReactResizeDetector>
    )
}

export default Stepper
