import { ReactElement, ReactNode, useEffect, useState } from "react";
import { useApi } from "@/hoc/Api/context";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "@/components/Loader";
import LoginContext from "./context";
import { AuthUser } from "@/models/AuthUser";

interface LoginProviderProps {
    children: ReactNode;
}

const LoginProvider = (props: LoginProviderProps): ReactElement => {
    const api = useApi()
    const navigate = useNavigate()
    const [authUser, setAuthUser] = useState<AuthUser>({ email: undefined, role: undefined });
    const [proceed, setProceed] = useState<boolean>(false);
    let location = useLocation();

    useEffect(() => {
        api.IsLogged().then(resp => {
            if (!resp.isLogged) {
                navigate(`/Auth?from=${location.pathname}`)
            } else {
                setProceed(true)
                setAuthUser(resp.authUser!)
            }
        })
    }, [])

    if (!proceed) {
        return <Loader />
    }

    return <LoginContext.Provider value={{ authUser }}>
        {props.children}
    </LoginContext.Provider>
}

export default LoginProvider
