import BalanceLog, {BalanceLogType} from "@/models/BalanceLog";
import moment from "moment";

interface EventsOptions {
    value: number;
    label: string;
}

export enum PaginationActionKind {
    FORWARD = "forward",
    BACKWARD = "backward",
    FORWARD_LONG = "forward_long",
    BACKWARD_LONG = "backward_long",
    CHANGE_ROW_PER_PAGE = "change_row_per_page",
    FILTER_TRANSACTION_TYPE = "filter_transaction_type",
    FILTER_DATE = "filter_date",
    SET_INITIAL_DATA = "set_initial_data",
}

interface payloadType {
    rowsPerPage?: number;
    filterTransactionType?: BalanceLogType;
    filterDate?: moment.Moment;
    initialData?: BalanceLog[];
}

export interface PaginationAction {
    type: PaginationActionKind;
    payload?: payloadType;
}

export interface PaginationState {
    immutableUserTransaction: BalanceLog[];
    userTransactions: BalanceLog[];
    filterTransactionType: BalanceLogType | undefined;
    filterDate: moment.Moment | undefined;
    rowsPerPage: number;
    rowsMax: number;
    firstRow: number;
    lastRow: number;
    backwardDisabled: boolean;
    forwardDisabled: boolean;
    backwardLongDisabled: boolean;
    forwardLongDisabled: boolean;
    eventsOptions: EventsOptions[];
}
