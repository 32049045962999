import {ReactElement, useEffect, useState} from "react";
import PayoutHistory from "@/components/PayoutHistory/PayoutHistory";
import {useApi} from "@/hoc/Api/context";
import UserInfo from "@/models/UserInfo";
import Loader from "@/components/Loader";
import {Modal} from "react-bootstrap";
import PayoutModal from "@/components/PayoutModal";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";
import {toast} from "react-toastify";

const Balance = ({...props}): ReactElement => {
    const api = useApi();
    const seo = useSeo();
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [showPayoutModal, setShowPayoutModal] = useState<boolean>(false);

    const updateUserInfo = () => {
        api.UserInfo().then(u => setUserInfo(u))
            .catch(() => {toast.error("Spiacenti, si è verificato un errore")})
    }

    useEffect(() => {
        seo.setSeo(SeoPage.profiloPortafoglio)
        updateUserInfo()
    }, [])

    if (!userInfo) {
        return <Loader />
    }

    return (
        <div className="balance container">

            {/* HEADER */}
            <div className="row balance__header justify-content-evenly">

                {/* SALDO DISPONIBILE */}
                <div className="col-md-5 col-11">

                    {/* ICON + TITLE */}
                    <div className="row pb-1 mb-4">
                        <div className="col-auto ps-3 pe-1 d-none d-md-flex align-items-center">
                            <i className="icon icon--available-balance icon-balance"/>
                        </div>
                        <div className="col-auto px-auto px-md-0 d-flex align-items-center">
                            <label className="title-balance text--primary-color">
                                Saldo disponibile
                            </label>
                        </div>
                    </div>

                    {/* BALANCE + BUTTON */}
                    <div className="row pb-1 mb-4">
                        <div className="col-auto ps-3 pe-1 d-flex d-md-none align-items-center">
                            <i className="icon icon--available-balance icon-balance"/>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <label className="available-balance">
                                {"€ " + (userInfo?.availableBalance / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                            </label>
                        </div>
                        <div className="col-auto d-none d-lg-flex align-items-center">
                            <button className="btn btn--outlined py-1"
                                    onClick={() => setShowPayoutModal(!showPayoutModal)}
                                    disabled={userInfo?.availableBalance === 0}
                            >
                                <span className="btn__text-gradient">Preleva</span>
                            </button>
                        </div>
                    </div>

                    {/* DESCRIPTION */}
                    <div className="row pb-1 mb-4">
                        <label className="description-balance text--heading-color">
                            Questi sono i fondi disponibili per il prelievo
                        </label>
                    </div>

                    <div className="row pb-1 mb-4 d-flex d-lg-none">
                        <div className="col-auto d-flex py-4 px-md-0 align-items-center">
                            <button className="btn btn--outlined py-1"
                                    onClick={() => setShowPayoutModal(!showPayoutModal)}
                                    disabled={userInfo?.availableBalance === 0}
                            >
                                <span className="btn__text-gradient">Preleva</span>
                            </button>
                        </div>
                    </div>

                </div>

                {/* FONDI IN ARRIVO */}
                <div className="col-md-5 col-11">
                    <div className="row pb-1 mb-4 d-flex align-items-center">
                        <label className="title-funds text--primary-color">
                            Fondi in arrivo
                        </label>
                    </div>
                    <div className="row pb-1 mb-4">
                        <label className="funds-coming text--heading-color">
                            {"€ " + ((userInfo?.totalBalance - userInfo?.availableBalance)/100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                        </label>
                    </div>
                    <div className="row">
                        <label className="description-funds text--heading-color">
                            Questi fondi sono bloccati e saranno presto disponibili per il prelievo.
                        </label>
                    </div>
                </div>

            </div>

            {/* HISTORY */}
            <div className={"col-12"}>
                <PayoutHistory/>
            </div>

            {
                showPayoutModal ?
                    <>
                        <Modal show={showPayoutModal}
                               onHide={() => {
                                   setShowPayoutModal(false)
                               }}
                               contentClassName={"ticket-cards__modal__content"}
                               dialogClassName={"ticket-cards__modal"}
                        >
                            <PayoutModal
                                userInfo={userInfo}
                                onComplete={() => {
                                    setShowPayoutModal(false)
                                    updateUserInfo()
                                }}
                                onClose={() => {
                                    setShowPayoutModal(false)
                                }}
                            />
                        </Modal>
                    </>
                    :
                    null
            }

        </div>
    )
}

export default Balance
