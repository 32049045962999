import {ReactElement, ReactNode} from 'react'

import Footer from './Footer'
import Header from './Header'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
interface LayoutProps {
    children: ReactNode
    noFooter?: boolean
}

const Layout = (props: LayoutProps): ReactElement => {
  return (
        <div className="layout-container row justify-content-between">
            <div className="col-12 p-0">
                <Header/>
            </div>
            <div className="col-12 p-0 toast-content">
                <ToastContainer/>
            </div>
            <div className="col-12 p-0">
                {props.children}
            </div>
            {
                props.noFooter ?
                null :
                    <div className="col-12 p-0 d-flex justify-content-end">
                        <Footer/>
                    </div>
            }
        </div>
  )
}

export default Layout
