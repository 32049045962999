import 'bootstrap'
import './sass/main.scss'
import {BrowserRouter, createBrowserRouter, Outlet, Route, RouterProvider, Routes} from 'react-router-dom'

import Auth from './pages/Auth/Auth'
import Carrello from './pages/Carrello/Carrello'
import Checkout from './pages/Checkout/Checkout'
import Faq from './pages/Faq/Faq'
import Home from './pages/Home/Home'
import Pdp from './pages/Pdp/Pdp'
import Plp from './pages/Plp/Plp'
import NotFound404 from './pages/404/NotFound404'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Profile from './pages/Profile/Profile'
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
import ApiProvider from "@/hoc/Api/provider";
import CheckoutCompleted from "@/pages/CheckoutCompleted/CheckoutCompleted";
import StoreProvider from "@/hoc/Store/provider";
import LoginProvider from "@/hoc/Login/LoginProvider";
import SeoProvider from "@/hoc/Seo/provider";
import {TeamProvider} from "@/hoc/Team/TeamProvider";
import {CookiesProvider} from "@/hoc/Cookies/Cookies";
import Admin from './pages/Admin/Admin'
import Validazioni from './pages/Validazioni/Validationi'
import React from "react";

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <ApiProvider baseUrl={process.env.REACT_APP_API_BASE_PATH ?? "https://www.ticketag.dev/api"}>
                <Outlet/>
            </ApiProvider>,
            children: [
                {
                    path: '',
                    element: <Home/>
                },
                {
                    path: '/Partite',
                    element: <Plp/>
                },
                {
                    path: '/Partita/:id',
                    element: <Pdp/>
                },
                {
                    path: '/Auth/*',
                    element: <Auth/>
                },
                {
                    path: '/Profilo/*',
                    element: <LoginProvider><Profile/></LoginProvider>
                },
                {
                    path: '/Carrello',
                    element: <LoginProvider><Carrello/></LoginProvider>
                },
                {
                    path: '/Checkout/:id',
                    element: <LoginProvider><CheckoutCompleted/></LoginProvider>
                },
                {
                    path: '/Checkout',
                    element: <LoginProvider><Checkout/></LoginProvider>
                },
                {
                    path: '/Termini-e-Condizioni',
                    element: <TermsAndConditions/>
                },
                {
                    path: '/Privacy-Policy',
                    element: <PrivacyPolicy/>
                },
                {
                    path: '/Faq',
                    element: <Faq/>
                },
                {
                    path: '/Admin',
                    element: <LoginProvider><Admin/></LoginProvider>
                },
                {
                    path: '/Validazioni/:id',
                    element: <LoginProvider><Validazioni/></LoginProvider>
                },
                {
                    path: '*',
                    element: <NotFound404/>
                }
            ]
        }
    ])
    return (
        <TeamProvider eventIssuer={process.env.REACT_APP_EVENT_ISSUER || undefined}>
            <StoreProvider>
                <CookiesProvider>
                    <SeoProvider>
                        <RouterProvider router={router}/>
                    </SeoProvider>
                </CookiesProvider>
            </StoreProvider>
        </TeamProvider>

    )
}

export default App
