import {ReactElement, useState} from "react";
import {useForm} from "react-hook-form";
import {useApi} from "@/hoc/Api/context";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import LostPasswordModal from "@/components/LostPasswordModal";
import {Modal} from "react-bootstrap";
import { isSafari } from 'react-device-detect';

interface loginForm {
    email: string;
    password: string;
}

interface loginProps {
    from: string | undefined;
}

const Login = (props: loginProps): ReactElement => {
    const api = useApi()
    const navigate = useNavigate()
    const {register, formState, handleSubmit} = useForm<loginForm>({mode: "onChange"})

    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showModalLostPassword, setShowModalLostPassword] = useState<boolean>(false)

    const handleLogin = (data: loginForm): void => {
        api.Login(data.email, data.password).then(resolve => {
            if (resolve){
                navigate(props.from ?? "/Profilo")
            } else {
                toast.error("Email o password errati")
            }
        })
    }

    return (
        <div id="signin" className="signup-in__content">
            <form id="signinForm"
                  onSubmit={handleSubmit(handleLogin)}
                  className="needs-validation"
                  noValidate
            >
                {/* EMAIL */}
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input id="email"
                           autoComplete="on"
                           type="email"
                           className={`form-control col-lg-8 col-12 ${formState.errors.email ? 'is-invalid' : ''}`}
                           {...register("email", {
                               required: true,
                               pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                           })}
                           name="email"
                    />
                    <div className="invalid-feedback">
                        Errore, inserisci una mail valida
                    </div>
                </div>

                {/* PASSWORD */}
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-group">
                        <input id="password"
                               autoComplete="on"
                               type={showPassword ? "text" : "password"}
                               className={`form-control col-lg-8 col-12 ${formState.errors.password ? 'is-invalid' : ''}`}
                               {...register("password", {
                                   required: true,
                                   minLength: 1
                               })}
                               name="password"
                               aria-describedby="showPasswordButton"
                        />
                        <span id={"showPasswordButton"}
                              className={`${isSafari ? "showPasswordButton__safari": "showPasswordButton "} icon icon--eye icon--default-no-opacity
                                  ${showPassword ? "icon--eye--closed" : null}
                                  ${formState.errors.password ? "showPasswordButton__translate" : null }
                              `}
                              onClick={() => {
                                  setShowPassword(!showPassword)
                              }}
                            >
                        </span>
                        <div className="invalid-feedback">
                            Errore, inserisci una password
                        </div>
                    </div>

                </div>

                <div className="invalid-feedback is-invalid">
                    Errore, inserisci una password
                </div>

                {/* LOGIN BUTTON */}
                <button type="submit"
                        className="btn btn--gradient signup-in__btn--gradient">
                    Accedi Subito
                </button>

                {/* PASSWORD LOST */}
                <div className="signup-in__box-lost-password col-12 col-md-7 col-xl-6 text-center">
                    <a className="signup-in__lost-password text--clickable-links"
                        onClick={() => {setShowModalLostPassword(!showModalLostPassword)}}
                    >
                        Password dimenticata?
                    </a>
                </div>
            </form>

            {
                showModalLostPassword ?

                    <Modal
                        show={showModalLostPassword}
                        onHide={() => {setShowModalLostPassword(false)}}
                        contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                        dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                    >
                        <LostPasswordModal onClose={() => {setShowModalLostPassword(false)}}/>

                    </Modal>
                    :
                    null
            }
        </div>
    )
}

export default Login
