import {ReactElement, useEffect, useState} from "react";
import Media, {useMedia} from "react-media";
import EventIssuer from "@/models/EventIssuer";
import PLPCheckbox from "@/components/PLPCheckbox";
import Select, {StylesConfig} from "react-select";
import variables from "@/models/ColorsVariables";
import * as _ from "lodash";

interface PLPFiltersProps {
    teams: EventIssuer[];
    updateFilter: (f: string[]) => void;
}

interface SelectOptions {
    value: String;
    label: String;
}

const PLPFilters = (props: PLPFiltersProps): ReactElement => {
    const [filter, setFilter] = useState<string[]>([]);

    const filterTeam = (id: string, value: boolean): void => {
        if (value) {
            setFilter(_.uniq([...filter, id]));
        } else {
            setFilter(filter.filter(f => f !== id));
        }
    }

    useEffect(() => {
        props.updateFilter(filter)
    }, [filter])

    const teamsList = props.teams.map((t, i) => {
        return <PLPCheckbox name={t.name} checked={false} key={i} id={t.uuid} filter={filterTeam}/>
    })

    const selectStyles: StylesConfig = {
        control: (styles) => ({...styles, backgroundColor: variables.background, border: 0, boxShadow: "none", color: variables.mainText}),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
        valueContainer: (styles) => ({ ...styles, padding: 0, color: variables.mainText}),
        placeholder: (styles) => ({ ...styles, color: variables.mainText}),
        dropdownIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        clearIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                color: variables.mainText,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: variables.mainText })
    }

    const eventIssuerOption: SelectOptions[] = _.uniqBy(props.teams.map((team): SelectOptions => ({
        value: team.uuid,
        label: team.name
    })), 'value')

    const GLOBAL_MEDIA_QUERIES = {
        xs: "(max-width: 767px)",
        md: "(max-width: 991px)"
    };

    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    useEffect(() => {
        setFilter([]);
    }, [matches])

    return (
        <div className="col-12 col-lg-3 col-xl-2 plp__filters">
            <Media query={{maxWidth: 991}} key={null}>
                {
                    matches => matches ?
                        <Media query={{maxWidth: 767}} key={null}>
                            {
                                match => match ?
                                    <>
                                        <div className="row">
                                            <div className="col-12 plp__filters__col d-flex justify-content-between">
                                                <div className="plp__filters__col__group col-8">
                                                    <div className="plp__filters__col__group col-12">
                                                        <Select className={"w-100"}
                                                                options={eventIssuerOption}
                                                                placeholder={"Squadra"}
                                                                isClearable
                                                                styles={selectStyles}
                                                                onChange={(ev:any) => {ev != null ? filterTeam(ev.value, true) : setFilter([])}}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ml-auto plp__filters__col__header align-items-center">
                                                    <label
                                                        className="label d-flex plp__filters__col__header__label text--primary-color">{"Filtri"}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-12 plp__filters__col d-flex justify-content-between">
                                                <div className="plp__filters__col__group col-4">
                                                    <div className="plp__filters__col__group col-12">
                                                        <Select className={"w-100"}
                                                                options={eventIssuerOption}
                                                                placeholder={"Squadra"}
                                                                isClearable
                                                                styles={selectStyles}
                                                                onChange={(ev:any) => {ev != null ? filterTeam(ev.value, true) : setFilter([])}}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ml-auto plp__filters__col__header align-items-center">
                                                    <label
                                                        className="label d-flex plp__filters__col__header__label text--primary-color">{"Filtri"}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }
                        </Media> :
                        <>
                            <div className="row">
                                <div className="col-12 plp__filters__col">
                                    <div className="plp__filters__col__header">
                                        <label className="label plp__filters__col__header__label text--primary-color">{"Filtri"}</label>
                                    </div>
                                    <div className="plp__filters__body filter__body">
                                        <div className="filter__body__info">
                                            <label className="label filter__body__info__label text--primary-color">
                                                {"Squadra"}
                                            </label>
                                        </div>
                                        {teamsList}
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Media>
        </div>
    )
}

export default PLPFilters
