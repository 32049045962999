import { useApi } from "@/hoc/Api/context";
import { useState } from "react";
import Select from "react-select"
import { toast } from "react-toastify";

export interface SelectOption {
    value: string;
    label: string;
}

interface CreateValidationProps {
    eventsIssuerSelect: SelectOption[],
    onClose: (refreshList: boolean) => void;
}

const CreateValidationModal = ({ ...props }: CreateValidationProps) => {

    const [eventIssuer, setEventIssuer] = useState<SelectOption | undefined>(undefined)
    const api = useApi()

    const handleSubmitValidation = () => {
        api.PostValidation(eventIssuer!.value).then(() => {
            toast("Validazione creata con successo", { type: "success" })
            props.onClose(true)
        }).catch(err => {
            toast("Spiacenti, si è verificato un errore", { type: "error" })
        })
    }

    return <>
        <div className="modal-header">
            <h5 className="ml-auto mr-auto modal-title">{"Crea validazione"}</h5>
            <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                onClick={() => { }}
            >
                <i aria-hidden="true" className="icon icon--close icon--default-no-opacity " />
            </button>
        </div>
        <div className="modal-body mx-3 mx-sm-0 mb-3">
            <div className="container d-flex flex-column gap-3">
                <p className='m-0'>Seleziona l'evento dal quale creare la validazione</p>
                <Select
                    maxMenuHeight={250}
                    menuPosition="fixed"
                    defaultValue={props.eventsIssuerSelect[0]}
                    options={props.eventsIssuerSelect}
                    placeholder={"Seleziona evento"}
                    value={eventIssuer}
                    onChange={(ev: any) => {
                        setEventIssuer(ev)
                    }}
                />
                <button
                    type="submit"
                    className="btn btn--gradient"
                    onClick={handleSubmitValidation} disabled={eventIssuer === undefined} >
                    {"CREA VALIDAZIONE"}
                </button>
            </div>
        </div>
    </>
}

export default CreateValidationModal