import {ReactElement} from 'react'

interface LoaderProps {
    small?: boolean;
    text?: string;
}

const Loader = (props: LoaderProps):ReactElement => {
  return (
      <div className="loader__container">
          <div className={`loader__container ${props.small?'loader__sm':''}`}>
              { props.text
                  ?
                  <h3 className="loader__sentence text-center">{props.text}</h3>
                  :
                  null
              }
              <div className='loader'>
                  <div>
                      <div>
                          <div>
                              <div>
                                  <div>
                                      <div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Loader
