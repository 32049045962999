import React, {ReactElement, useState} from "react";
import Checkbox from "@/components/Checkbox";

import cookies from "@/static/json/cookies.json";

interface CookiesModalProps {
    onClose: () => void;
}

interface AccordionCardInterface {
    id: number;
    title: string;
    content: string;
    initialState: boolean;
    disabled: boolean;
    checked(checked: boolean): void;
}

const CookiesModal = (props: CookiesModalProps): ReactElement => {
    const [analyticsCookie, setAnalyticsCookie] = useState<boolean>(false)

    const cookieAccordionCards = (props: AccordionCardInterface) => {
        return (
            <div className="accordion-item info-accordion-item-no-separator">
                <h2 className="accordion-header text--primary-color" id={`heading${props.id}`}>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-10 p-0 d-flex justify-content-start align-items-center">
                            <Checkbox
                                id={String(props.id)}
                                title={props.title}
                                onToggle={(checked) => props.checked(checked)}
                                checked={props.initialState}
                                parentClass={"d-flex"}
                                disabled={props.disabled}
                                cookies
                            />
                        </div>
                        <div className="col-auto p-0 d-flex">
                            <button className="accordion-button info-accordion-button collapsed text--primary-color"
                                    style={{maxWidth: "20px"}}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${props.id}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse${props.id}`}
                            ></button>
                        </div>
                    </div>


                </h2>
                <div id={`collapse${props.id}`}
                     className="accordion-collapse collapse text--heading-color"
                     aria-labelledby={`heading${props.id}`}
                     data-bs-parent="#cookiesAccordion"
                >
                    <div className="accordion-body info-accordion-body" dangerouslySetInnerHTML={{ __html: props.content}}></div>
                </div>
            </div>
        )
    }

    const handleSubmit = () => {
        const cookies = {
            compulsory: true,
            analytics: analyticsCookie,
        }
        localStorage.setItem("cookies", JSON.stringify(cookies))
        props.onClose();
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="ml-auto mr-auto modal-title">
                    {"Accetta i Cookies"}
                </h5>

                <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mx-3 mx-sm-0">
                <div className="container" style={{maxWidth: 600}}>
                    <div className="accordion info-accordion-container pt-2" id="cookiesAccordion">
                        {
                            cookies.map((a_c, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            cookieAccordionCards(
                                                {
                                                    id: index,
                                                    title: a_c.title,
                                                    content: a_c.content,
                                                    initialState: a_c.initialState,
                                                    disabled: a_c.disabled,
                                                    checked(checked: boolean) {
                                                        if (index === 1) {
                                                            setAnalyticsCookie(checked)
                                                        }
                                                    }
                                                })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='text-center d-flex align-items-center justify-content-center py-4'>
                    <button className='btn btn--gradient mx-auto ' onClick={() => {handleSubmit()}}>
                        ACCETTA
                    </button>
                </div>
            </div>
        </>
    )
}

export default CookiesModal
