import TicketOrder from "@/models/Ticketorder";
import Payout from "@/models/Payout";


export enum BalanceLogType {
    SALE = "sale",
    PAYOUT = "payout"
}

export default interface BalanceLog {
    uuid: string;
    date: string;
    accountingDate?: string;
    type: BalanceLogType;
    amount: number;
    data: TicketOrder | Payout;
}
