import {ReactElement} from "react";
import AccordionCard from "@/components/Accordion/AccordionCard";

interface AccordionSection {
    title: string;
    content: string;
}

const Accordion = (props: {sections: AccordionSection[], accordionId: string}): ReactElement => {
    return (

        <div className="accordion info-accordion-container" id={props.accordionId}>
            {
                props.sections.map((section, index) => {
                    return (
                        <AccordionCard
                            key={index}
                            id={index}
                            accordionId={props.accordionId}
                            title={section.title}
                            content={section.content}
                        />
                    )
                })
            }
        </div>
    )
}

export default Accordion
