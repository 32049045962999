import React, {ReactElement, useEffect} from 'react'
import Info from '@/components/Info'
import Layout from "@/components/Layout";

import data from "@/static/json/privacyPolicy.json";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const PrivacyPolicy = ({...props}):ReactElement => {
    const seo = useSeo()
    useEffect(() => {
        window.scrollTo(0, 0)
        seo.setSeo(SeoPage.privacyPolicy)
    }, [])

    return (
    <Layout>
        <Info
            title={data.title}
            subtitle={data.subtitle}
            accordionId={data.accordionId}
            accordionSection={data.accordionSections}
        />
    </Layout>
  )
}

export default PrivacyPolicy
