import {ReactElement, useEffect} from "react";
import Payout, {PayoutStatus} from "@/models/Payout";
import Moment from "react-moment";
import UserTicket from "@/models/UserTicket";


interface PayoutModalProps {
    onClose: () => void;
    ticket: UserTicket | undefined
    payout: Payout | undefined
    accountingDate: string | undefined
}


const HistoryModal = (props: PayoutModalProps): ReactElement => {

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    return <>
        <div className="modal-header">
            <h6 className="mx-auto modal-title">Dettaglio Operazione</h6>
            <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                    onClick={() => {
                        props.onClose()
                    }}
            >
                <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
            </button>
        </div>
        {
            props.ticket ?
                <div className="ticket-cards__modal__custom-body modal-body container balance-details-modal row gy-2 gy-md-1">
                    <div className="col-12 d-flex py-3 justify-content-start align-items-center">
                        <i className="icon icon--payout-tickets ticket-icon"/>
                        <label className="balance-modal-header px-3 text--primary-color">Vendita Biglietto</label>
                    </div>
                    <div className="col-12 p-0 d-flex pt-4 justify-content-start align-items-center">
                        <label className="info-header px-3 text--primary-color">Partita</label>
                    </div>
                    <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                        <label className="info-content px-3 text--heading-color">
                            { props.ticket.mainIssuer } vs { props.ticket.secondaryIssuer }
                        </label>
                    </div>
                    <div className="col-12 p-0 d-flex pt-4 justify-content-start align-items-center">
                        <label className="info-header px-3 text--primary-color">Biglietto</label>
                    </div>
                    <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                        <label className="info-content px-3 text--heading-color">
                            {`${props.ticket.ticketDetails.section} - Settore ${props.ticket.ticketDetails.subSection} - Fila: ${props.ticket.ticketDetails.row} - Posto: ${props.ticket.ticketDetails.seat}`}
                        </label>
                    </div>
                    <div className="col-12 p-0 d-flex pt-4 justify-content-between align-items-center">
                        <div className="col-auto ">
                            <label className="info-header px-3 text--primary-color">
                                Importo
                            </label>
                            <br/>
                            <label className="info-content px-3">
                                {(props.ticket.price / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                            </label>
                        </div>
                        <div className="col-auto">
                            <label className="info-header px-3 text--primary-color">
                                Data Contabile
                            </label>
                            <br/>
                            <label className="info-content px-3">
                                <Moment format="DD/MM/YYYY" style={{letterSpacing: "0.05em"}} className="text--heading-color">
                                    { new Date(props.ticket.date) }
                                </Moment>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 p-0 d-flex pt-5 justify-content-center align-items-center">
                        <button className="btn btn--outlined py-1 px-5"
                                onClick={() => {props.onClose()}}
                        >
                            <span className="btn__text-gradient">CHIUDI</span>
                        </button>
                    </div>
                </div>
                :
                props.payout ?
                <div className="ticket-cards__modal__custom-body modal-body container balance-details-modal row gy-1 gy-md-2">
                    <div className="col-12 d-flex py-3 justify-content-start align-items-center">
                        <i className="icon icon--available-balance ticket-icon"/>
                        <label className="balance-modal-header px-3 text--primary-color">Payout</label>
                    </div>
                    <div className="col-12 p-0 d-flex pt-3 pt-md-4 justify-content-start align-items-center">
                        <label className="info-header px-3 text--primary-color">Data Contabile</label>
                    </div>
                    <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                        <label className="info-content px-3">
                            <Moment format="DD/MM/YYYY" style={{letterSpacing: "0.05em"}} className="text--heading-color">
                                { new Date(props.payout.date) }
                            </Moment>
                        </label>
                    </div>
                    {
                        props.payout.amount > 2000 ?
                            <div className="col-12 p-0 d-none d-sm-flex pt-4 justify-content-between align-items-center">
                                <div className="col-auto ">
                                    <label className="info-header px-3 text--primary-color">
                                        IBAN
                                    </label>
                                    <br/>
                                    <label className="info-content px-3 text--heading-color">
                                        { props.payout.IBAN }
                                    </label>
                                </div>
                                <div className="col-auto">
                                    <label className="info-header px-3 text--primary-color">
                                        Importo
                                    </label>
                                    <br/>
                                    <label className="info-content px-3 text--heading-color">
                                        {(props.payout.amount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                    </label>
                                </div>
                            </div>
                            :
                            <>
                                <div className="col-12 p-0 d-none d-sm-flex pt-4 justify-content-between align-items-center">
                                    <label className="info-header px-3 text--primary-color">
                                        IBAN
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-none d-sm-flex justify-content-between align-items-center">
                                    <label className="info-content px-3 text--heading-color">
                                        { props.payout.IBAN }
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-none d-sm-flex pt-4 justify-content-between align-items-center">
                                    <div className="col-auto ">
                                        <label className="info-header px-3 text--primary-color">
                                            Importo Lordo
                                        </label>
                                        <br/>
                                        <label className="info-content px-3">
                                            <span className="blue">
                                                {((props.payout.amount + 200) / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-auto ">
                                        <label className="info-header px-3 text--primary-color">
                                            Importo Netto
                                        </label>
                                        <br/>
                                        <label className="info-content px-3">
                                            <span className="green">
                                                {(props.payout.amount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-auto">
                                        <label className="info-header px-3 text--primary-color">
                                            Commissioni
                                        </label>
                                        <br/>
                                        <label className="info-content px-3">
                                            <span className="red">
                                                {(-2.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </>
                    }

                    <div className="col-12 p-0 d-flex d-sm-none pt-3 justify-content-start align-items-center">
                        <label className="info-header px-3 text--primary-color">
                            IBAN
                        </label>
                    </div>
                    <div className="col-12 p-0 d-flex d-sm-none justify-content-start align-items-center">
                        <label className="info-content px-3 text--heading-color">
                            { props.payout.IBAN }
                        </label>
                    </div>
                    {
                        props.payout.amount > 2000 ?
                            <>
                                <div className="col-12 p-0 d-flex d-sm-none pt-3 justify-content-start align-items-center">
                                    <label className="info-header px-3 text--primary-color">
                                        Importo
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-flex d-sm-none justify-content-start align-items-center">
                                    <label className="info-content px-3 text--heading-color">
                                        {(props.payout.amount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                    </label>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-12 p-0 d-flex d-sm-none pt-3 justify-content-between align-items-center">
                                    <label className="info-header px-3 text--primary-color">
                                        Importo Lordo
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-flex d-sm-none justify-content-between align-items-center">
                                    <label className="info-content px-3">
                                        <span className="blue">
                                            {((props.payout.amount + 200) / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                        </span>
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-flex d-sm-none pt-3 justify-content-between align-items-center">
                                    <label className="info-header px-3 text--primary-color">
                                        Importo Netto
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-flex d-sm-none justify-content-between align-items-center">
                                    <label className="info-content px-3">
                                        <span className="green">
                                            {(props.payout.amount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                        </span>
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-flex d-sm-none pt-3 justify-content-between align-items-center">
                                    <label className="info-header px-3 text--primary-color">
                                        Commissioni
                                    </label>
                                </div>
                                <div className="col-12 p-0 d-flex d-sm-none justify-content-between align-items-center">
                                    <label className="info-content px-3">
                                        <span className="red">
                                            {(-2.0).toLocaleString('it-IT', {minimumFractionDigits: 2}) + " €"}
                                        </span>
                                    </label>
                                </div>

                            </>
                    }
                    <div className="col-12 p-0 d-flex pt-3 pt-md-4 justify-content-start align-items-center">
                        <label className="info-header px-3 text--primary-color">Stato</label>
                    </div>
                    <div className="col-12 p-0 d-flex justify-content-start align-items-center">
                        <label className={`info-content px-3 ${props.payout.status === PayoutStatus.COMPLETED ? "completed" : (props.payout.status === PayoutStatus.PENDING || props.payout.status === PayoutStatus.IN_TRANSFER) ? "pending" : "failed"}`}>
                            {
                                props.payout.status === PayoutStatus.COMPLETED ?
                                    "COMPLETATO"
                                    :
                                (props.payout.status === PayoutStatus.PENDING || props.payout.status === PayoutStatus.IN_TRANSFER) ?
                                    "IN ATTESA DI VERIFICA"
                                    :
                                    "FALLITO"
                            }
                        </label>
                    </div>
                    <div className="col-12 p-0 d-flex pt-5 justify-content-center align-items-center">
                        <button className="btn btn--outlined py-1 px-5"
                                onClick={() => {props.onClose()}}
                        >
                            <span className="btn__text-gradient">CHIUDI</span>
                        </button>
                    </div>
                </div>
                :
                null
        }
    </>
}

export default HistoryModal
