import {ReactElement, useEffect, useState} from "react";
import {useApi} from "@/hoc/Api/context";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useStore} from "@/hoc/Store/context";

interface ChangePasswordModalProps {
    onClose: () => void;
}

const LogoutModal = (props: ChangePasswordModalProps): ReactElement => {

    const api = useApi()
    const navigate = useNavigate()

    const [allDevice, setAllDevice] = useState<boolean>(false)
    const store = useStore()

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    const handleLogout = (): void => {
        api.Logout(allDevice).then(() => {
            store.deletePreorder()
            navigate("/")
        }).catch(() => {
            toast("Spiacenti, si è verificato un errore", {type: "error"})
        })
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="ml-auto mr-auto modal-title">{"Logout"}</h5>
                <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mx-3 mx-sm-0">
                <div className="container">
                    <form id="change-password-modal-form" className="needs-validation"
                          onSubmit={(e) => e.preventDefault()}>
                        <div className="reset-password-modal__content__form row mt-3">
                            <div className="row py-2">
                                <div className={"col-auto"}>
                                    <div className={`form-group form-check mt-0`}>
                                        <input type="checkbox"
                                               className={`form-check-input`}
                                               id={"readTerms"}
                                               checked={allDevice}
                                               onChange={() => {
                                                   setAllDevice(!allDevice)
                                               }}
                                               name={"readTerms"}
                                        />
                                    </div>
                                </div>
                                <div className={"col p-0"}>
                                    <label className="form-check-label">
                                        Scollega il mio account da tutti i dispositivi compreso quello attualmente in
                                        uso
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="reset-password-modal__content__form row mt-1">
                            <div className="checkout-modal__content__buttons col-12 d-flex">
                                <button type="button" className="btn btn--gradient" onClick={() => {
                                    props.onClose()
                                }}>
                                    <span className="btn__text">ANNULLA</span>
                                </button>
                                <button id="signup-button"
                                        className="btn btn--outlined "
                                        onClick={() => handleLogout()}>
                                    <span className="btn__text-gradient">CONFERMA</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LogoutModal
