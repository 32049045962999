import {ReactElement, useEffect, useState} from "react";
import Media from "react-media";

import {animated as a, Transition} from "react-spring";

import Section from "@/models/Section";
import {Modal} from "react-bootstrap";
import EventLocationInfoModal from "@/components/EventLocationInfoModal";


interface SectionsFilterProps {
    title: string;
    sections: Section[];
    updateFilter: (val: string[]) => void;
    filterSections: string[];
    eventLocationName: string;
    eventLocationImage: string;
}

const SectionsFilter = (props: SectionsFilterProps): ReactElement => {
    const [activeSections, setActiveSections] = useState<string[]>(props.filterSections);
    const [sectionsShowed, setSectionShowed] = useState<number>(8);
    const [showEventLocationInfo, setShowEventLocationInfo] = useState<boolean>(false);


    useEffect(() => {
        props.updateFilter(activeSections)
    }, [activeSections])

    const handleChange = (toggled: string): void => {
        const idx = activeSections.indexOf(toggled);
        if (idx !== -1) {
            setActiveSections(activeSections.filter((section: string) => section !== toggled))
        } else {
            setActiveSections([...activeSections, toggled])
        }
    }

    return (
        <>
            <div className="col-12 col-lg-2 filter__body__info filter__body__info--sector me-lg-4">
                <label className="label filter__body__info__label text--primary-color">{props.title}</label>

                <i aria-label="auto" className="icon icon--info cursor-pointer"
                   onClick={() => setShowEventLocationInfo(!showEventLocationInfo)}
                />

                <Media
                    query="(max-width: 991px)"
                    render={() =>
                        <a
                            className={"filter__body__info__icon filter__body__info__icon--question"}
                        />
                    }
                />
            </div>
            <div className="col-12 col-lg-9 ms-lg-5">
                <>
                    <Transition
                        items={props.sections.slice(0, Math.min(sectionsShowed, props.sections.length))}
                        keys={props.sections.map((section, i) => section.uuid)}
                        from={{opacity: 0, display: 'inline-block'}}
                        enter={{opacity: 1, display: 'inline-block'}}
                        leave={{opacity: 0, display: 'inline-block'}}
                    >
                        {
                            (styles: any, item) => (
                                <a.div style={styles}>
                                    <div className={`filter__body__element filter__body__element--sector ${activeSections.indexOf(item.uuid) !== -1 ? "active" : "f"}`}
                                         onClick={() => handleChange(item.uuid)}
                                    >
                                        {item.name}
                                    </div>
                                </a.div>
                            )
                        }
                    </Transition>
                    {props.sections.length > sectionsShowed ?
                        <button type="button"
                                className="btn btn--outlined filter__body--sector__button--show-more"
                                onClick={() => {
                                    setSectionShowed(sectionsShowed + 8)
                                }}
                        >
                            <span className="btn__text-gradient filter__body--sector__button--show-more__text">Carica altri</span>
                        </button>
                        : null
                    }
                </>
            </div>
            {
                showEventLocationInfo ?
                    <Modal show={showEventLocationInfo}
                           onHide={() => {
                               setShowEventLocationInfo(false)
                           }}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <EventLocationInfoModal
                            eventLocationImage={props.eventLocationImage}
                            eventLocationName={props.eventLocationName}
                            onClose={() => {
                                setShowEventLocationInfo(false)
                            }}
                        />
                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default SectionsFilter
