import {ReactElement, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";

interface LostPasswordModalProps {
    onClose: () => void;
}

interface LostPasswordForm {
    email: string;
}

const LostPasswordModal = (props: LostPasswordModalProps): ReactElement => {

    const {register, formState, handleSubmit} = useForm<LostPasswordForm>({mode: "onChange"})

    const api = useApi()

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    const handleLostPassword = (data: LostPasswordForm) :void => {
        api.RecoveryPasswordRequest(data.email).then(()=>{
            toast("Ti abbiamo inviato una email :)", {type:"success"})
            props.onClose()
        }).catch(err => {
            toast("Spiacenti, si è verificato un errore", {type:"error"})
        })
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="ml-auto mr-auto modal-title">{"Password reset"}</h5>
                <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                        onClick={() => { props.onClose() }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mx-3 mx-sm-0">
                <div className="container">
                    <form id="lost-password-modal-form" className="needs-validation"
                          onSubmit={handleSubmit(handleLostPassword)}>
                        <div className="account-change-data-modal__content__form row">
                            <div className="col-12">
                                <div className="row checkout-modal__content__header">
                                    <div className="col-12 mt-4 mb-2 mt-md-0">
                                        <p className="checkout-modal__content__header__title p-0">
                                            Inserisci la tua email, ti invieremo un link per resettare la tua password.
                                        </p>
                                    </div>
                                </div>
                                <div className="account-change-data-modal__content__form__group form-group">
                                    <label htmlFor="OldPassword">{"Inserisci l'indirizzo email"}</label>
                                    <input type="text"
                                           id={"email"}
                                           {...register("email", {
                                               required: true,
                                               pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                           })}
                                           className={`account-change-data-modal__content__form__group__input-wrapper form-control ${formState.errors.email ? 'is-invalid' : ''}`}/>
                                    <div
                                        className="invalid-feedback">{"Inserisci un indirizzo mail valido"}</div>
                                </div>
                            </div>

                            <div className="checkout-modal__content__buttons col-12 d-flex">
                                <button type="button" className="btn btn--outlined" onClick={() => {
                                    props.onClose()
                                }}>
                                    <span className="btn__text-gradient">{"ANNULLA"}</span>
                                </button>
                                <button disabled={!formState.isValid} type="submit" className="btn btn--gradient">
                                    {"INVIA"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LostPasswordModal
