import React, {ReactElement, useEffect, useState} from "react";
import Media, {useMedia} from "react-media";
import RangeSelector from "@/components/RangeSelector";
import ReductionCode from "@/models/ReductionCode";
import Section from "@/models/Section";
import {Modal} from "react-bootstrap";
import FiltersModal from "@/components/FiltersModal";
import ReductionCodesFilter from "@/components/ReductionCodesFilters";
import SectionFiltersModal from "@/components/SectionFiltersModal";
import SectionsFilter from "@/components/SectionsFilter";
import PricesFilter from "@/components/PriceFilter";
import Select, {StylesConfig} from "react-select";
import {useSearchParams} from "react-router-dom";
import variables from "@/models/ColorsVariables";

export interface PDPFilterArgs {
    sections: string[];
    reductionCodes: string[];
    numSeats: number;
    price: number | undefined;
}

interface PDPFiltersProps {
    updateSections: (sections: string[]) => void;
    updateReductionCodes: (reductionCodes: string[]) => void;
    updateNumSeats: (numSeats: number) => void;
    updatePrice: (price: number | undefined) => void;
    reductionCodes: ReductionCode[];
    sections: Section[];
    nSeats: number;
    eventLocationImage: string;
    eventLocationName: string;
    bundleSeatsNumber: number[];
}

interface selectOption {
    value: number;
    label: string;
}

const PDPFilters = (props: PDPFiltersProps): ReactElement => {

    const [filter, setFilter] = useState<PDPFilterArgs>({
        sections: [],
        reductionCodes: [],
        numSeats: 1,
        price: undefined
    })

    const [sectionFilterOpen, setSectionFilterOpen] = useState<boolean>(false);
    const [otherFilterOpen, setOtherFilterOpen] = useState<boolean>(false);
    const [quantitiesOptions, setQuantitiesOptions] = useState<selectOption[]>([]);

    const selectStyles: StylesConfig = {
        control: (styles) => (
            {...styles, backgroundColor: variables.background, border: "0", boxShadow: "none", color: variables.mainText, fontSize: 14, cursor: 'pointer',
            }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
        dropdownIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        valueContainer: (styles) => ({ ...styles, padding:0, fontSize: 14, color: variables.mainText}),
        menu: (styles) => ({ ...styles, fontSize: 14, color: `${variables.mainText} !important`}),
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                color: variables.mainText,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: variables.mainText, fontSize: 14}),
    }

    const GLOBAL_MEDIA_QUERIES = {
        desktop: "(min-width: 768px)"
    };

    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    useEffect(() => {
        if (props.bundleSeatsNumber.includes(props.nSeats)) {
            setFilter({...filter, numSeats: props.nSeats})
        } else if (matches.desktop) {
            props.updateNumSeats(filter.numSeats)
        }
        let options: selectOption[] = [];
        options.push({value: 1, label: "Tutti"});
        for (let i = 0; i < props.bundleSeatsNumber.length; i++) {
            options.push({
                value: props.bundleSeatsNumber[i],
                label: props.bundleSeatsNumber[i].toString()
            })
        }
        setQuantitiesOptions(options);
    }, [])

    const filterList =
        (
            <>
                <div className={"col-12 filters__element filters__element--no-sector filter"}>
                    <div className="filter__head d-none d-lg-flex">
                        <label className="label filter__head__label filter__label text--primary-color">{"Filtri"}</label>
                    </div>
                    <div className="row filters__element__body">
                        <div className="col-12 filter__body">
                            <div className="filter__body__info">
                                <label className="label filter__body__info__label text--primary-color">N. biglietti</label>
                            </div>
                            <div className="filter__body__element filter__body__element--typology mb-3 col-8">
                                <Select
                                        options={quantitiesOptions}
                                        placeholder={"Tutti"}
                                        styles={selectStyles}
                                        isSearchable={false}
                                        value={quantitiesOptions.find((option) => option.value === filter.numSeats)}
                                        onChange={(ev:any) => {
                                            setFilter({...filter, numSeats: ev.value})
                                            props.updateNumSeats(ev.value)
                                        }}
                                />
                            </div>
                        </div>
                        <ReductionCodesFilter
                            title={"Tipologia"}
                            filterReductionCodes={filter.reductionCodes}
                            reductionCodes={props.reductionCodes}
                            updateFilter={(rCodes) => {
                                props.updateReductionCodes(rCodes)
                                setFilter({...filter, reductionCodes: rCodes})
                            }}
                        />
                        <PricesFilter
                            filterPrice={filter.price}
                            onChange={(p) => {
                                props.updatePrice(p)
                                setFilter({...filter, price: p})
                            }}
                        />
                    </div>
                </div>
            </>
        )

    return (
        <Media query={{maxWidth: 991}} key={undefined}>
            {
                matches => matches ?
                    (
                        <>
                            <div className="col-12 filters mb-5">
                                <div className="row d-flex align-items-center filters__header">
                                    <div className={"col-6 p-0"}>
                                        <div className="filters__header__element"
                                             onClick={() => {
                                                 props.updateSections([])
                                                 setSectionFilterOpen(!sectionFilterOpen)
                                             }}
                                        >
                                            <i className={"icon icon--default-no-opacity icon--sector filters__header__element__label__icon filters__header__element__label__icon--expanded"}/>
                                            <label className="label filters__header__element__label filters__header__element__label--sector">
                                                {"Settore"}
                                            </label>
                                        </div>
                                    </div>
                                    <div className={"col-6 p-0"}>
                                        <div className="filters__header__element ms-1"
                                             onClick={() => {
                                                 setOtherFilterOpen(!sectionFilterOpen)
                                             }}>
                                            <i className={"icon icon--default-no-opacity icon--filter filters__header__element__label__icon filters__header__element__label__icon--expanded"}/>
                                            <label
                                                className="label filters__header__element__label filters__header__element__label--filters">{"Filtri"}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row d-flex align-items-center filters__header"}>
                                    <div className={"col-12 p-0"}>
                                        <div className={"filters__header__element mt-1 py-3"}>
                                            <QuantitiesFilter initialValue={filter.numSeats} updateFilter={(val: number) => {
                                                setFilter({...filter, numSeats: Number(val)})
                                                props.updateNumSeats(Number(val))
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                otherFilterOpen ?
                                    <Modal show={otherFilterOpen}
                                           onHide={() => {
                                               setOtherFilterOpen(false)
                                           }}
                                           contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                                           dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                                    >
                                        <FiltersModal
                                            onClose={() => {setOtherFilterOpen(false)}}
                                            resetFilters={() => {
                                                setFilter({...filter, price: undefined, reductionCodes: []})
                                                setOtherFilterOpen(false)
                                                props.updateReductionCodes([])
                                                props.updatePrice(undefined)
                                            }}
                                        >
                                            <ReductionCodesFilter
                                                title={"Tipologia"}
                                                filterReductionCodes={filter.reductionCodes}
                                                reductionCodes={props.reductionCodes}
                                                updateFilter={(rCodes) => {
                                                    props.updateReductionCodes(rCodes)
                                                    setFilter({...filter, reductionCodes: rCodes})
                                                }}
                                            />
                                            <PricesFilter
                                                onChange={(p) => {
                                                    props.updatePrice(p)
                                                    setFilter({...filter, price: p})
                                                }}
                                                filterPrice={filter.price}
                                                radioClass="font-size-medium"
                                                title={"Fascia di prezzo"}
                                            />
                                        </FiltersModal>
                                    </Modal>
                                    :
                                    null
                            }
                            {
                                sectionFilterOpen ?
                                    <Modal show={sectionFilterOpen}
                                           onHide={() => {
                                               setSectionFilterOpen(false)
                                           }}
                                           contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                                           dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                                    >
                                        <SectionFiltersModal
                                            onClose={() => {
                                                setSectionFilterOpen(false)
                                            }}
                                            resetSectionFilter={() => {
                                                setSectionFilterOpen(false)
                                                setFilter({...filter, sections: []})
                                            }}
                                        >
                                            <SectionsFilter
                                                title={"Scegli un Settore"}
                                                sections={props.sections}
                                                filterSections={filter.sections}
                                                eventLocationImage={props.eventLocationImage}
                                                eventLocationName={props.eventLocationName}
                                                updateFilter={
                                                (val: string[]) => {
                                                    props.updateSections(val)
                                                    setFilter({...filter, sections: val})
                                                }}
                                            />
                                        </SectionFiltersModal>
                                    </Modal>
                                    :
                                    null
                            }
                        </>
                    ) : (
                    <>
                        {filterList}
                    </>
                )
            }
        </Media>
    )
}


interface QuantitiesFilterProps {
    updateFilter: (val: number) => void
    initialValue: number;
}

const QuantitiesFilter = (props: QuantitiesFilterProps): ReactElement => {
    const [searchParams] = useSearchParams()

    const [numSeats, setNumSeats] = useState<number>(Number(searchParams.get("tickets")) || props.initialValue)

    const handleChange = (val: number): void => {
        setNumSeats(val)
        props.updateFilter(val)
    }

    useEffect(() => {
        props.updateFilter(numSeats)
    }, [])

    return (
        <div className="col-12 filter__body">
            <div className="row">
                <div className={"col-12"}>
                    <div className={"row px-3 px-sm-5 d-flex justify-content-between pb-1"}>
                        <div className="col-auto d-inline-flex">
                            <i className={"icon icon--default-no-opacity icon--tickets filters__header__element__label__icon filters__header__element__label__icon--expanded d-md-none"}/>
                            <label className="label d-lg-none filter__body__info__label text--primary-color">
                                {"Numero minimo di biglietti"}
                            </label>
                        </div>
                        <div className={"col-auto"}>
                            <label className="d-flex label filter__body__info__label filter__body__info__label--quantity">
                                {numSeats === 1 ? "Tutti" : numSeats}
                            </label>
                        </div>
                    </div>
                    <div className={"row px-3 px-sm-5 d-flex justify-content-center pt-1"}>
                        <div className={"col-12"}>
                            <RangeSelector
                                id={"numSeats"}
                                name={"numSeats"}
                                min={1}
                                max={5}
                                step={1}
                                initialValue={numSeats}
                                disabled={false}
                                onChange={(val: number) => {
                                    handleChange(val)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PDPFilters
