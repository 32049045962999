import {ReactElement, useEffect, useState} from "react";
import {useStore} from "@/hoc/Store/context";
import Ticketorder from "@/models/Ticketorder";
import MembershipCard from "@/models/MembershipCard";
import {Modal} from "react-bootstrap";
import AddCardModal from "@/components/AddCardModal/AddCardModal";
import {useApi} from "@/hoc/Api/context";

interface ChangePasswordModalProps {
    onClose: () => void;
    onSelected: (uuid: string) => void;
    currentTicket: Ticketorder;
    issuer: Array<string>;
}

const MemberCardModal = (props: ChangePasswordModalProps): ReactElement => {

    const store = useStore()
    const api = useApi()
    const [filter, setFilter] = useState<string>("");

    const [margin, setMargin] = useState<number>(0)
    const [indexSlide, setIndexSlide] = useState<number>(0)
    const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false)
    const [accordionOpen, setAccordioOpen] = useState<boolean>(false)

    useEffect(() => {
        setMargin(indexSlide * -215)
    }, [indexSlide])

    const onSelect = (m: MembershipCard) => {
        props.onSelected(m.uuid)
        props.onClose()
    }

    const cardList = store.getAvailableMembershipCards(props.currentTicket.eventUuid, props.currentTicket.mainIssuerUuid).filter(m => {
        return m.valid &&
            (
                filter === "" ||
                m.fullName.toLowerCase().includes(filter.toLowerCase()) ||
                m.number.toLowerCase().includes(filter.toLowerCase())
            )
    }).map(m => {
        return (
            <div className="member-card member-card__mobile" onClick={() => {onSelect(m)}} data-dismiss="modal" aria-label="Close">
                <span className="member-card__colors"
                      style={{background: "linear-gradient(105.83deg, #" + m.color1 + " 0%, #" + m.color2 + " 100%)"}}/>
                <div className="member-card__personal-info">
                    <p className="member-card__personal-info__owner mb-0">{m.fullName}</p>
                    <p className="label-3 mb-0 member-card__personal-info__team">{m.eventIssuer}</p>
                </div>
                <div className="member-card__general-info">
                    <p className="member-card__general-info__number label-3 mb-0">{m.number}</p>
                    <p className="member-card__general-info__associate label-3 mb-0">{"Associa"}</p>
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="modal-header py-3" style={{zIndex: "3"}}>
                <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                        onClick={() => {setAccordioOpen(!accordionOpen)}}
                >
                    <i aria-hidden="true" className="icon icon--search icon--default-no-opacity "/>
                </button>
                <div className={"ml-auto mr-auto"}>
                    <h5 className="modal-title">{"Scegli la tessera"}</h5>
                    <p className="m-0 label-3 font-weight-semibold text-center px-1">{props.issuer[0]} <span>vs</span> {props.issuer[1]}</p>
                </div>
                <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--red "/>
                </button>
            </div>
            {/* SEARCH */}
            <div style={{marginTop: "75px"}}></div>
            <div className={(accordionOpen) ? "col-12 cart-ticket__accordion show" : "col-12 cart-ticket__accordion"}>
                <div className="member-cards-carousel d-flex align-self-center justify-content-center">
                    <div className="input-group align-self-center" style={{marginBottom: "20px", width: "90vw"}}>
                        <input id="cardFilter" value={filter}
                               autoComplete="on"
                               type={"text"}
                               placeholder={"Cerca la tua tessera"}
                               onChange={(ev) => {
                                   setFilter(ev.target.value)
                               }}
                               style={{border: "none", borderBottom: "1px solid #5E7891"}}
                               className={`form-control col-lg-8 col-12`}
                        />
                        {/* BUTTON CLEAR SEARCH */}
                        <span className="emptySearchButton icon icon--no-margin icon--close-search"
                              onClick={() => {
                                  setFilter("")
                              }}
                        ></span>
                    </div>
                </div>
            </div>

            <div className="checkout-modal__content__buttons col-12 d-flex align-self-center"
                 style={{width: "200px"}}>
                <button id="signin-button" className="btn btn--outlined p-0" onClick={() => {setShowAddCardModal(true)}}>
                    <span className="btn__text-gradient p-0 w-100">AGGIUNGI TESSERA</span>
                </button>
            </div>
            <div >
                {cardList}
            </div>
            {
                showAddCardModal ?
                    <Modal show={showAddCardModal}
                           onHide={() => {
                               setShowAddCardModal(false)
                           }}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <AddCardModal onClose={() => {
                            setShowAddCardModal(false)
                        }} onComplete={() => {
                            setShowAddCardModal(false)
                            api.GetMembershipCards().then(r => {
                                store.setMembershipCard(r)
                            })
                        }}/>
                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default MemberCardModal
