import React, {ReactElement, ReactNode, useState} from "react";
import Preorder from "@/models/Preorder";
import StoreContext from "@/hoc/Store/context";
import TicketOrder from "@/models/Ticketorder";
import MembershipCard from "@/models/MembershipCard";
import UserTickets from "@/models/UserTickets";

interface StoreProviderProps {
    children: ReactNode;
}

const StoreProvider = (props: StoreProviderProps): ReactElement => {

    const [preorder, setPreorder] = useState<Preorder | undefined>(undefined);
    const [membershipCards, setMembershipCards] = useState<MembershipCard[] | undefined>([]);
    const [userTickets, setUserTickets] = useState<UserTickets | undefined>(undefined);

    const getAvailableMembershipCards = (eventUuid: string, mainIssuerUuid: string): MembershipCard[] => {
        return membershipCards?.filter(m => {
            const tOrds = preorder?.ticketOrders
                .filter(t => (t.targetMembershipCardUuid !== undefined
                    && t.targetMembershipCardUuid !== null
                    && t.eventUuid === eventUuid
                ))
                .map(t => t.targetMembershipCardUuid)
            return !(tOrds || []).includes(m.uuid) && mainIssuerUuid === m.eventIssuerUuid
        }) || []
    }

    const setTicketOrder = (to: TicketOrder): void => {
        if (!preorder) {
            return
        }
        for (let i = 0; i < preorder.ticketOrders.length; i++) {
            if (preorder.ticketOrders[i].uuid === to.uuid) {
                let newPreorder = {...preorder}
                newPreorder.ticketOrders[i] = to
                setPreorder(newPreorder)
                return
            }
        }

    }

    const deletePreorder = (): void => {
        setPreorder(undefined)
    };

    const setMembershipCard = (to: MembershipCard[]): void => {
        setMembershipCards(to)
    }

    return <StoreContext.Provider value={{
        preorder: () => preorder,
        ticketsOrder: () => preorder?.ticketOrders || [],
        membershipCards: () => membershipCards || [],
        userTickets: () => userTickets,
        setTicketOrder: setTicketOrder,
        setUserTickets: setUserTickets,
        setMembershipCard: setMembershipCard,
        setPreorder: setPreorder,
        getAvailableMembershipCards: getAvailableMembershipCards,
        deletePreorder: deletePreorder
    }}>
        <>
            {props.children}
        </>
    </StoreContext.Provider>
}

export default StoreProvider
