import {ReactElement, useEffect} from "react";
import Layout from "@/components/Layout";
import {useNavigate} from "react-router-dom";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const NotFound404 = (): ReactElement => {
    const seo = useSeo()
    useEffect(() => {
        seo.setSeo(SeoPage.notFound)
    })
    const navigate = useNavigate()
    return (
        <Layout>
            <div className="not-found">
                <div className="hero_not_found">
                    <div className="row justify-content-start d-flex gy-2">
                        <div className="col-1 col-xl-2"></div>
                        <div className="col-8 col-sm-5">
                            <div className="row text-container">
                                <div className="col-12 d-flex justify-content-center text-center">
                                    <h1 className="title text--primary-color text-start font-weight-bold">
                                        C'è un po' troppo silenzio qui!
                                    </h1>
                                </div>
                                <div className="col-12 d-flex justify-content-center text-center">
                                    <h5 className="content text--heading-color text-start">
                                        Sfortunatamente non siamo riusciti a trovare la pagina che cercavi!
                                    </h5>
                                </div>
                                <div className="col-12 d-flex justify-content-start text-center py-5">
                                    <button className="btn btn--gradient"
                                            onClick={() => {
                                                navigate("/")
                                            }}
                                    >
                                        Torna Indietro
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFound404
