export enum UserRole {
    USER = 0,
    ADMIN = 1,
    DEVELOPER = 2
}

export interface AuthUser {
    email?: string,
    role?: UserRole
}

export interface LoggedUser {
    isLogged: boolean,
    authUser?: AuthUser
}