import React, {ReactElement} from 'react';

interface PLPCheckboxProps {
    id: string
    name: string;
    checked: boolean;
    filter: (id: string, value: boolean) => void
}

const PLPCheckbox = (props: PLPCheckboxProps): ReactElement => {

    const handleChange = (event: any): void => {
        if (event.target.checked) {
            props.filter(props.id, true)
        } else {
            props.filter(props.id, false)
        }
    }

    return (
        <div className="filter__body__element filter__body__element--typology">
            <div className={"col-auto"}>
                <div className="form-group form-check ps-3">
                    <input type="checkbox"
                           className="form-check-input"
                           id={props.name}
                           name={props.name}
                           value={props.name}
                           defaultChecked={props.checked}
                           onChange={(event) => {
                               handleChange(event)
                           }}/>
                    <div className={"col px-2"}>
                        <label className="ps-1 form-check-label" htmlFor={props.name}>{props.name}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PLPCheckbox;
