import {ReactElement, useEffect, useState} from "react";
import Ticketorder from "@/models/Ticketorder";
import MembershipCard from "@/models/MembershipCard";
import {useApi} from "@/hoc/Api/context";
import { format } from 'date-fns'
import { it } from 'date-fns/locale';
import Media from "react-media";
import MemberCardsCarousel from "@/components/MemberCardsCarousel";
import {useStore} from "@/hoc/Store/context";
import {Modal} from "react-bootstrap";
import MemberCardModal from "@/components/MemberCardModal";
import {PreorderState} from "@/models/Preorder";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

interface CartTicketProps {
    ticketOrder: Ticketorder;
}

const CartTicket = (props: CartTicketProps): ReactElement => {

    const api = useApi()
    const store = useStore()
    const navigate = useNavigate()

    const [membershipCard, setMembershipCard] = useState<MembershipCard | undefined>(undefined);
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
    const [showModalMemberCard, setShowModalMemberCard] = useState<boolean>(false);

    useEffect(() => {
        if (props.ticketOrder.targetMembershipCardUuid !== undefined) {
            api.GetMembershipCard(props.ticketOrder.targetMembershipCardUuid).then(m => {
                setMembershipCard(m)
            })
        }
    }, [])

    const openAccordion = (): void => {
        setAccordionOpen(!accordionOpen)
    }

    const removeMemberCard = (): void => {
        api.DisassociateMembershipCard(props.ticketOrder.uuid).then((t) => {
            store.setTicketOrder(t)
            setMembershipCard(undefined)
        })
    }

    const getMembershipCard = (uuid: string): void => {
        api.GetMembershipCard(uuid).then((m) => {
            setMembershipCard(m)
        })

    }

    const removeTicketOrder = () => {
        api.RemoveTicketToPreorder(props.ticketOrder.uuid).then((t) => {
            api.GetPreorder().then(p => {
                if (p.state === PreorderState.Draft || p.state === PreorderState.Pending) {
                    store.setPreorder(p)
                } else {
                    navigate("/Checkout")
                }
            })
        }).catch(() => {
            toast.error("Spiacenti, si è verificato un errore")
        })
    }

    const formatDate = (date: string) => {
        const piecesArr = format(new Date(date), 'eeee d MMMM', {locale: it}).split(" ");
        for (let i = 0; i < piecesArr.length; i++) {
            piecesArr[i] = piecesArr[i].charAt(0).toUpperCase() + piecesArr[i].slice(1);
        }
        return piecesArr.join(" ");
    }

    return (
        <>
            <Media query="(max-width: 500px)">
                {
                    matches => matches ?
                        (
                            <div className="card-ticket-new row align-items-center">

                                {/* PARTITA: squadre - orario - loco */}
                                <div className="col-12">
                                    <div className="cart-ticket__contents__info">
                                        <div className="cart-ticket__contents__info__labels">
                                            <p className="cart-ticket__contents__member-card__associate-label m-0 pb-1">
                                                {props.ticketOrder.ticketDetails.reductionCode}
                                            </p>
                                            <h4 className="font-weight-semibold cart-ticket__contents__info__match text--primary-color pb-1">
                                                {props.ticketOrder.mainIssuer} <span className="text--heading-color">vs</span> {props.ticketOrder.secondaryIssuer}
                                            </h4>
                                            <p>
                                                { formatDate(props.ticketOrder.date) }, Stadio {props.ticketOrder.location}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* DETTAGLI: settore - fila - posto */}
                                <div className="col-12 mb-0">
                                    <div className="card-ticket-new__no-line-height col p-0">
                                        <div className={"row p-0"}>
                                            <p className="card-ticket-new__details">
                                                {`Settore: ${props.ticketOrder.ticketDetails.section} n° ${props.ticketOrder.ticketDetails.subSection}, Fila: ${props.ticketOrder.ticketDetails.row}, Posto: ${props.ticketOrder.ticketDetails.seat}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* TESSERA */}
                                <div className="col-12" onClick={()=>{
                                    if(!membershipCard){
                                        setShowModalMemberCard(true)
                                    }
                                }}>
                                    <div className={"row justify-content-between"}>
                                        <div className={"col-auto pt-1 pb-2"}>
                                            {
                                                (!membershipCard) ?
                                                    <p className="cart-ticket__contents__member-card__associate-label">{"Associa tessera"}</p>
                                                    :
                                                    <div className="cart-ticket__contents__member-card__info ">
                                                        <div className={"col p-0"}>
                                                            <div className={"row p-0"}>
                                                            <span className="cart-ticket__contents__member-card__info__owner cart-ticket__contents__member-card__info__owner__mobile text--primary-color">
                                                                {membershipCard.fullName}
                                                            </span>
                                                            </div>
                                                            <div className={"row p-0"}>
                                                            <span className="cart-ticket__contents__member-card__info__team cart-ticket__contents__member-card__info__team__mobile">
                                                                {`${membershipCard.reductionCode} #${membershipCard.number}`}
                                                            </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                            }
                                        </div>
                                        <div
                                            className="col-auto card-ticket-new__btn-expand card-ticket-new__btn-expand__mobile p-0">
                                            {
                                                (!membershipCard) ?
                                                    // icon--small-arrow--up
                                                    <i
                                                       className={`icon icon--small-arrow ${accordionOpen ? "icon--small-arrow--up" : ""} icon--default card-ticket-new__btn-expand__mobile__icon`} />
                                                    :
                                                    <i onClick={() => { removeMemberCard() }}
                                                       className="icon icon--close card-ticket-new__btn-expand__mobile__icon icon--red" />
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* PREZZO: costo - delete */}
                                <div className="col-12 align-self-center">
                                    <div className="row justify-content-between">
                                        <div className="col-auto pt-2 pb-3">
                                            <div className={"row"}>
                                                {/* FINAL PRICE */}
                                                <div className="col-auto pe-1">
                                                <span className={"card-ticket-new__text-gradient m-0"}>
                                                    € {(props.ticketOrder.price/100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}*
                                                </span>
                                                </div>

                                                {/* ORIGINAL PRICE */}
                                                {
                                                    props.ticketOrder.priceOfficial ?
                                                        (
                                                            <div className="col-auto justify-content-center ps-1">
                                                            <span className="card-ticket-new__text card-ticket-new__text__mobile">
                                                                € {(props.ticketOrder.priceOfficial/100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}**
                                                            </span>
                                                            </div>
                                                        ) :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-auto p-0 card-ticket-new__price card-ticket-new__price__mobile pt-2 pb-3">
                                            <i onClick={() => { removeTicketOrder() }} className="cart-ticket__contents__edit__trash-can icon icon--trash-can icon--red" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )
                        :
                        (
                            <div className="card-ticket-new row align-items-center">

                                {/* PARTITA: squadre - orario - loco */}
                                <div className="col-5">
                                    <div className="cart-ticket__contents__info">
                                        <div className="cart-ticket__contents__info__labels">
                                            <p className="cart-ticket__contents__member-card__associate-label m-0">
                                                {props.ticketOrder.ticketDetails.reductionCode}
                                            </p>
                                            <h4 className="font-weight-semibold cart-ticket__contents__info__match text--primary-color">
                                                {props.ticketOrder.mainIssuer} <span className="text--heading-color">vs</span> {props.ticketOrder.secondaryIssuer}
                                            </h4>
                                            <p>
                                                { formatDate(props.ticketOrder.date) }, {props.ticketOrder.location}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* DETTAGLI: settore - fila - posto */}
                                <div className="col-5 mb-0">
                                    <div className={"row justify-content-between"}>
                                        <div className="card-ticket-new__no-line-height col p-0">
                                            <div className={"row p-0"}>
                                        <span className="card-ticket-new__details">
                                            {`Settore: ${props.ticketOrder.ticketDetails.section} n° ${props.ticketOrder.ticketDetails.subSection}\n\n`}
                                        </span>
                                            </div>
                                            <div className={"row p-0"}>
                                        <span className="card-ticket-new__details">
                                            {`Fila: ${props.ticketOrder.ticketDetails.row}, Posto: ${props.ticketOrder.ticketDetails.seat}`}
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* PREZZO: costo - delete */}
                                <div className="col-2 align-self-center">
                                    <div className="row justify-content-between">
                                        <div className="col-auto p-0">
                                            <div className="row p-0">
                                            <span className={"card-ticket-new__text-gradient m-0"}>
                                                € {(props.ticketOrder.price/100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}*
                                            </span>
                                            </div>
                                            {
                                                props.ticketOrder.priceOfficial ?
                                                    (
                                                        <div className="row p-0 justify-content-center">
                                        <span className="card-ticket-new__text">
                                            € {(props.ticketOrder.priceOfficial/100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}**
                                        </span>
                                                        </div>
                                                    ) :
                                                    null
                                            }

                                        </div>
                                        <div className="col-auto p-0 card-ticket-new__price">
                                            <i onClick={() => { removeTicketOrder() }} className="cart-ticket__contents__edit__trash-can icon icon--trash-can icon--red" />
                                        </div>
                                    </div>

                                </div>

                                {/* TESSERA */}
                                <div className="col-12">
                                    <div className={(!membershipCard) ? "cart-ticket__contents__member-card" : "cart-ticket__contents__member-card align-items-center"}
                                         onClick={() => { if (membershipCard) { removeMemberCard() }else{openAccordion()}}}
                                    >
                                        {
                                            (!membershipCard) ?
                                                <p className="cart-ticket__contents__member-card__associate-label" onClick={() => setAccordionOpen(!accordionOpen)}>{"Associa tessera"}</p>
                                                :
                                                <div className="cart-ticket__contents__member-card__info">
                                                    <span className="cart-ticket__contents__member-card__info__owner text--primary-color">
                                                        {membershipCard.fullName}
                                                    </span>
                                                    <span className="cart-ticket__contents__member-card__info__team">
                                                        {` - ${membershipCard.reductionCode} #${membershipCard.number}`}
                                                    </span>
                                                </div>
                                        }
                                        <div className={"col-auto align-items-center d-flex pe-4"}>
                                            {
                                                (!membershipCard) ?
                                                    // icon--small-arrow--up
                                                    <i onClick={() => { openAccordion() }}
                                                       className={`icon icon--small-arrow ${accordionOpen ? "icon--small-arrow--up" : ""} icon--default card-ticket-new__btn-expand__mobile__icon`} />
                                                    :
                                                    <i onClick={() => { removeMemberCard() }}
                                                       className="icon icon--close card-ticket-new__btn-expand__mobile__icon icon--red" />
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    <div className={(accordionOpen) ? "col-12 cart-ticket__accordion show" : "col-12 cart-ticket__accordion"}
                                         id={"member-cards_" + props.ticketOrder.uuid}>
                                        <MemberCardsCarousel
                                            currentTicket={props.ticketOrder}
                                            onSelected={(uuid) => {
                                                api.AssociateMembershipCard(props.ticketOrder.uuid, uuid).then((t) => {
                                                    store.setTicketOrder(t)
                                                    getMembershipCard(uuid)
                                                    setAccordionOpen(false)
                                                }).catch(() => {
                                                    toast.error("Spiacenti, si è verificato un errore")
                                                })
                                            }}/>
                                    </div>

                                }
                            </div>
                        )
                }
            </Media>
            {
                showModalMemberCard ?

                    <Modal
                        show={showModalMemberCard}
                        onHide={() => {setShowModalMemberCard(false)}}
                        contentClassName={"account-change-data-modal__content checkout-modal__content ticket-cards__modal__content"}
                        dialogClassName={"checkout-modal ticket-cards__modal overflow-md-hidden"}
                    >
                        <MemberCardModal
                            currentTicket={props.ticketOrder}
                            issuer={[props.ticketOrder.mainIssuer, props.ticketOrder.secondaryIssuer]}
                            onSelected={(uuid) => {
                                api.AssociateMembershipCard(props.ticketOrder.uuid, uuid).then((t) => {
                                    store.setTicketOrder(t)
                                    getMembershipCard(uuid)
                                    setAccordionOpen(false)
                                }).catch(() => {
                                    toast.error("Spiacenti, si è verificato un errore")
                                })
                            }}
                            onClose={() => {setShowModalMemberCard(false)}}
                        />

                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default CartTicket
