import {ReactElement, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";
import {isSafari} from "react-device-detect";


interface ChangePasswordModalProps {
    onClose: () => void;
    token?: string;
}

interface ChangePasswordForm {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const ChangePasswordModal = (props: ChangePasswordModalProps): ReactElement => {
    const isRecovery = props.token !== undefined
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false)
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
    const [requirementsPassword, setRequirementsPassword] = useState<boolean>(false)
    const newPassword = useRef<string>()
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false)
    const {register, formState, handleSubmit, watch, setValue, getValues, trigger} = useForm<ChangePasswordForm>({mode: "onChange"})
    const api = useApi()
    newPassword.current = watch("newPassword", "")

    useEffect(() => {
        document.body.style.position = 'fixed';

        register("newPassword", {
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([a-zA-Z\d$&+,:;=?_@#|'<>.^*()%!-]+){8,}$/
        })
        return () => {
            document.body.style.position = 'unset';
        }
    },[])

    const onSubmit = (d: ChangePasswordForm) => {
        if (isRecovery) {
            api.RecoveryPassword(props.token ?? "", d.newPassword).then(() => {
                toast("Password modificata con successo", {type: "success"})
                props.onClose()
            }).catch(e => {
                toast("Spiacenti, si è verificato un errore", {type: "error"})
            })
        } else {
            api.ChangePassword(d.oldPassword, d.newPassword).then(() => {
                toast("Password modificata con successo", {type: "success"})
                props.onClose()
            }).catch(e => {
                toast("Spiacenti, si è verificato un errore", {type: "error"})
            })
        }
    }
    return (
        <>
            <div className="modal-header">
                <h5 className="mx-auto modal-title">{"Cambia password"}</h5>
                <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mx-3 mx-sm-0">
                <div className="container">
                    <form id="change-password-modal-form" className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className="reset-password-modal__content__form row">
                            {
                                isRecovery ?
                                    null :
                                    (
                                        <div className="col-12">
                                            <label htmlFor="OldPassword">{"VECCHIA PASSWORD"}</label>
                                            <div className="reset-password-modal__content__form__group form-group">
                                                <input type={showOldPassword ? "text" : "password"}
                                                       id={"OldPassword"}
                                                       {...register("oldPassword", {
                                                           required: true,
                                                           pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([a-zA-Z\d$&+,:;=?_@#|'<>.^*()%!-]+){8,}$/
                                                       })}
                                                       className={`reset-password-modal__content__form__group__input-wrapper form-control ${formState.errors.oldPassword ? 'is-invalid' : ''}`}
                                                />
                                                <span className={`showPasswordButton icon icon--eye icon--default-no-opacity
                                                ${isSafari ? "showPasswordButton__safari": "showPasswordButton "}
                                                ${showOldPassword ? "icon--eye--closed" : null}
                                                ${formState.errors.oldPassword ? "showPasswordButton__translate" : null}`}
                                                      onClick={() => {
                                                          setShowOldPassword(!showOldPassword)
                                                      }}
                                                ></span>
                                                <div className="invalid-feedback">{"Errore, password non valida"}</div>
                                            </div>
                                        </div>
                                    )
                            }
                            <div className="col-12">
                                <label htmlFor="NewPassword">{"NUOVA PASSWORD"}</label>
                                <div className="reset-password-modal__content__form__group form-group">
                                    <input type={showNewPassword ? "text" : "password"}
                                           onChange={(event) => {
                                               setValue("newPassword", event.target.value, {shouldValidate: true})
                                               trigger("confirmNewPassword")
                                           }}
                                           id={"NewPassword"}
                                           className={`reset-password-modal__content__form__group__input-wrapper form-control ${formState.errors.newPassword ? 'is-invalid' : ''}`}
                                    />
                                    <span className={`showPasswordButton icon icon--eye icon--default-no-opacity
                                            ${isSafari ? "showPasswordButton__safari": "showPasswordButton "}
                                            ${showNewPassword ? "icon--eye--closed" : null}
                                            ${formState.errors.newPassword ? "showPasswordButton__translate" : null}`}
                                              onClick={() => {
                                                  setShowNewPassword(!showNewPassword)
                                              }}
                                    ></span>
                                    <div className="invalid-feedback">
                                        Errore, la password non rispetta i requisiti
                                        <br/>
                                        <span className="text-decoration-underline"
                                              onClick={() => {
                                                  setRequirementsPassword(!requirementsPassword)
                                              }}
                                        >
                                        Requisiti:
                                        </span>
                                        {
                                            requirementsPassword?
                                                (
                                                    <ul>
                                                        <li>Almeno una Lettera Maiuscola</li>
                                                        <li>Almeno una Lettera minuscola</li>
                                                        <li>Almeno un Numero</li>
                                                        <li>Lunghezza minima 8 caratteri</li>
                                                        <li>Non deve contenere spazi</li>
                                                        <li>Caratteri speciali ammessi:</li>
                                                        <ul>
                                                            <li>{" & + , : ; = ? _ @ # | ' < > . ^ * ( ) % !"}</li>
                                                        </ul>
                                                    </ul>
                                                )
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <label htmlFor="PasswordCheck">{"CONFERMA PASSWORD"}</label>
                                <div className="reset-password-modal__content__form__group form-group">
                                    <input type={showConfirmNewPassword ? "text" : "password"}
                                           {...register("confirmNewPassword", {
                                               required: true,
                                               validate: val => {
                                                   return val === getValues("newPassword")
                                               }
                                           })}
                                           className={`reset-password-modal__content__form__group__input-wrapper form-control ${formState.errors.confirmNewPassword ? 'is-invalid' : ''}`}
                                           id="PasswordCheck"/>
                                    <span className={`showPasswordButton icon icon--eye icon--default-no-opacity
                                            ${isSafari ? "showPasswordButton__safari": "showPasswordButton "}
                                            ${showConfirmNewPassword ? "icon--eye--closed" : null}
                                            ${formState.errors.confirmNewPassword ? "showPasswordButton__translate" : null}`}
                                          onClick={() => {
                                              setShowConfirmNewPassword(!showConfirmNewPassword)
                                          }}
                                    ></span>
                                    <div
                                        className="invalid-feedback">{"Le due password non coincidono"}</div>
                                </div>
                            </div>


                            <div className="checkout-modal__content__buttons col-12 d-flex">
                                <button type="button" className="btn btn--outlined" onClick={() => {
                                    props.onClose()
                                }}>
                                    <span
                                        className="btn__text-gradient">{"ANNULLA"}</span>
                                </button>
                                <button disabled={!formState.isValid} type="submit" className="btn btn--gradient">
                                    {"SALVA"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ChangePasswordModal
