import {ReactElement} from "react";

interface RadioProps {
    parentClass?: string;
    id: string;
    value: string;
    checked: boolean;
    name: string;
    title: string;
    disabled?: boolean;
    changeStatus: (val: string) => void;
}

const Radio = (props: RadioProps): ReactElement => {
    return (
        <div className={"row py-1"}>
            <div className={"col-auto pe-1"}>
                <input type="radio"
                       className="form-check-input form-check-input-radio"
                       id={props.id}
                       name={props.name}
                       value={props.value}
                       checked={props.checked}
                       disabled={props.disabled}
                       onChange={() => props.changeStatus(props.value)}
                />
            </div>
            <div className={`col-auto ps-2`}>
                <label className={`form-check-label ${props.parentClass ? props.parentClass : ""}`} htmlFor={props.id}>
                    {props.title}
                </label>
            </div>
        </div>
    )
}

export default Radio
