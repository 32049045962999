import {ReactElement, useEffect, useState} from 'react'
import {Elements,} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import {useApi} from "@/hoc/Api/context";
import {PreorderState} from "@/models/Preorder";
import Loader from "@/components/Loader";
import Layout from "@/components/Layout";
import CheckoutForm from "@/components/CheckoutForm";
import {useStore} from "@/hoc/Store/context";
import TicketDetailsCheckout from "@/components/TicketDetailsCheckout";
import {useNavigate} from "react-router-dom";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

const Checkout = ({...props}): ReactElement => {
    const api = useApi()
    const navigate = useNavigate()
    const store = useStore()
    const seo = useSeo()
    const [stripePromise, setStripePromise] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY || "", {locale: "it"}));
        seo.setSeo(SeoPage.checkout);
        api.GetPreorder().then(p => {
            // Block this condition if order state is pending but payment request fail
            if(p.state == PreorderState.Pending &&
                (p.clientData?.statusPayment === "succeeded" || p.clientData?.statusPayment === "processing")){
                navigate(`/Checkout/${preorder?.uuid}`)
                return
            }
            store.setPreorder(p)
            setLoading(false)
        })
    }, [])
    const preorder = store.preorder()
    if (preorder === undefined || stripePromise === undefined || loading) {
        return <Loader/>
    }
    return (
        <Layout>
            <Elements stripe={stripePromise} >
                <div>
                    <div className="checkout">
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-7 col-lg-7 checkout__header"}>
                                    <h3 className="label-3">Checkout</h3>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-center justify-content-md-between ">
                                <div className={"col-12 col-md-7 col-lg-6 pt-4"}>
                                    <div className="col-11 col-md-12 mx-auto">
                                        <CheckoutForm orderUuid={preorder.uuid}/>
                                    </div>
                                </div>
                                <div className="col-10 col-md-4 col-lg-4 mt-4 mt-md-auto checkout__details-footer p-4">
                                    {
                                        preorder.ticketOrders.map(t => (
                                            <TicketDetailsCheckout ticketOrder={t} key={t.ticketUuid}/>
                                        ))
                                    }
                                    <div className="row justify-content-between checkout__total-amount ps-3">
                                        <div className="col text--heading-color">
                                            Totale
                                        </div>
                                        <div className="col text-end text--heading-color">
                                            € {(preorder.totalAmount / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center pt-3">
                                        <div className={"col-auto"}>
                                            <label
                                                className="label-3 cursor-pointer text-decoration-underline text--clickable-links"
                                                onClick={() => navigate("/Carrello")}
                                            >
                                                MODIFICA IL CARRELLO
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Elements>
        </Layout>

    )
}

export default Checkout
