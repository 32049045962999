import {ReactElement} from "react";

interface AccordionSectionProps {
    id: number;
    accordionId: string;
    title: string;
    content: string;
}

const AccordionCard = (props: AccordionSectionProps):ReactElement => {
    return (
        <div className="accordion-item info-accordion-item">
                <h2 className="accordion-header text--primary-color" id={`heading${props.id}`}>
                    <button className="accordion-button info-accordion-button collapsed text--primary-color"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${props.id}`}
                            aria-expanded="true"
                            aria-controls={`collapse${props.id}`}
                    >
                        {props.title}
                    </button>
                </h2>
                <div id={`collapse${props.id}`}
                     className="accordion-collapse collapse text--heading-color"
                     aria-labelledby={`heading${props.id}`}
                     data-bs-parent={`#${props.accordionId}`}>
                    <div className="accordion-body info-accordion-body">
                        {props.content}
                    </div>
                </div>
            </div>
    )
}
export default AccordionCard
