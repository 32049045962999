export enum PayoutStatus {
    PENDING = "pending",
    IN_TRANSFER = "inTransfer",
    COMPLETED = "completed",
    FAILED = "failed",
    CANCELED = "canceled"
}


export default interface Payout {
    uuid: string;
    name: string;
    IBAN: string;
    date: string;
    amount: number;
    status: PayoutStatus;
}
