import {ReactElement, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {isSafari} from "react-device-detect";
import {useApi} from "@/hoc/Api/context";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


interface ChangePasswordModalProps {
    onClose: () => void;
}

interface ChangePasswordForm {
    password: string;
}

const DeleteAccountModal = (props: ChangePasswordModalProps): ReactElement => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const {register, formState, handleSubmit} = useForm<ChangePasswordForm>({mode: "onChange"})
    const api = useApi()
    const navigate = useNavigate()

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    const onSubmit = (d: ChangePasswordForm) => {
        api.DeleteUser(d.password)
            .then(r => {
                props.onClose()
                navigate("/")
            })
            .catch(e => {
                toast("Spiacenti, si è verificato un errore", {type: "error"})
            })
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="ml-auto mr-auto modal-title">{"Elimina accont"}</h5>
                <button type="button" className="btn close p-0 d-md-none" data-dismiss="modal" aria-label="Close"
                        onClick={() => { props.onClose() }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>
            <div className="modal-body mx-3 mx-sm-0">
                <div className="container">
                    <form id="change-password-modal-form" className="needs-validation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className="account-change-data-modal__content__form row">
                            <div className="col-12">
                                <div className="row checkout-modal__content__header">
                                    <div className="col-12 mt-4 mb-2 mt-md-0">
                                        <p className="checkout-modal__content__header__title p-0">
                                            Confermi di voler eliminare il tuo account?
                                            Questa è un’operazione irreversibile, per continuare con l’eliminazione del tuo account, inserisci la tua password per confermare la tua identità.
                                        </p>
                                    </div>
                                </div>
                                <label htmlFor="OldPassword">{"PASSWORD"}</label>
                                <div className="account-change-data-modal__content__form__group form-group input-group">
                                    <input type={showPassword ? "text" : "password"}
                                           id={"OldPassword"}
                                           {...register("password", {
                                               required: true,
                                               minLength: 1
                                           })}
                                           className={`account-change-data-modal__content__form__group__input-wrapper form-control ${formState.errors.password ? 'is-invalid' : ''}`}
                                    />
                                        <span className={`${isSafari ? "showPasswordButton__safari": "showPasswordButton "} icon icon--eye icon--default-no-opacity
                                            ${showPassword ? "icon--eye--closed" : null}
                                            ${formState.errors.password ? "showPasswordButton__translate" : null }`}
                                          onClick={() => {
                                              setShowPassword(!showPassword)
                                          }}
                                        ></span>
                                    <div
                                        className="invalid-feedback">{"Errore, password non valida"}</div>
                                </div>
                            </div>
                            <div className="checkout-modal__content__buttons col-12 d-flex">
                                <button type="button" className="btn btn--outlined" onClick={() => {
                                    props.onClose()
                                }}>
                                    <span
                                        className="btn__text-gradient">{"ANNULLA"}</span>
                                </button>
                                <button disabled={!formState.isValid}
                                        type="submit"
                                        className="btn btn--red"
                                >
                                        ELIMINA
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DeleteAccountModal
