
export enum PayoutProfileStatus{
    Draft = "draft",
    KYCNeeded = "kyc_needed",
    KYCAdditionalDocumentNeeded = "kyc_additional_document",
    KYCPending  = "kyc_pending",
    Complete = "complete"
}

export default interface PayoutProfile{
    uuid: string;
    firstName: string;
    lastName: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    IBAN: string;
    phoneNumber: string;
    dateOfBirth: Date;
    documentFrontUuid?: string;
    documentRearUuid?:string;
    status: PayoutProfileStatus;
}

