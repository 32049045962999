import React, {ReactElement, useEffect, useState} from 'react'
import ProfileHeader from "@/components/ProfileHeader";
import Layout from "@/components/Layout";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Account from "@/pages/Profile/Account";
import Tessere from "@/pages/Profile/Tessere";
import {useApi} from "@/hoc/Api/context";
import Tickets from "@/pages/Profile/Tickets";
import UserInfo from "@/models/UserInfo";
import Loader from "@/components/Loader";
import Balance from "@/pages/Profile/Balance";
import {toast} from "react-toastify";
import {PayoutProfileStatus} from "@/models/PayoutProfile";

interface localStoragePayoutProfile {
    time: number;
    value: PayoutProfileStatus;
}

const Profile = ({...props}): ReactElement => {
    const api = useApi()
    const navigate = useNavigate()
    const [userData, setUserData] = useState<UserInfo | undefined>(undefined)
    const allowedSections = ["", "/", "/Tessere", "/Vendi", "/Saldo"];
    let location = useLocation();

    const userUpdate = () => {
        api.UserInfo().then((u: UserInfo) => {
            let userD: UserInfo = u;
            if (u.payoutProfile?.status != PayoutProfileStatus.KYCPending) {
                const getLocalSt = localStorage.getItem("updatePayoutProfile")
                if (getLocalSt != null) {
                    const dataLocalSt: localStoragePayoutProfile = JSON.parse(getLocalSt)
                    if (userD.payoutProfile?.status != dataLocalSt.value) {
                        setUserData(userD)
                        localStorage.removeItem("updatePayoutProfile")
                    } else if (dataLocalSt.time + 120000 > new Date().getMilliseconds()) {
                        if (userD.payoutProfile?.status) {
                            userD.payoutProfile.status = PayoutProfileStatus.KYCPending
                        }
                        setUserData(userD)
                    } else {
                        localStorage.removeItem("updatePayoutProfile")
                        setUserData(userD)
                    }
                } else {
                    setUserData(userD)
                }
            } else {
                localStorage.removeItem("updatePayoutProfile")
                setUserData(userD)
            }
        }).catch(() => {
            toast.error("Spiacenti, si è verificato un errore")
        })
    }

    useEffect(() => {
        if (allowedSections.indexOf(location.pathname.substring(8,)) === -1) {
            navigate("/404-not-found")
        }
        userUpdate()
    }, [])

    if (userData === undefined) {
        return <Loader/>
    }

    return (
        <Layout>
            <div className="profile">
                <ProfileHeader
                    user={userData}
                    onUserUpdated={(u) => {
                        setUserData(u)
                    }}
                />
                <Routes>
                    <Route path={"/"} element={<Account user={userData} onUserUpdate={() => {userUpdate()}}/>}/>
                    <Route path={"/Tessere"} element={<Tessere/>}/>
                    <Route path={"/Vendi"} element={<Tickets/>}/>
                    <Route path={"/Saldo"} element={<Balance/>}/>
                </Routes>
            </div>
        </Layout>
    )
}

export default Profile
