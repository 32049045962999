import React, {ReactElement, useEffect, useState} from "react";
import Radio from "@/components/Radio";

interface PricesFilterProps {
    onChange: (val: number | undefined) => void;
    radioClass?: string;
    title?: string;
    filterPrice: number | undefined;
}

const PricesFilter = (props: PricesFilterProps): ReactElement => {

    const [activePrice, setActivePrice] = useState<number | undefined>(props.filterPrice);

    useEffect(() => {
        props.onChange(activePrice)
    }, [activePrice])

    const options = [
        10,
        30,
        60,
        80
    ]

    return (
        <div className="col-12 filter__body">
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className="filter__body__info">
                        <label className="label filter__body__info__label text--primary-color">{props.title ? props.title : "Prezzo"}</label>
                    </div>
                </div>
            </div>
            <Radio
                key={"any"}
                title={`Qualsiasi`}
                id={"any"}
                name="price-range_selector"
                value={"any"}
                checked={activePrice === undefined}
                changeStatus={() => {
                    setActivePrice(undefined)
                }}
                parentClass={props.radioClass}
            />
            {
                options.map((p, i) => {
                    return (
                        <Radio
                            key={i}
                            title={`< ${p} €`}
                            id={p.toString()}
                            name="price-range_selector"
                            value={p.toString()}
                            checked={activePrice === p}
                            changeStatus={() => {
                                setActivePrice(p)
                            }}
                            parentClass={props.radioClass}
                        />
                    )
                })
            }
        </div>
    )
}

export default PricesFilter
