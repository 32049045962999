import PayoutProfile, {PayoutProfileStatus} from "@/models/PayoutProfile";
import {ReactElement, useEffect, useState} from "react";
import EditPayoutProfile from "@/components/PayoutProfileModal/EditPayoutProfile";
import UploadDocuments from "@/components/PayoutProfileModal/UploadDocuments";

interface PayoutProfileModalProps {
    profile?: PayoutProfile;
    onClose: () => void;
}

const PayoutProfileModal = (props: PayoutProfileModalProps): ReactElement => {

    const [modalTitle, setModalTitle] = useState<string>("");

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    const renderModal = (): ReactElement => {
        if (!props.profile) {
            return <EditPayoutProfile onClose={props.onClose} profile={props.profile} updateTitle={setModalTitle}/>
        }
        switch (props.profile?.status){
            case PayoutProfileStatus.Draft:
                return <EditPayoutProfile onClose={props.onClose} profile={props.profile} updateTitle={setModalTitle}/>
            case PayoutProfileStatus.Complete:
                return <EditPayoutProfile onClose={props.onClose} profile={props.profile} updateTitle={setModalTitle}/>
            case PayoutProfileStatus.KYCNeeded:
                return <EditPayoutProfile onClose={props.onClose} profile={props.profile} updateTitle={setModalTitle}/>
            case PayoutProfileStatus.KYCAdditionalDocumentNeeded:
                return <UploadDocuments
                    onClose={props.onClose}
                    profile={props.profile}
                    additionalDocument={true}
                    updateTitle={setModalTitle}
                />
            default:
                return <></>

        }
    }
    return <>
        <div className="modal-header">
            <h5 className="mx-auto modal-title">{modalTitle}</h5>
            <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                    onClick={() => {
                        props.onClose()
                    }}
            >
                <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
            </button>
        </div>
        <div className="ticket-cards__modal__custom-body modal-body container">
            {renderModal()}
        </div>
    </>
}

export default PayoutProfileModal
