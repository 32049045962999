import {ReactElement, useEffect} from 'react'

import {Link} from 'react-router-dom'

// @ts-ignore
import HeaderLogo from '@/img/icon/header_logo.png'
// @ts-ignore

import {useStore} from "@/hoc/Store/context";
import {useApi} from "@/hoc/Api/context";
const Header = ({...props}): ReactElement => {

    const store = useStore()
    const api = useApi()

    useEffect(() => {
        api.IsLogged().then((isLogged) => {
            if (isLogged.isLogged) {
                api.GetPreorder().then(p => {
                    store.setPreorder(p)
                })
            }
        })
    }, [])

    return (
        <nav className='navbar navbar-expand-lg'>
            <a className='navbar__header__content' href='/'>
                <img alt='Ticketag Logo' className='navbar-brand navbar__brand' src={HeaderLogo}/>
            </a>
            <div className='navbar__header__content navbar-icons navbar__icons d-flex justify-content-between'>
                <button className='navbar__header__content navbar-toggler' id='navbarToggler'
                        onClick={
                            () => {
                                if (document.body.classList.contains('locked')) {
                                    document.body.classList.remove('locked')
                                    document.getElementById('navbarMenu')?.classList.remove('show')
                                    document.getElementById('navbarToggler')?.classList.remove('is-active')
                                } else {
                                    document.body.classList.add('locked')
                                    document.getElementById('navbarMenu')?.classList.add('show')
                                    document.getElementById('navbarToggler')?.classList.add('is-active')
                                }
                            }
                        }
                >
                    <div className='hamburger'>
                        <div className='hamburger-box'>
                            <div className='hamburger-inner'/>
                        </div>
                    </div>
                </button>
                <Link className='navbar__icons--login navbar_separator' to='/Profilo'>
                    <i className="nav-access-item navbar__icons--login icon icon--access"/>
                </Link>
                <Link className='navbar__icons--shopping' title={(props.productsInBag ? props.productsInBag : null)} to='/Carrello'>
                    <div className="position-relative me-1">
                        <i className="nav-access-item navbar__icons--shopping icon icon--shopping"/>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                              style={{backgroundColor: "red", fontSize: 9}}
                        >
                            {
                                store.preorder()?.ticketOrders.length !== 0 ?
                                    store.preorder()?.ticketOrders.length
                                    :
                                    null
                            }
                        </span>
                    </div>
                </Link>
            </div>
            <div aria-expanded='false' className="collapse navbar-collapse navbar__collapse d-flex justify-content-end" id='navbarMenu'>
                <ul className='navbar-nav mb-lg-0'>
                    <li className='nav-item ps-2'>
                        <Link
                            className='nav-link' onClick={
                            () => {
                                document.body.classList.remove('locked')
                                document.getElementById('navbarMenu')?.classList.remove('show')
                                document.getElementById('navbarToggler')?.classList.remove('is-active')
                            }
                        } to='/Partite'
                        >Partite</Link>
                    </li>
                    <li className='nav-profile nav-item d-lg-none navbar__nav-profile ps-2'>
                        <Link className='nav-link d-flex justify-content-between'
                              onClick={
                                  () => {
                                      document.body.classList.remove('locked')
                                      document.getElementById('navbarMenu')?.classList.remove('show')
                                      document.getElementById('navbarToggler')?.classList.remove('is-active')
                                  }
                              } to='/Profilo'
                        >
                            Il mio profilo
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Header
