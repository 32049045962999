import {ReactElement, useEffect, useState} from "react";


interface CheckboxProps {
    id: string;
    parentClass?: string;
    title: string;
    value?: string;
    onToggle: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    required?: boolean;
    cookies?: boolean;
}

const Checkbox = (props: CheckboxProps): ReactElement => {
    const [checked, setChecked] = useState<boolean>(props.checked);

    useEffect(() => {
        props.onToggle(checked);
    }, [checked])

    return (
        <>
            <div className={"row py-2 align-items-center"}>
                <div className={"col-auto"}>
                    <div className={`form-group form-check-input-checkbox ${props?.parentClass || ""}`}>
                        <input type="checkbox"
                               style={props.cookies ? {marginTop: 0} : {}}
                               className="form-check-input"
                               id={props.id}
                               name={props.id}
                               value={props.value}
                               disabled={props.disabled}
                               required={props.required}
                               checked={checked}
                               onChange={(event) => {
                                   event.target.checked ? setChecked(true) : setChecked(false)
                               }}
                        />
                    </div>
                </div>
                <div className={"col p-0 d-flex"}>
                    <label className="form-check-label">
                        {props.title}
                    </label>
                </div>
            </div>
        </>
    )
}

export default Checkbox
