import {ReactElement, useEffect, useState} from "react";


interface RangeSelectorProps {
    id: string;
    name: string;
    min: number;
    max: number;
    step: number;
    disabled: boolean;
    onChange: (value: number) => void;
    initialValue: number;
}

const RangeSelector = (props: RangeSelectorProps): ReactElement => {
    const [value, setValue] = useState<number>(props.initialValue)
    useEffect(() => {
        props.onChange(value);
    }, [value])

    const onChange = (ev: any): void => {
        setValue(ev.target.value);
    }

    return (
        <input type="range"
               className="range-selector"
               id={props.id}
               name={props.name}
               min={props.min}
               max={props.max}
               step={props.step}
               value={value}
               disabled={props.disabled}
               onChange={(event) => onChange(event)}
               onMouseUp={(event) => onChange(event)}
               onTouchEnd={(event) => onChange(event)}
        />
    )
}

export default RangeSelector
