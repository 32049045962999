import {createContext, useContext} from "react";
import {SeoPage} from "@/hoc/Seo/provider";

interface SeoContextIFace {
    setSeo: (page: SeoPage) => void
}

// @ts-ignore
const SeoContext = createContext<SeoContextIFace>();


const useSeo = (): SeoContextIFace => {
    return useContext(SeoContext)
}

export default SeoContext;
export {useSeo}
