import TicketOrder from "@/models/Ticketorder";

export enum PreorderState {
    Draft ="draft",
    Pending="pending",
    Transferring="transferring",
    TransferCompleted="transfer_completed",
    TransferFailed="transfer_failed",
    TransferPartial="transfer_partial",
    PaymentFailed="payment_failed",
    Completed = "completed"
}

interface PreorderClientData{
    clientSecret: string;
    statusPayment: string;
}

export default interface Preorder {
    uuid: string;
    userUuid: string;
    ticketOrders: TicketOrder[];
    totalAmount: number;
    state: PreorderState;
    clientData?: PreorderClientData;
}
