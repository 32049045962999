import React, {ReactElement} from "react";
import Select, {StylesConfig} from "react-select";
import {BalanceLogType} from "@/models/BalanceLog";
import moment from "moment/moment";
import variables from "@/models/ColorsVariables";

const selectStyles: StylesConfig = {
    control: (styles) => (
        {...styles, backgroundColor: variables.background, border: "0", boxShadow: "none", color: variables.mainText, fontSize: 14, cursor: 'pointer',}),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
    dropdownIndicator: (styles, {isFocused}) => {
        return {
            ...styles,
            color: isFocused ? variables.mainText : variables.mainText,
            ':hover' : {
                color: variables.mainText
            }
        }
    },
    clearIndicator: (styles, {isFocused}) => {
        return {
            ...styles,
            color: isFocused ? variables.mainText : variables.mainText,
            ':hover' : {
                color: variables.mainText
            }
        }
    },
    valueContainer: (styles) => ({ ...styles, fontSize: 14, color: variables.mainText}),
    menu: (styles) => ({ ...styles, fontSize: 14, color: `${variables.mainText} !important`}),
    placeholder: (styles) => ({...styles, color: variables.mainText}),
    option: (styles, {isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: 'pointer',
            backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
            color: variables.mainText,
            ':active': {
                ...styles[':active'],
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
            },
        }
    },
    singleValue: (styles) => ({ ...styles, color: variables.mainText, fontSize: 14}),
}

interface PayoutHistoryFiltersProps {
    onChangeTransactionType: (type: BalanceLogType | undefined) => void;
    onChangeDate: (date: moment.Moment | undefined) => void;
}

const PayoutHistoryFilters = (props: PayoutHistoryFiltersProps): ReactElement => {

    const transactionTypeOptions = [
        {
            value: BalanceLogType.SALE,
            label: "Vendita Biglietto"
        },
        {
            value: BalanceLogType.PAYOUT,
            label: "Prelievo denaro"
        }
    ]

    const dateOptions = [
        {
            value: moment().subtract(1,'days').endOf('day').toString(),
            label: "Oggi",
        },
        {
            value: moment().subtract(7, 'days'),
            label: "Ultimi 7 giorni",
        },
        {
            value: moment().subtract(30, 'days'),
            label: "Ultimi 30 giorni",
        },
        {
            value: moment().subtract(90, 'days'),
            label: "Ultimi 3 mesi",
        },
        {
            value: moment().subtract(180, 'days'),
            label: "Ultimi 6 mesi",
        }
    ]

    return (
        <>
            <div className="row payout-history-filters">
                <div className="col-sm-5 col-lg-3">
                    <label className="filter-title ps-1 text--heading-color">Tipo di Movimenti</label>
                    <Select
                        isSearchable={false}
                        isClearable={true}
                        options={transactionTypeOptions}
                        placeholder={"Mostra tutte"}
                        styles={selectStyles}
                        onChange={(ev: any) => {
                            ev == null ?
                                props.onChangeTransactionType(undefined)
                                :
                            props.onChangeTransactionType(ev.value);
                        }}
                    />
                </div>
                <div className="col-1"></div>
                <div className="col-sm-5 col-lg-3">
                    <label className="filter-title ps-1 text--heading-color">Data</label>
                    <Select
                        isSearchable={false}
                        isClearable={true}
                        options={dateOptions}
                        placeholder={"Sempre"}
                        styles={selectStyles}
                        onChange={(ev: any) => {
                            ev == null ?
                                props.onChangeDate(undefined)
                                :
                                props.onChangeDate(ev.value);
                        }}
                    />
                </div>
                <div className="col-1"></div>
            </div>
        </>
    )
}

export default PayoutHistoryFilters;
