import {ReactElement} from "react";

import Accordion from "@/components/Accordion/Accordion";

interface AccordionSection {
    title: string;
    content: string;
}

interface InfoProps {
    title: string;
    subtitle: string;
    accordionId: string;
    accordionSection: AccordionSection[];
}

const Info = (props: InfoProps): ReactElement => {

    return (
        <div className={"info-container container"}>
            <div className={"row justify-content-center mb-4 px-2"}>
                <div className={"col-auto"}>
                    <h1 className={"info-title text--primary-color"}
                        dangerouslySetInnerHTML={{ __html: props.title}}
                    >
                    </h1>
                </div>
            </div>
            <div className={"row justify-content-center pb-1 mb-5 px-2"}>
                <div className={"col-auto"}>
                    <p className={"info-subtitle text--heading-color"}>
                        {props.subtitle}
                    </p>
                </div>
            </div>
            <Accordion accordionId={props.accordionId} sections={props.accordionSection}/>
        </div>
    )
}

export default Info
