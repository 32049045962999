import {ReactElement, useEffect} from "react";
import Media from "react-media";
import CartTicket from "@/components/CartTicket";
import TableCartTicket from "@/components/TableCartTicket";
import {useStore} from "@/hoc/Store/context";

interface CartTicketTicketProps {
    showErrors: boolean;
}

const CartTickets = ({...props}: CartTicketTicketProps): ReactElement => {

    const store = useStore()

    const ticketsOrders = store.preorder()?.ticketOrders || []

    const ticketList = ticketsOrders.map(t => (
            <CartTicket ticketOrder={t} key={t.ticketUuid}/>
        )
    )

    const ticketTableList = ticketsOrders.map((t) => (
            <TableCartTicket key={t.ticketUuid} ticketOrder={t}
                             showErrors={props.showErrors}
                             onChange={(newTicketOrder)=>{
                                 store.setTicketOrder(newTicketOrder)
                             }}
            />
        )
    )

    useEffect(() => {

    }, [])

    return (
        <Media query="(max-width: 991px)">
            {
                matches => matches ? (
                    <div className="cart-tickets row">
                        <div className="cart-ticket col-12">
                            <Media query="(max-width: 500px)">
                                {
                                    matches => matches ?
                                        (
                                            <div className="cart-ticket__contents">
                                                {ticketList}
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="cart-ticket__contents">
                                                {/* LABEL */}
                                                <div className="cart-tickets__table-header row">
                                                    <div className="col-5">
                                                        <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Partita"}</label>
                                                    </div>
                                                    <div className="col-5">
                                                        <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Dettagli"}</label>
                                                    </div>
                                                    <div className="col-2 p-0">
                                                        <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Prezzo"}</label>
                                                    </div>
                                                </div>
                                                {ticketList}
                                            </div>
                                        )
                                }
                            </Media>
                        </div>
                    </div>
                ) : (
                    <div className="cart-tickets">
                        <div className="cart-tickets__table-header row">
                            <div className="col-4">
                                <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Partita"}</label>
                            </div>
                            <div className="col-3">
                                <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Dettagli"}</label>
                            </div>
                            <div className="col-3">
                                <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Tessera"}</label>
                            </div>
                            <div className="col-2">
                                <label className="cart-tickets__table-header__title label-2 text--primary-color">{"Prezzo"}</label>
                            </div>
                        </div>
                        {ticketTableList}
                    </div>
                )
            }
        </Media>
    )
}

export default CartTickets
