import {ReactElement, useEffect, useState} from "react";
import DatePicker from 'react-date-picker/dist/entry.nostyle';

import "./DatePicker.css";

interface DatePickerProps {
    props?: any;
    onSelect: (date: Date | undefined) => void;
    initialData?: (Date | undefined);
}

const DatePickerComponent = (props: DatePickerProps): ReactElement => {
    const [value, onChange] = useState<Date | undefined>(props.initialData);

    useEffect(() => {
        props.onSelect(value);
    }, [value])

    return (
        <>
            <div className={"row"}>
                <DatePicker
                    name={"datepicker"}
                    dayPlaceholder={"gg"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"aaaa"}
                    onChange={onChange}
                    value={value}
                    format="dd/MM/yyyy"
                    clearIcon={null}
                    locale="it-IT"
                    minDate={new Date(1900, 0, 1)}
                    maxDate={new Date()}
                    calendarIcon={null}
                    disableCalendar
                />
            </div>
        </>
    );
}

export default DatePickerComponent;
