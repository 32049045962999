import React, {ReactElement} from 'react';
import {useNavigate} from "react-router-dom";
import Moment from "react-moment";

interface SoldOutProps {
    page: string;
}

const SoldOut = (props: SoldOutProps): ReactElement => {
    const navigate = useNavigate();

    switch (props.page) {
        case "plp":
            return (
                <div className={"row col-12 justify-content-center mx-auto"}>
                     <div className="col-12 text-center">
                         <h2 className="sold-out__title text--primary-color">Riprova più tardi :)</h2>
                     </div>
                     <div className="col-12 px-3 mt-3">
                         <p className="sold-out__subtitle text-center text--heading-color">Attualmente non ci sono partite disponibili, riprova più tardi.</p>
                     </div>
                     <div className="col-12 px-3 d-flex justify-content-center mt-4">
                         <div className='text-center'>
                             <button className='btn btn--gradient ml-auto px-sm-3' onClick={() => {navigate("/")}}>
                                 TORNA INDIETRO
                             </button>
                         </div>
                     </div>
                </div>
            )
        case "pdp":
            return (
                <div className="row justify-content-center mx-auto mt-5">
                    <div className="col-12">
                        <h2 className="shopping-cart--empty__title text-center">Riprova più tardi :)</h2>
                    </div>
                    <div className="col-11 col-md-9 col-lg-7 col-xl-6">
                        <p className="shopping-cart--empty__subtitle text-center">
                            Attualmente non ci sono biglietti disponibili, riprova più tardi.
                        </p>
                    </div>
                    <div className="col-12 justify-content-center d-flex mb-5">
                        <button type="button" className="shopping-cart--empty__continue btn btn--gradient"
                                onClick={() => {navigate("/Partite")}}
                        >
                            {"Torna indietro"}
                        </button>
                    </div>
                </div>
            )
        default:
            return <></>
    }

}


export default SoldOut
