import { AuthUser } from "@/models/AuthUser";
import { createContext, useContext } from "react";

interface AuthUserContextIFace {
    authUser: AuthUser,
}

// @ts-ignore
const LoginContext = createContext<AuthUserContextIFace>();

const useAuthUser = (): AuthUserContextIFace => {
    return useContext(LoginContext)
}

export default LoginContext;
export { useAuthUser }
