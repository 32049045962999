interface colorsVariables {
    background: string;
    linesAndBox: string;
    mainText: string;
}

const variables: colorsVariables = {
    background:     'rgb(255, 255, 255);',  // Change Me
    linesAndBox:    'rgb(0, 20, 97);',  // Change Me
    mainText:       'rgb(122, 133, 140);',   // Change Me
}

export default variables
