import {createContext, ReactElement, ReactNode, useContext, useState} from "react";
import CookiesModal from "@/components/CookiesModal";
import {Modal} from "react-bootstrap";


interface CookieContextData {
    openModal: ()=>void
}

// @ts-ignore
const CookiesContext = createContext<CookieContextData>();

const useCookies = () => {
    return useContext(CookiesContext)
}

interface TeamProviderProps {
    children: ReactNode;
}

const CookiesProvider = (props: TeamProviderProps): ReactElement => {

    const cookies = localStorage.getItem("cookies");
    const [showCookiesModal, setShowCookiesModal] = useState(cookies == null)

    return (
        <CookiesContext.Provider value={{
            openModal: ()=>{
                setShowCookiesModal(true)
            }
        }}>
            {props.children}
            {
                showCookiesModal ?
                    <Modal show={showCookiesModal}
                           onHide={() => {
                               setShowCookiesModal(false)
                           }}
                           onEscapeKeyDown={e => {
                               e.preventDefault()
                           }}
                           backdrop={'static'}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <CookiesModal
                            onClose={() => {
                                setShowCookiesModal(false)
                            }}
                        />
                    </Modal>
                    :
                    null
            }
        </CookiesContext.Provider>
    )
}

export {useCookies, CookiesProvider}
