import React, {ReactElement, useEffect, useState} from 'react'
import Layout from "@/components/Layout";
import SellingTickets from "@/models/SellingTickets";
import Event from "@/models/Event"
import Loader from "@/components/Loader";
import Moment from 'react-moment';
import SoldOut from '@/components/SoldOut';
import {Flipper} from 'react-flip-toolkit';
import Bundle from "@/components/Bundle";
import Ticket from "@/components/Ticket";
import PDPFilters from "@/components/PDPFilters";
import {useApi} from "@/hoc/Api/context";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import SectionsFilter from "@/components/SectionsFilter";
import TicketModels from "@/models/Ticket";
import ReductionCode from "@/models/ReductionCode";
import Section from "@/models/Section";
import TicketsBundle from "@/models/TicketsBundle";
import * as _ from "lodash";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";

interface PDPProps {

}

export interface PDPFilterArgs {
    sections: string[];
    reductionCodes: string[];
    numSeats: number;
    price: number | undefined;
}

const Pdp = (props: PDPProps): ReactElement => {
    const seo = useSeo()
    const [sellingTickets, setSellingTickets] = useState<SellingTickets | undefined>(undefined);
    const [event, setEvent] = useState<Event | undefined>(undefined);
    const [bundlesShowed, setBundleShowed] = useState<number>(3);
    const [ticketShowed, setTicketShowed] = useState<number>(10);
    const [showTickets, setShowTickets] = useState<boolean>(true);
    const [showBundles, setShowBundles] = useState<boolean>(true);
    const [reductionsCode, setReductionsCode] = useState<ReductionCode[]>([]);
    const [sections, setSections] = useState<Section[]>([]);

    const [searchParams] = useSearchParams()

    const [filters, setFilters] = useState<PDPFilterArgs>({
        sections: [],
        reductionCodes: [],
        numSeats: searchParams.get("tickets") !== "" ? Number(searchParams.get("tickets")) : 1,
        price: undefined
    });

    const api = useApi()
    const {id} = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        if (id === undefined) {
            navigate("/")
            return
        }
        seo.setSeo(SeoPage.partita);
        api.GetEvent(id).then((e) => {
            setEvent(e)
            console.log(e.eventLocationImage)
            api.GetEventLocationSections(e.eventLocationUuid).then((s) => {
                setSections(s)
            })
            api.GetEventIssuerReductionCodes(e.mainIssuerUuid).then((r) => {
                setReductionsCode(r)
            })
        })
        api.GetEventSellingTickets(id).then((s_t) => {
            setSellingTickets(s_t)
        })
    }, [])

    if (sellingTickets === undefined || event === undefined) {
        return <Loader/>
    }

    const isSoldOut = (): boolean => {
        return sellingTickets.tickets.length === 0 && sellingTickets.bundles.length === 0;
    }

    const filterTickets = (ticket: TicketModels): boolean => {
        let show: boolean = true;

        if (filters?.price !== undefined) {
            if (ticket.price >= (filters.price! * 100)) show = false;
        }

        if (filters?.sections.length !== 0) {
            if (!filters?.sections.includes(ticket.sectionUuid)) show = false;
        }

        if (filters?.reductionCodes.length !== 0) {
            if (!filters?.reductionCodes.includes(ticket.reductionCodeUuid)) show = false;
        }

        return show;
    }

    const filterBundles = (bundle: TicketsBundle): boolean => {
        let show: boolean = true;

        if (filters?.price !== undefined) {
            if (bundle.totalPrice >= (filters.price! * 100)) show = false;
        }

        if (filters?.sections.length !== 0) {
            if (!filters?.sections.includes(bundle.sectionUuid)) show = false;
        }

        if (filters?.reductionCodes.length !== 0) {
            if (!filters?.reductionCodes.includes(bundle.reductionCodeUuid)) show = false;
        }

        if (filters?.numSeats !== 1) {
            if (bundle.totalTickets !== filters.numSeats) return false;
        }

        return show
    }

    const bundleList = sellingTickets.bundles
        .filter((b) => {
            return filterBundles(b)
        })
        .map((b, i) => {
            return <Bundle bundle={b} reductionCodes={reductionsCode} key={i}
                           onSelect={() => {
                               api.GetEventSellingTickets(id!).then((r) => {
                                   setSellingTickets(r)
                                   if (r.bundles.length === 0) setShowTickets(true)
                               })
                           }}
            />
        })

    const ticketList = sellingTickets.tickets
        .filter((t) => {
            return filterTickets(t)
        })
        .map((t, i) => {
            return <Ticket ticket={t} reductionCodes={reductionsCode} key={i}
                           onSelect={() => {
                               api.GetEventSellingTickets(id!).then((r) => {
                                   setSellingTickets(r)
                                   if (r.tickets.length === 0) setShowBundles(true)
                               })
                           }}
            />
        })


    return (
        <Layout>
            <div className="PDP">
                <div className="PDP container">
                    <div className={isSoldOut() ? "row PDP__sold-out" : "row justify-content-center"}>

                        {/* HEADER INFO MATCH */}
                        {
                            !isSoldOut() ?
                                <div className="col-12 PDP__header">
                                    <h1 className="PDP__header__title font-weight-semibold">
                                        {event.mainIssuer}
                                        <span className="PDP__header__title__versus text--heading-color"> vs </span>
                                        {event.secondaryIssuer}
                                    </h1>

                                    <p className="PDP__header__subtitle text--heading-color">{event.eventLocation}</p>
                                    <p className="PDP__header__subtitle">
                                        <Moment className="PDP__header__subtitle__datetime text--heading-color" locale="it"
                                                format="dddd D MMMM,">
                                            {event.date}
                                        </Moment>
                                        {" ore "}
                                        <Moment className="text--heading-color" locale="it" format="HH:mm">
                                            {event.date}
                                        </Moment>
                                    </p>
                                </div>
                                :
                                null
                        }

                            <>
                                <div className={"row"}>
                                    {/* SECTORS SECTION FOR DESKTOP */}
                                    <div className={"col-12 filters__element filters__element--sector filter"}>
                                        <div className="row filters__element__body filters__element__body--sector">
                                            <SectionsFilter
                                                title={"Settori"}
                                                filterSections={filters.sections}
                                                sections={sections}
                                                eventLocationImage={event.eventLocationImage}
                                                eventLocationName={event.eventLocation}
                                                updateFilter={(val: string[]) => {
                                                    setFilters({...filters!, sections: val})
                                                }}/>
                                        </div>
                                    </div>

                                    {/* PDP FILTERS */}
                                    <div className={"col-12 col-lg-3 col-xl-2"}>
                                        <PDPFilters
                                            updatePrice={(val: number | undefined) => {
                                                setFilters({...filters!, price: val})
                                            }}
                                            updateReductionCodes={(val: string[]) => {
                                                setFilters({...filters!, reductionCodes: val})
                                            }}
                                            updateSections={(val: string[]) => {
                                                setFilters({...filters!, sections: val})
                                            }}
                                            updateNumSeats={(val: number) => {
                                                setFilters({...filters!, numSeats: val})
                                            }}
                                            reductionCodes={reductionsCode}
                                            sections={sections}
                                            nSeats={filters.numSeats}
                                            eventLocationName={event.eventLocation}
                                            eventLocationImage={event.eventLocationImage}
                                            bundleSeatsNumber={_.sortBy(_.uniq(sellingTickets.bundles.map((b) => {
                                                return b.totalTickets
                                            })))}
                                        />
                                    </div>

                                    <div className={"col-12 col-lg-9"}>
                                        {!isSoldOut()
                                            ?
                                        <div className="col-12 offset-xl-1 tickets__container">
                                            {
                                                (ticketList.length !== 0 || bundleList.length !== 0) ?
                                                    <>
                                                        {/* BUNDLE PACKAGES */}
                                                        {
                                                            (sellingTickets.bundles.length > 0)
                                                                ?
                                                                <div className="row">
                                                                    <div className="tickets accordion container"
                                                                         id="bundlesAccordion">
                                                                        <div className="row">
                                                                            <div
                                                                                className="tickets__header text--heading-color col-12"
                                                                            >
                                                                                {"Pacchetti"}
                                                                                <i className={`tickets__header__icon icon icon--arrow icon--arrow--${showBundles ? "up" : "down"} icon--default-heading cursor-pointer`}
                                                                                   onClick={() => {
                                                                                       setShowBundles(!showBundles)
                                                                                   }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            <div
                                                                                className={`easy-accordion ${showBundles ? "" : "hidden"}`}>
                                                                                <Flipper
                                                                                    flipKey={bundleList.slice(0, Math.min(bundlesShowed, sellingTickets.bundles.length))}>
                                                                                    <div id="bundlesCollapse"
                                                                                         className={"bundlesCollapse row show"}>
                                                                                        {bundleList.slice(0, Math.min(bundlesShowed, sellingTickets.bundles.length))}
                                                                                        <div className="col-12">
                                                                                            {
                                                                                                bundleList.length !== 0 ?
                                                                                                    (sellingTickets.bundles.length > bundlesShowed) ?
                                                                                                        <div
                                                                                                            className="tickets__show-more row">
                                                                                                            <button
                                                                                                                className="btn btn--gradient"
                                                                                                                onClick={() => {
                                                                                                                    setBundleShowed(bundlesShowed + 3)
                                                                                                                }}
                                                                                                            >
                                                                                                                {"Carica altri"}
                                                                                                            </button>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                    :
                                                                                                    <h4 className={"ms-4"}>Spiacenti,
                                                                                                        nessun pacchetto
                                                                                                        disponibile</h4>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </Flipper>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {/* SINGLE TICKETS */}
                                                        {
                                                            (sellingTickets.tickets.length > 0)
                                                                ?
                                                                <div className="row">
                                                                    <div className="tickets accordion container"
                                                                         id="ticketAccordion">
                                                                        {
                                                                            sellingTickets.bundles.length > 0 ?
                                                                                (
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="tickets__header text--heading-color col-12">
                                                                                            {"Biglietti Singoli"}
                                                                                            <i className={`tickets__header__icon icon icon--arrow icon--arrow--${showTickets ? "up" : "down"} icon--default-heading cursor-pointer`}
                                                                                               onClick={() => {
                                                                                                   setShowTickets(!showTickets)
                                                                                               }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            <div
                                                                                className={`easy-accordion ${showTickets ? "" : "hidden"}`}>
                                                                                <div id="ticketsCollapse"
                                                                                     className="ticketsCollapse row collapse show">
                                                                                    <div className="col-12"
                                                                                         style={{
                                                                                             borderBottom: "1px solid #F2F2F2",
                                                                                             paddingBottom: "10px"
                                                                                         }}>
                                                                                        <div
                                                                                            className="d-none d-lg-flex row d-flex justify-content-between">
                                                                                            <div className={"col-6"}>
                                                                                                <div
                                                                                                    className={"row d-flex justify-content-between"}>
                                                                                                    <div
                                                                                                        className={"col-auto"}>
                                                                                                        <label
                                                                                                            className="filter__label text--primary-color px-2">{"Settore"}</label>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={"col-auto"}>
                                                                                                        <label
                                                                                                            className="filter__label text--primary-color px-4">{"Fila e posto"}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <Flipper
                                                                                            flipKey={ticketList.slice(0, Math.min(ticketShowed, sellingTickets.tickets.length))}>
                                                                                            {ticketList.slice(0, Math.min(ticketShowed, sellingTickets.tickets.length))}
                                                                                        </Flipper>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        {
                                                                                            ticketList.length !== 0 ?
                                                                                                (ticketList.length > ticketShowed) ?
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            setTicketShowed(ticketShowed + 10)
                                                                                                        }}
                                                                                                        className="tickets__show-more row">
                                                                                                        <button
                                                                                                            className="btn btn--gradient">{"Carica altri"}</button>
                                                                                                    </div> :
                                                                                                    null
                                                                                                :
                                                                                                <h4 className={"m-4"}>Spiacenti,
                                                                                                    nessun biglietto
                                                                                                    disponibile</h4>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {/* INFO FOOTER */}

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p className="tickets__container__info label-2">
                                                                    {"* Questo è il prezzo definitivo inclusivo del costo del biglietto, dei costi del servizio di Ticketag e della piattaforma di pagamento “Stripe”"}
                                                                </p>
                                                                <p className="tickets__container__info label-2">
                                                                    {"** Prezzo di facciata del singolo biglietto intero al botteghino."}
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    <div className="row games__not-found">
                                                        <div className="col-12 text-center">
                                                            <h3 className="games__not-found__label">{"Nessun biglietto trovato"}</h3>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                            :
                                            // TICKETS SOLD OUT
                                            <SoldOut page={"pdp"}/>
                                        }
                                    </div>
                                </div>

                            </>

                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Pdp
