import { ReactElement, useEffect, useState } from "react";
import MembershipCard from "@/models/MembershipCard";
import Ticketorder from "@/models/Ticketorder";
import { useApi } from "@/hoc/Api/context";
import MemberCardsCarousel from "@/components/MemberCardsCarousel";
import Loader from "@/components/Loader";
import { format } from 'date-fns';
import { it } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/hoc/Store/context";
import { PreorderState } from "@/models/Preorder";
import { toast } from "react-toastify";


interface TableCartTicketProps {
    ticketOrder: Ticketorder;
    showErrors: boolean;
    onChange: (t: Ticketorder) => void;
}

const TableCartTicket = (props: TableCartTicketProps): ReactElement => {

    const api = useApi()
    const store = useStore()
    const navigate = useNavigate()

    const [membershipCard, setMembershipCard] = useState<MembershipCard | undefined>(undefined)
    const [accordionOpen, setAccordionOpen] = useState<boolean>(false);

    const formatDate = (date: string) => {
        const piecesArr = format(new Date(date), 'eeee d MMMM', { locale: it }).split(" ");
        for (let i = 0; i < piecesArr.length; i++) {
            piecesArr[i] = piecesArr[i].charAt(0).toUpperCase() + piecesArr[i].slice(1);
        }
        return piecesArr.join(" ");
    }

    const openAccordion = (): void => {
        setAccordionOpen(!accordionOpen)
    }

    const removeMemberCard = (): void => {
        api.DisassociateMembershipCard(props.ticketOrder.uuid).then((t) => {
            store.setTicketOrder(t)
            setMembershipCard(undefined)
        }).catch(() => {
            toast.error("Spiacenti, si è verificato un errore")
        })
    }

    const removeTicketOrder = (): void => {
        api.RemoveTicketToPreorder(props.ticketOrder.uuid).then((t) => {
            api.GetPreorder().then(p => {
                if (p.state === PreorderState.Draft || p.state === PreorderState.Pending) {
                    store.setPreorder(p)
                } else {
                    navigate("/Checkout")
                }
            })
        }).catch(() => {
            toast.error("Spiacenti, si è verificato un errore")
        })
    }

    const getMembershipCard = (uuid: string): void => {
        api.GetMembershipCard(uuid).then((m) => {
            setMembershipCard(m)
        })

    }

    useEffect(() => {
        props.ticketOrder.targetMembershipCardUuid ?
            getMembershipCard(props.ticketOrder.targetMembershipCardUuid)
            :
            setMembershipCard(undefined)
    }, [])

    if (accordionOpen === undefined) {
        return <Loader />
    }

    return (
        <div className="cart-ticket row align-items-md-center">

            {/* PARTITA: squadre - orario - loco */}
            <div className="col-4">
                <div className="cart-ticket__contents__info">
                    <div className="cart-ticket__contents__info__labels">
                        <p className="cart-ticket__contents__member-card__associate-label m-0 text--main-accent">
                            {props.ticketOrder.ticketDetails.reductionCode}
                        </p>
                        <h4 className="font-weight-semibold cart-ticket__contents__info__match text--primary-color">
                            {props.ticketOrder.mainIssuer} <span className="text--heading-color">vs</span> {props.ticketOrder.secondaryIssuer}
                        </h4>
                        <p>
                            {formatDate(props.ticketOrder.date)}, {props.ticketOrder.location}
                        </p>
                    </div>
                </div>
            </div>

            {/* DETTAGLI: settore - fila - posto */}

            <div className="col-3">
                <div className="card-ticket-new__no-line-height col p-0">
                    <div className={"row p-0"}>
                        <span className="card-ticket-new__details">
                            {`Settore: ${props.ticketOrder.ticketDetails.section} n° ${props.ticketOrder.ticketDetails.subSection}\n\n`}
                        </span>
                    </div>
                    <div className={"row p-0"}>
                        <span className="card-ticket-new__details">
                            {`Fila: ${props.ticketOrder.ticketDetails.row}, Posto: ${props.ticketOrder.ticketDetails.seat}`}
                        </span>
                    </div>
                </div>
            </div>

            {/* TESSERA */}
            <div className={(!membershipCard) ? "col-3 align-items-center p-0" : "col-3 p-0"}>
                {
                    (!membershipCard) ?
                        (
                            <div className="row justify-content-between m-0 p-0"
                                onClick={() => { openAccordion() }}>
                                <div className="col-auto cursor-pointer">
                                    <p className="cart-ticket__contents__member-card__associate-label">{"Associa la Tessera"}</p>
                                </div>
                                <div className="col-auto cursor-pointer me-4">
                                    <a className={(!membershipCard) ? "cart-ticket__contents__member-card__modal-btn" : "cart-ticket__contents__member-card__modal-btn active"}>
                                        <i className="icon icon--small-arrow icon--default" />
                                    </a>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className={"row justify-content-between m-0 p-0"} onClick={() => {
                                if (!membershipCard) {
                                    openAccordion()
                                } else {
                                    removeMemberCard()
                                }
                            }}>
                                <div className="col-auto cart-ticket__contents__member-card__info">
                                    <p className="cart-ticket__contents__member-card__info__owner">
                                        {membershipCard.fullName}
                                    </p>
                                    <p className="cart-ticket__contents__member-card__info__team">
                                        {membershipCard.eventIssuer} #{membershipCard.number}
                                    </p>
                                </div>
                                <div className={"col-auto align-items-center d-flex pe-lg-3 pe-xl-4 "}>
                                    {
                                        (!membershipCard) ?
                                            // icon--small-arrow--up
                                            <i onClick={() => { openAccordion() }}
                                                className="icon icon--small-arrow icon--default card-ticket-new__btn-expand__mobile__icon" />
                                            :
                                            <i onClick={() => { removeMemberCard() }}
                                                className="icon icon--close icon--default card-ticket-new__btn-expand__mobile__icon icon--red" />
                                    }
                                </div>
                            </div>
                        )
                }
            </div>

            {/* PREZZO + delete */}
            <div className="col-2 cart-ticket__edit-col">
                <div className="row justify-content-between card-ticket-new__container-price">
                    <div className="col-auto p-0">
                        <div className="row p-0">
                            <span className={"card-ticket-new__text-gradient m-0"}>
                                € {(props.ticketOrder.price / 100.0).toLocaleString('it-IT', { minimumFractionDigits: 2 })}*
                            </span>
                        </div>
                        {
                            props.ticketOrder.priceOfficial ?
                                (
                                    <div className="row p-0 justify-content-center">
                                        <span className="card-ticket-new__text">
                                            € {(props.ticketOrder.priceOfficial / 100.0).toLocaleString('it-IT', { minimumFractionDigits: 2 })}**
                                        </span>
                                    </div>
                                ) :
                                null
                        }

                    </div>
                    <div className="col-auto p-0 card-ticket-new__price">
                        <i onClick={() => { removeTicketOrder() }} className="cart-ticket__contents__edit__trash-can icon icon--trash-can icon--red" />
                    </div>
                </div>
            </div>

            {
                <div className={(accordionOpen) ? "col-12 cart-ticket__accordion show" : "col-12 cart-ticket__accordion"}
                    id={"member-cards_" + props.ticketOrder.uuid}>
                    <MemberCardsCarousel
                        currentTicket={props.ticketOrder}
                        onSelected={(uuid) => {
                            api.AssociateMembershipCard(props.ticketOrder.uuid, uuid).then((t) => {
                                store.setTicketOrder(t)
                                getMembershipCard(uuid)
                                setAccordionOpen(false)
                            }).catch(() => {
                                toast.error("Spiacenti, si è verificato un errore.")
                            })
                        }} />
                </div>

            }
            {(props.showErrors && !membershipCard && !accordionOpen) ? <div className="cart-ticket__error-box">
                <i className="cart-ticket__error-box__icon icon icon--warning icon-warning"></i>
                <p className="cart-ticket__error-box__text m-0">Per procedere all'acquisto devi prima associare una tessera al biglietto. Seleziona "Associa Tessera" per associare una tessera al biglietto e procedere al checkout.
                </p>
            </div> : null}
        </div>
    )
}

export default TableCartTicket
