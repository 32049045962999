import React, {ReactElement, useEffect, useReducer} from "react";
import {BalanceLogType} from "@/models/BalanceLog";
import PayoutHistoryTicket from "@/components/PayoutHistory/PayoutHistoryTicket";
import Select, {StylesConfig} from "react-select";
import {PaginationActionKind} from "@/components/PayoutHistory/PayoutHistoryModel";
import paginationReducer from "@/components/PayoutHistory/PayoutHistoryPaginationReducer";
import PayoutHistoryFilters from "@/components/PayoutHistory/PayoutHistoryFilters";
import moment from "moment";
import {useApi} from "@/hoc/Api/context";
import variables from "@/models/ColorsVariables";

const selectStyles: StylesConfig = {
    control: (styles) => (
        {...styles, backgroundColor: variables.background, border: "0", boxShadow: "none", color: variables.mainText, fontSize: 14, cursor: 'pointer',}),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
    dropdownIndicator: (styles, {isFocused}) => {
        return {
            ...styles,
            color: isFocused ? variables.mainText : variables.mainText,
            ':hover' : {
                color: variables.mainText
            }
        }
    },
    clearIndicator: (styles, {isFocused}) => {
        return {
            ...styles,
            color: isFocused ? variables.mainText : variables.mainText,
            ':hover' : {
                color: variables.mainText
            }
        }
    },
    valueContainer: (styles) => ({ ...styles, fontSize: 14, color: variables.mainText}),
    menu: (styles) => ({ ...styles, fontSize: 14, color: `${variables.mainText} !important`}),
    placeholder: (styles) => ({...styles, color: variables.mainText}),
    option: (styles, {isFocused, isSelected}) => {
        return {
            ...styles,
            cursor: 'pointer',
            backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
            color: variables.mainText,
            ':active': {
                ...styles[':active'],
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
            },
        }
    },
    singleValue: (styles) => ({ ...styles, color: variables.mainText, fontSize: 14}),
}

const eventsOptions = [
    {
        value: 4,
        label: "4"
    },
    {
        value: 5,
        label: "5"
    },
    {
        value: 6,
        label: "6"
    },
    {
        value: 7,
        label: "7"
    }
]


const PayoutHistory = (): ReactElement => {
    const api = useApi()

    const [pagination, paginationDispatch] = useReducer(paginationReducer,
        {
            immutableUserTransaction: [],
            userTransactions: [],
            rowsPerPage: 0,
            rowsMax: 0,
            firstRow: 0,
            lastRow: 0,
            filterTransactionType: undefined,
            filterDate: undefined,
            eventsOptions: eventsOptions,
            backwardDisabled: true,
            forwardDisabled: true,
            backwardLongDisabled: true,
            forwardLongDisabled: true,
        });

    useEffect(() => {
        api.GetUserTransactions().then(t => {
            paginationDispatch({type: PaginationActionKind.SET_INITIAL_DATA, payload: {initialData: t}})
        })
    },[])

    return (
        <div className="row gy-4 justify-content-center payout-history">
            {/* TITLE */}
            <div className="col-11">
                <span className="title text--primary-color">Elenco Movimenti</span>
            </div>

            {/* FILTERS SECTION */}
            <div className="col-11">
                <PayoutHistoryFilters
                    onChangeTransactionType={(type: BalanceLogType | undefined) => {
                        paginationDispatch({
                            type: PaginationActionKind.FILTER_TRANSACTION_TYPE,
                            payload: {filterTransactionType: type}
                        })
                    }}
                    onChangeDate={(date: moment.Moment | undefined) => {
                        paginationDispatch({type: PaginationActionKind.FILTER_DATE, payload: {filterDate: date}})
                    }}
                />
            </div>

            {/* TABLE */}
            <div className="col-11">

                {/* HEADER */}
                <div className="row d-flex align-items-center py-1 header">
                    <div className="col-2 p-0 col-sm-2 col-lg-2 d-flex"></div>
                    <div className="col-7 p-0 col-sm-5 col-lg-4 d-flex"><span
                        className="table-header-text text--primary-color">Descrizione</span></div>
                    <div className="col-2 p-0 d-none d-lg-flex"><span className="table-header-text text--primary-color">Data</span></div>
                    <div className="col-2 p-0 col-sm-3 col-lg-2 d-flex"><span
                        className="table-header-text text--primary-color">Importo</span></div>
                    <div className="col-2 p-0 col-sm-4 col-lg-2 d-none d-sm-flex"></div>
                </div>

                {/* ROWS */}
                {
                    pagination.userTransactions.length !== 0 ?
                        pagination.userTransactions.slice(pagination.firstRow, pagination.lastRow).map((item, index) => {
                            return (
                                <PayoutHistoryTicket
                                    key={index}
                                    uuid={item.uuid}
                                    date={item.date}
                                    type={item.type}
                                    amount={item.amount}
                                    data={item.data as any}
                                />
                            )
                        })
                        :
                        <>
                            <div className="row d-flex align-items-center py-3 payout-history-ticket">
                                <div className="col-12">
                                    <label className="no-ticket-found text--heading-color">Nessun biglietto trovato</label>
                                </div>
                            </div>
                        </>
                }

                {/* FOOTER */}
                <div className="row gx-5 d-flex py-1 table-footer">
                    <div className="col-auto px-2 d-flex">
                        <span className="text text--heading-color">Righe per pagina</span>
                    </div>
                    <div className="col-auto d-flex">
                        <Select
                            defaultValue={eventsOptions[0]}
                            isSearchable={false}
                            isClearable={false}
                            options={eventsOptions}
                            placeholder={pagination.rowsPerPage}
                            styles={selectStyles}
                            value={pagination.rowsPerPage}
                            onChange={(ev: any) => {
                                paginationDispatch({
                                    type: PaginationActionKind.CHANGE_ROW_PER_PAGE,
                                    payload: {rowsPerPage: ev.value}
                                })
                            }}
                        />
                    </div>
                    <div className="col-auto px-2 d-flex">
                        <span className="text text--heading-color">
                            {pagination.firstRow + 1} - {pagination.lastRow} of {pagination.rowsMax}
                        </span>
                    </div>

                    {/* PAGINATION NAVIGATION */}
                    <div className="col-12 py-3 py-md-auto col-md-auto justify-content-center d-flex">
                        <div className={`ms-4 me-4 d-flex align-items-center justify-content-center
                        ${pagination.backwardLongDisabled ? '' : 'cursor-pointer'}`}
                             onClick={() => {
                                 if (!pagination.backwardLongDisabled) paginationDispatch({type: PaginationActionKind.BACKWARD_LONG})
                             }}
                        >
                            <i
                                className={`mx-auto icon icon--back-long-pagination
                                ${pagination.backwardLongDisabled ? '' : 'icon--back-long-pagination--active'}`}
                            />
                        </div>
                        <div className={`ms-4 me-4 d-flex align-items-center justify-content-center
                        ${pagination.backwardDisabled ? '' : 'cursor-pointer'}`}
                             onClick={() => {
                                 if (!pagination.backwardDisabled) paginationDispatch({type: PaginationActionKind.BACKWARD})
                             }}
                        >
                            <i
                                className={`mx-auto icon icon--back-pagination
                                ${pagination.backwardDisabled ? '' : 'icon--back-pagination--active'}`}
                            />
                        </div>
                        <div className={`ms-4 me-4 d-flex align-items-center justify-content-center
                        ${pagination.forwardDisabled ? '' : 'cursor-pointer'}`}
                             onClick={() => {
                                 if (!pagination.forwardDisabled) paginationDispatch({type: PaginationActionKind.FORWARD})
                             }}
                        >
                            <i
                                className={`mx-auto icon icon--forward-pagination
                                ${pagination.forwardDisabled ? '' : 'icon--forward-pagination--active'}`}
                            />
                        </div>
                        <div className={`ms-4 me-4 d-flex align-items-center justify-content-center
                        ${pagination.forwardLongDisabled ? '' : 'cursor-pointer'}`}
                             onClick={() => {
                                 if (!pagination.forwardLongDisabled) paginationDispatch({type: PaginationActionKind.FORWARD_LONG})
                             }}
                        >
                            <i
                                className={`mx-auto icon icon--forward-long-pagination
                                ${pagination.forwardLongDisabled ? '' : 'icon--forward-long-pagination--active'}`}
                            />
                        </div>
                    </div>
                    <div className="pe-2 d-none d-lg-flex" style={{width: '50px'}}></div>
                </div>
            </div>
        </div>
    )
}

export default PayoutHistory;
